import axios from 'axios';
import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ErrorMessage from '../../userAuth/ErrorMessage';
import FootNav from '../../Nav/FootNav';
import download from 'downloadjs';

function AccessDownload() {

        const [loadDatas, setLoadDatas] = useState(false);
        const [years, setYears] = useState();
        const [centData, setCentData] = useState(null);
        const [grade, setGrade] = useState(null);
        const [subject,setSubject] = useState(null);
        const [curGrade, setCurGrade] = useState('');
        const [loadfull, setLoadFull] = useState(false)
        const [term, setTerm] = useState();
        const [yr, setYr] = useState();
        const [downloadStatus, setDownloadStatus] = useState(null);
        const [displayDown, setDisplayDown] = useState(false);
        const [loading, setLoading] = useState(false)
    
        const [loadMe, setLoadMe] = useState(false);
        const [cate, setCate] = useState('');
        const [assCate, setAssCate] = useState([]);

        const id = cate;


   const handleErrs = ($type,$msg,$uitype) =>{
    let errorChild = {types:$type, msg:$msg, uiType:$uitype}
  return (
    <div>
    <ErrorMessage errors={errorChild} />
    </div>
  
  )
}



const ACat = (datas,tm) =>{
    let ms;
     switch(tm){
        case "1":
          ms = datas.term1
        break;
        case "2":
          ms = datas.term2
        break;
        case "3":
          ms = datas.term3
          break;
        case "4":
          ms = datas.term4
          break;
     }
  
              let sd = ms.split(',');
             setAssCate(sd);
  
  }
  
  
  const getAssessmentCate = (tm) => {
      setTerm(tm)
      setLoadMe(true)
    if(!tm.trim()){}else{
    axios.post('/get-assess-cate',{tm},{
       headers:{'Content-Type' : 'application/json'}
    }).then((res)=>{
  
          setTimeout(()=>{
            setLoadMe(false)
            if(res.data.termData === 'empty'){
              setAssCate([]);
            }else
            {
                 ACat(res.data.termData[0],tm)
      
            }
          },1000)
  
        
    }).catch((err)=>{
       console.log(err)
    })
  }
  }
  

const downloadFile = (id,title,type,dtype) => {
    setLoading(true)
  axios.post('/downloadassessment-file',{id,dtype},{
     responseType:'blob'
  }).then((res)=>{
     setTimeout(()=>{
      setLoading(false)
  
      const content = res.headers['content-type'];
      download(res.data, title+type, content)
     },500)
     
  
     
  }).catch((err)=>{
      console.log(err)
  })
  }

const controlDownload = (e) =>{
    let subj = e.target.value;
       if(subj === ''){
        setDownloadStatus(null)
       }else{
         setLoadFull(true)
       axios.get('/api-fetchassessment-data/'+curGrade+'/'+subj+'/'+term+'/'+yr+'/'+id,{
           headers:{'Content-Type':'application/json'}
       }).then((res)=>{
              setTimeout(()=>{
                      setLoadFull(false)
                      setDisplayDown(true)
                    if(res.data.dData !== 'empty'){
                       setDownloadStatus(res.data.dData[0])
                       
                    }else{
                       setDownloadStatus(null)
                        console.log('empty')
                    }
                      
              },1200)
       })
    }
}


const handleDownloadData = () =>{

    return(
      <div className="col-lg-12">
       { downloadStatus !== null ?
 
        <div className="table-responsive">
             {loading === false ? null : <p><i className='text-success'>Downloading File. Please wait...</i></p>}
               <table className="table project-list-table table-nowrap align-middle table-borderless">
                                      <thead>
                                                 <tr>
                                                     <th scope="col" style={{ width: 100 }}>#</th>
                                                     <th scope="col">Subject Name</th>
                                                     <th scope="col">Assessment</th>
                                                     <th scope="col">Due Date</th>
                                                     <th scope="col">Grade</th>
                                                     <th scope="col">Memo</th>
                                                     <th scope="col">File Status</th>
                                                     <th scope="col">Action</th>
                                                 </tr>
                                             </thead>
 
                                             <tbody>
                                                 <tr>
                                                     <td>
                                                       
                                                         1
                                                     
                                                     </td>
                                                     <td>
                                                         <h5 className="text-truncate font-size-14 text-dark">{ downloadStatus.subject_name }</h5>
                                                        
                                                     </td>
                                                     <td className='text-dark' style={{ textTransform:'capitalize' }}>{ id }</td>
                                                     <td>{ downloadStatus.due_date }</td>
                                                     <td>{ downloadStatus.grade }</td>
                                                     <td>{ downloadStatus.memo === null ? <span className="badge bg-info">Not Available</span> : 
                                                      <button className="btn btn-info btn-sm btn-rounded"  onClick={()=> downloadFile(downloadStatus.id,downloadStatus.subject_name,'pdf','memo')}>
                                                      <i className='mdi mdi-download'></i> Download
                                                    </button> }</td>
                                                     <td><span className="badge bg-success">
                                                     { downloadStatus.status === 'Yes' ? 'Available' : 'Not Available' }
                                                       </span></td>
                                                     <td>
                                                         <div className="dropdown">
                                                           
                                                             <button className="btn btn-info btn-sm btn-rounded"
                                                              onClick={()=> downloadFile(downloadStatus.id,downloadStatus.subject_name,'pdf','downloadFile')}>
                                                               <i className='mdi mdi-download'></i> Download
                                                             </button>
                                                           
                                                          
                                                         </div>
                                                     </td>
                                                 </tr>
                                                 </tbody>
 
 
               </table>
        </div> : 
 
        handleErrs('danger','No file available for download','message') }
 
 
        </div>
    )
 
 }


    const handleSubject = (tm) => {
                if(tm ===''){
                    setSubject(null)
                }else{
                    setLoadMe(true);
                    setCate(tm)
                axios.get('/api-fetchgrade-termsubject/'+curGrade+'/'+term,{
                    headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                            setTimeout(()=>{
                                 setLoadMe(false)
                                if(res.data.subjectData !== 'empty'){
                                    setSubject(res.data.subjectData)   
                              }else{
                                  setSubject(null)
                              }
                            },1000)
                     
                }).catch((err)=>{
                    console.log(err)
                })
            }
    }

   const handleCentre = (cid) => {
            if(cid !== ''){
            setLoadMe(true)
            axios.get('/api-list-currentgrade/'+cid+'/Tutor',{
                 headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                        setTimeout(()=>{
                            setLoadMe(false)
                          
                            if(res.data.gradeData !== 'empty'){
                                setGrade(res.data.gradeData)
                           }else{
                                setGrade(null)
                           }
                        },1000)
                 
            }).catch((err)=>{
                  console.log(err)
            })
        }else{ setGrade(null)}
   }
   
    const displayOption = () => {
             return (
                 
                
                <div className='row'>
                       <form id='restForm'>
                     <div className='card'>
                        <div className='card-body row'>
                            <h3 className='card-title'>Download {id}</h3>
                            <hr style={{ color:'red' }} />
                         

                            <div className="col-sm-auto col-md-2 mt-4">
                        <label className="" htmlFor="autoSizingSelect">Year</label>
                        <select className='form-select' onChange={(e)=>setYr(e.target.value)}>
                            <option value={''}>Choose...</option>
                            { years && years.map((y)=>{
                                  return (
                                    <option key={y.id} value={y.year}>{y.year}</option>
                                  )
                            })}
                        </select>

                            </div>


                            <div className="col-sm-auto col-md-2 mt-4">
                        <label className="" htmlFor="autoSizingSelect">Centres</label>
                        <select className='form-select' onChange={(e)=>handleCentre(e.target.value)}>
                            <option value={''}>Choose...</option>
                             { centData !== null ? centData && centData.map((c)=>{
                                  return(
                                      <option key={c.id} value={c.centre_id}>{c.centre_name}</option>
                                  )

                             }) : <option value={''} disabled>Not Available</option>}
                        </select>

                            </div>



                        


                            <div className="col-sm-auto col-md-2 mt-4">
                        <label className="" htmlFor="autoSizingSelect">Grade</label>
                        <select className='form-select' onChange={(e) => setCurGrade(e.target.value)}>
                            <option value={''}>Choose...</option>
                             { grade === null ? <option value={''} disabled>Not Available</option> :
                              grade && grade.map((g)=>{
                                   return (
                                      <option key={g.id} value={g.grade}>{'Grade '+g.grade}</option>
                                   )
                              })
                             }
                        </select>

                            </div>


                            <div className="col-sm-auto col-md-2 mt-4">
                        <label className="" htmlFor="autoSizingSelect">Term</label>
                        <select className='form-select' onChange={(e)=> getAssessmentCate(e.target.value)}>
                            <option value={''}>Choose...</option>
                          { curGrade !=='' ? <>
                          <option value={1}>Term 1</option>
                            <option value={2}>Term 2</option>
                            <option value={3}>Term 3</option>
                            <option value={4}>Term 4</option>
                                  </> : <option disabled>Not Available</option> }
                            
                        </select>

                            </div>

                            <div className="col-sm-auto col-md-2 mt-4">
                  <label className="" htmlFor="autoSizingSelect">Assessment Category</label>
                   
                  <select className="form-select form-control"  onChange={(e)=> handleSubject(e.target.value)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Choose </option>
                  { assCate.length > 0 ? assCate && assCate.map((c,i)=>{
                                                            return(
                                                              <option key={i} value={c}>{c}</option>
                                                            )
                                                     }) : <option disabled>Not available</option> }
                                                  
                      
                  </select>
                </div>



                            <div className="col-sm-auto col-md-2 mt-4">
                        <label className="" htmlFor="autoSizingSelect">Subject</label>
                        <select className='form-select' onChange={(e)=> controlDownload(e)}>
                            <option value={''}>Choose...</option>
                            { subject === null ? <option disabled>Not Available</option>
                            : subject && subject.map((s)=>{
                                    return(
                                          <option value={s.subject_id}>{ s.names }</option>
                                    )
                            })}

                        </select>

                            </div>

                                { loadMe === false ? null :
                            <div className='col-md-12 mt-4'>
                                <div className='text-center'>
                                        { handleErrs('success',null,'load') }
                                </div>
                            </div> }



                        </div>
                    
                    </div>
                    </form>
                   
                </div>
             )
    }



 useEffect(()=>{
    setDownloadStatus(null);
    setDisplayDown(false);

      try{

        axios.get('/api-centreaccademicyear-datas',{
            headers:{'Content-Type':'application/json'}
         }).then((res)=>{
          setTimeout(()=>{
                      
                        setLoadDatas(true)
                        setYears(res.data.accYear);
                     
                     
                      if(res.data.centreData !== 'empty'){
                               setCentData(res.data.centreData);
                      }else
                      {
                           setCentData(null)
                      }
                     
                   },800)
         }).catch((err)=>{
             console.log(err)
         })

      }catch(err){
            console.log(err)
      }
  
 },[id])
 return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
        { loadDatas === false ?
      <div id="preloader">
             <div id="status">
                 <div className="spinner-chase">
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                 </div>
             </div>
         </div>  : null }

         { loadfull === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

          <div className="wrapper">
              <span className="loader"></span>
              <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

              <p style={{marginLeft: -30, marginTop: 120}}>Processing your request. Please wait...</p>
          </div>

          </div>
         }

       { loadDatas === false ? null : <div> {displayOption() }
       
       </div> }

       { displayDown === false ? null : handleDownloadData()}

       <FootNav />
        </div>
    </div>
</div>
 )

}

export default AccessDownload