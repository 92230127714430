import React from 'react'

function FootNav() {
     const curDate = new Date().getFullYear();
  return (
    <div>
         <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                © { curDate } Horion.
              </div>
              <div className="col-sm-6">
                <div className="text-sm-end d-none d-sm-block">
                  Home tutors
                </div>
              </div>
            </div>
          </div>
        </footer>
    </div>
  )
}

export default FootNav