import React from 'react'

function SubjectAddHandler({cartHandler}) {


    const checkPrice = (price) => {
        if(price === null || price === ''){
              return 0;
        }else{
              return price;
        }
}

  const handleCart = () =>{
        let check = cartHandler.cartItems.length;
          return check;
  }

  const checkIfSubjectExistInCart = (sid) =>{
      if(cartHandler.cartItems.find((item) => item.id === sid)){
          return true;
      }
  }

  const calculateCart = (type) => {
                let total = 0;
                cartHandler.cartItems.map((s) => {
                        total = total + parseInt(s.price);
                })

              

                  let mtotal, wtot;

                if(type === 'all'){

                   mtotal = parseInt(cartHandler.handleDurationPrice()) + total;
                }else{
                    mtotal = total;
                }
 

                if( cartHandler.subScribe === ''){
                        wtot = total
                }else{
                      wtot = mtotal;
                }

                return wtot;
                 

  }



  return (
    <div>

<form>
                                                                

                                                                { cartHandler.notify === null ? null : cartHandler.notify }
                                                               <div className='mb-3 form-group'>
                                                               <label>Select your grade</label>
                                                               <select className='form-select' onChange={(e)=> cartHandler.handleGrade(e.target.value)}>
                                                                   <option value={''}>Choose...</option>
                                                                   { cartHandler.grade && cartHandler.grade.map((g)=>{
                                                                         return (
                                                                            <option key={g.id} value={g.names+'=>'+g.valid_term+'=>'+g.term+'=>'+g.year}>{'Grade '+g.names}</option>
                                                                         )
                                                                   })}
                                                               </select>
                                                               </div>

                                                               { cartHandler.displayTerm !== false ?
                                                               <div className='mb-3 row'>
                                                                   
                                                                   <div className='col-md-12'>
                                                                       <label>Select Term for <strong>Grade { cartHandler.selGrade}</strong></label>

                                                                   { cartHandler.termcheck === '1' ? 
                                                                   <select className='form-select' onChange={(e)=> cartHandler.handleTermSelection(e.target.value)}>
                                                                        
                                                                        <option value="">Choose...</option>
                                                                        <option value={1}>Term 1</option>
                                                                        <option value={2}>Term 2</option>
                                                                        <option value={3}>Term 3</option>
                                                                        <option value={4}>Term 4</option>
                                                                        
                                                                        
                                                                   </select>
                                                                  : cartHandler.termcheck === '2' ?

                                                                  <select className='form-select' onChange={(e)=> cartHandler.handleTermSelection(e.target.value)}>
                                                                        
                                                                  <option value="">Choose...</option>
                                                                  <option value={1} disabled>Term 1 Not Available</option>
                                                                  <option value={2}>Term 2</option>
                                                                  <option value={3}>Term 3</option>
                                                                  <option value={4}>Term 4</option>
                                                                  
                                                                  
                                                             </select>  : cartHandler.termcheck === '3' ? 
                                                              <select className='form-select' onChange={(e)=> cartHandler.handleTermSelection(e.target.value)}>
                                                                        
                                                              <option value="">Choose...</option>
                                                              <option value={1} disabled>Term 1 Not Available</option>
                                                              <option value={2} disabled>Term 2 Not Available</option>
                                                              <option value={3}>Term 3</option>
                                                              <option value={4}>Term 4</option>
                                                              
                                                              
                                                         </select>
                                                            : cartHandler.termcheck === '4' ?
                                                            <select className='form-select' onChange={(e)=> cartHandler.handleTermSelection(e.target.value)}>
                                                                        
                                                            <option value="">Choose...</option>
                                                            <option value={1} disabled>Term 1 Not Available</option>
                                                            <option value={2} disabled>Term 2 Not Available</option>
                                                            <option value={3} disabled>Term 3 Not Available</option>
                                                            <option value={4}>Term 4</option>
                                                            
                                                            
                                                       </select>
                                                         :  <select className='form-select'>
                                                                        
                                                         <option value="">Choose...</option>
                                                         <option value={1} >Term 1</option>
                                                            <option value={2}>Term 2 </option>
                                                            <option value={3}>Term 3 </option>
                                                            <option value={4}>Term 4 </option>
                                                         
                                                       
                                                        </select>
       }


                                                                       </div>  

                                                                                  
                                                                 
                                                               </div> : null
                                                           }

                                                                   { cartHandler.loadunRegSub === false ? null :
                                                                   
                                                                   <div>
                                                                        <div className="table-responsive mt-4">
                                                                           <h6>Select available subject for student</h6>
                                                                           <hr style={{ marginTop:-5 }} />
                                                                        <table className="table align-middle table-nowrap table-check">
                                                                        <thead className="table-light">
                                                                       <tr>
                                                                          
                                                                           <th className="align-middle">Subject Name</th>
                                                                           <th className="align-middle">Category</th>
                                                                           <th className="align-middle">Terms</th>
                                                                           <th className="align-middle">Amount</th>
                                                                           <th className="align-middle">Action</th>
                                                                       </tr>
                                                                   </thead>
                                                                   <tbody>

                                                                       { cartHandler.loadSubject === false ? null :
                                                                        cartHandler.loadSubject && cartHandler.loadSubject.map((sub)=>{
                                                                            return (

                                                                               <tr key={sub.id}>
                                                      
                                                                               <td><p className="text-body fw-bold">{ sub.names}</p> </td>
                                                                               <td>{ sub.cate}</td>
                                                                               <td>
                                                                               { sub.term}
                                                                               </td>
                       
                                                                               <td>
                                                                               { checkPrice(sub.price)}
                                                                               </td>
                       
                                                                            
                                                                              
                                                                             
                                                                               <td>
                                                                                  { checkIfSubjectExistInCart(sub.subject_id) === true ? 
                                                                                   <button onClick={() => cartHandler.removeSubjectFromCart(sub.subject_id)}  type="button" className="btn btn-danger btn-sm btn-rounded">
                                                                                   <i className="bx bx-trash font-size-16 align-middle me-2"></i>  Remove
                                                                                   </button>
                                                                                   :

                                                                                   <button onClick={() => cartHandler.handleAddSubject(sub.subject_id, checkPrice(sub.price), sub.names, sub.cate)} type="button" className="btn btn-primary btn-sm btn-rounded">
                                                                                   <i className="bx bx-plus align-middle me-1"></i>  Add Subject
                                                                                   </button>

                                                                                 
                                                                        }

                                                                               </td>
                                                                              
                                                                           </tr>

                                                                            )
                                                                        })
                                                                       }
                                                  

                                                   </tbody>
                                                         </table>
                                                                        </div>

                                                               

                                                                        <div className='col-md-12'>
                                                                           <label>Select your payment option</label>
                                                                       <select className='form-select' onChange={(e)=> cartHandler.handleSub(e.target.value)}>
                                                                           <option value={''}>Choose  </option>
                                                                           <option value={'Yearly'}>Yearly</option>
                                                                           <option value={'Termly'}>Termly</option>
                                                                       </select>
                                                                       </div> 

                                                                       <div className='row mt-4'>

                                                                       <div className='col-md-4'>
                                                                           </div>
                                                                       <div className='col-md-8'>
                                                                        { handleCart() === 0 ? null : 
                                                                       <div className="table-responsive">
                                               <table className="table table-nowrap">
                                               <thead>
                                                   <tr>
                                                       <th style={{width: 70}}></th>
                                                       <th>Details</th>
                                                       <th className="text-end">Price</th>
                                                   </tr>
                                               </thead>
                                               <tbody>
                                                   <tr>
                                                       <td></td>
                                                       <td>{ handleCart() > 1 ? <div>  { handleCart() } Subjects</div> : <div>  { handleCart() } Subject </div> } </td>
                                                       <td className="text-end">R { calculateCart(null) }</td>
                                                   </tr>

                                                   { cartHandler.subScribe === '' ? null :
                                                   
                                                   <tr>
                                                       <td></td>
                                                       <td>{ cartHandler.subScribe }</td>
                                                       <td className="text-end"> { cartHandler.handleDurationPrice() }</td>
                                                   </tr>
                                                        
                                                        }

                                                 
                                                   <tr>
                                                       <td colspan="2" className="text-end">Sub Total</td>
                                                       <td className="text-end">{ calculateCart('all') }</td>
                                                   </tr>
                                                  
                                                   <tr>
                                                       <td colspan="2" className="border-0 text-end">
                                                           <strong>Total</strong></td>
                                                       <td className="border-0 text-end"><h4 className="m-0">{ calculateCart('all') }</h4></td>
                                                   </tr>
                                               </tbody>
                                           </table>
                                                                         </div>
                                                                        }

                                                                       </div>
                                                                       </div>

                                                                   </div>
                                                                   }






                                                                   <div className='text-center'>
                                                               { cartHandler.loading === false ? null : cartHandler.handleErrs('danger',null,'load') }
                                                               </div>

                                                             



                                                           </form>
    </div>
  )
}

export default SubjectAddHandler