import React,{useState, useEffect} from 'react'
import ErrorMessage from '../userAuth/ErrorMessage';
import axios from 'axios';
import FootNav from '../Nav/FootNav';

function NotifyUser() {
   const [notis, setNotis] = useState([]);
   const [loadMe, setLoadMe] = useState(false);
   const [motis, setMotis] = useState(false)

   const handleErrs = ($type,$msg,$uitype) =>{
    let errorChild = {types:$type, msg:$msg, uiType:$uitype}
  return (
    <div>
    <ErrorMessage errors={errorChild} />
    </div>
  
  )
}

 const handleSeenNotify= (nid) =>{
          axios.post('/update-notify',{nid},{
             headers:{'Content-Type':'application/json'}
          }).then((res)=>{
                  if(res.data.nops === 'success'){
                       setMotis(true)
                  }
          }).catch((err)=>{
                console.log(err)
          })
 }


const getNotify = () => {
    
  axios.get('/all-notification',{
      headers:{'Content-Type':'application/json'}
  }).then((res)=>{
     let dap = res.data.mnoti;
        setTimeout(()=>{
          setLoadMe(true)
        
          if(dap.length > 0){
             setNotis(dap)
          }else
          {
              setNotis(0)
          }
        },1000)
       
  }).catch((err)=>{
          console.log(err)
  })
}

  useEffect(()=>{
      getNotify();
  },[motis])

  return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">

      { loadMe === false ?
             <div id="preloader">
             <div id="status">
                 <div className="spinner-chase">
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                 </div>
             </div>
         </div> : null }
          

            <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-1">Notifications</h4>
                                        <hr className='text-danger' style={{ marginTop:-1 }} />

                                        <div className="row">

                                           
                                         

                                            <div className="col-xl-12">

                                                { notis.length > 0 ?

                                                <div className="mt-4">
                                                    
                                                    <div className="accordion accordion-flush" id="accordionFlushExample">

                                                        { notis && notis.map((n, index)=>{
                                                            return(
                                                              <div className="accordion-item">
                                                              <h2  className={`accordion-header ${ n.status === 'unseen' ? 'bg-soft bg-success': null}`} id={"flush-heading"+index}>
                                                                  <button onClick={()=> handleSeenNotify(n.id)} className={`accordion-button fw-medium ${index === 0 ? 'collapsed' : null }`} type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse"+index} aria-expanded="true" aria-controls={"flush-collapse"+index}>
                                                                  { n.title } <small style={{ marginLeft:10 }}>{n.date_created}</small>
                                                                  </button>
                                                              </h2>

                                                              <div id={"flush-collapse"+index} className={`accordion-collapse collapse ${ index ===0 ? 'show' : null} `} aria-labelledby={"flush-heading"+index} data-bs-parent="#accordionFlushExample">
                                                                  <div className="accordion-body text-muted">
                                                                      { n.details }
                                                                     </div>
                                                              </div>
                                                          </div>
                                                            )
                                                        })}
                                 

                                                      </div>
                                                   
                                                </div> :

                                                <div className='text-center'>
                                                    { handleErrs(null,'You have no notification','not')}
                                                  </div>
                                                  }

                                            </div>
                                           
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                       

         

            <FootNav />
        </div>
    </div>
  </div>
  )
}

export default NotifyUser