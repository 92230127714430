import React,{useState, useEffect} from 'react'
import FootNav from '../Nav/FootNav'
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../userAuth/ErrorMessage';



function ParentDashBoard() {
    const [pName, setPName] = useState();
    const [wallet, setWallet] = useState();

    const [availableStud, setAvailableStud] = useState(false);
    const [paidInv, setPaidInv] = useState();
    const [unpaidInv, setUnPaidInv] = useState();

    const [loadStudent, setLoadStudent] = useState([]);
    const [ userType, setUserType] = useState();

    const [totalLen, setTotalLen] = useState(0);

    const history = useHistory();

    const Currency = (num) =>{
        let bala = parseInt(num)
    return bala.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

    const handleErrs = ($type,$msg,$uitype) =>{
      let errorChild = {types:$type, msg:$msg, uiType:$uitype}
    return (
      <div>
      <ErrorMessage errors={errorChild} />
      </div>
    
    )
 }

 const getUsers = () =>{
  axios.get('/checklogin/status',{
   headers: { 'Content-Type': 'application/json'},
  }).then((res) =>{
              setPName(res.data.userData[0].names);              
              setUserType(res.data.userType);
              setWallet(res.data.wallet[0].balance);
              setPaidInv(res.data.paid);
              setUnPaidInv(res.data.unpaid);

              const mstd = res.data.studD;
            
               if(mstd !== 'empty'){
                setAvailableStud(true)
                  
                setLoadStudent(res.data.studD);
                
               }else{
                setLoadStudent([])
                setAvailableStud(false)
               }
    

  })
}



const fetchStudent = async () =>{
  await axios.get('/get-parent-student',{ 
        headers: {'Content-Type': 'application/json'}
   }).then((response)=>{
                 let cutstud = response.data.studData;
                   if(cutstud !== 'empty'){
                setTotalLen(cutstud.length);
                   }else{
                    setTotalLen(0);
                   }
   })
}
 

  useEffect(()=>{
     getUsers();
    fetchStudent();
  },[])
  return (
    <div>
         <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
           
           <div className='row'>

               <div className="col-xl-4">
                                <div className="card overflow-hidden">
                                    <div className="bg-primary bg-soft">
                                        <div className="row">
                                            <div className="col-7">
                                                <div className="text-primary p-3">
                                                    <h5 className="text-primary">Welcome Back !</h5>
                                                   {/* <p>It will seem like simplified</p> */}
                                                </div>
                                            </div>
                                            <div className="col-5 align-self-end">
                                                {/*<img src="assets/images/profile-img.png" alt="" className="img-fluid" /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <i className="mdi mdi-account-circle text-primary img-thumbnail rounded-circle h1" />
                                                </div>
                                                <h5 className="font-size-15 text-truncate">{ pName }</h5>
                                                <p className="text-muted mb-0 text-truncate">{ userType }</p>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="pt-4">

                                                    <div className="row">
                                                        <div className="col-3">
                                                          
                                                        </div>
                                                        <div className="col-9 text-center">
                                                            <h5 className="font-size-15">R { wallet === null ? '0.00' : Currency(wallet) }</h5>
                                                            <p className="text-muted mb-0">Wallet Balance</p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4"> 
                                                         </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                    </div>


                    <div className="col-xl-8">
                    <div className="row">


                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <p className="text-muted fw-medium mb-2">Total Learners</p>
                                                        <h4 className="mb-0">{ totalLen }</h4>
                                                    </div>

                                                    <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                                        <span className="avatar-title">
                                                            <i className="bx bx-check-circle font-size-24"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <p className="text-muted fw-medium mb-2">Paid Invoice</p>
                                                        <h4 className="mb-0">{ paidInv }</h4>
                                                    </div>

                                                    <div className="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                                                        <span className="avatar-title">
                                                            <i className="bx bx-hourglass font-size-24"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <p className="text-muted fw-medium mb-2">Unpiad Invoice</p>
                                                        <h4 className="mb-0">{ unpaidInv }</h4>
                                                    </div>

                                                    <div className="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                                                        <span className="avatar-title">
                                                            <i className="bx bx-package font-size-24"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                      </div>



                      <div className='col-md-12'>
                      <div className="table-responsive">

                        <h4>Learners</h4>
                        { loadStudent.length > 0 ?
                        
                       
                        
                          <div>
                        <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" className='text-center'>Names</th>
                    <th scope="col" className='text-center'>Academic</th>
                    <th scope="col" className='text-center'>Grade</th>
                    <th scope="col" className='text-center'>Registration Status</th>
                    <th scope="col" className='text-center'>Profile Status</th>
                 
                     <th scope="col" className='text-center'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  { 
                        
                  loadStudent && loadStudent.map((s, index)=>{
                      return (
                        <tr key={s.id}>
                        <td> <h5 className="text-truncate font-size-14 text-dark">{1 + index}</h5></td>
                        <td className='text-center'>
                          <h5 className="text-truncate font-size-14 text-dark">{s.names}</h5>
                         
                        </td>
                        <td className='text-center'>{ s.academic_year }</td>
                        <td className='text-center'>{ s.grade === null ? 'N/A' : 'Grade '+s.grade }</td>
                        <td className='text-center'>
                           { s.reg_prog === 'Complete' ? <span className="badge bg-success">Complete</span>
                           :
                          <span className="badge bg-danger">UnCompleted</span>
                      }
                          </td>

                          <td className='text-center'>
                           { s.profile_status === 'Active' ? <div className='d-flex' style={{ marginLeft:10 }}> { handleErrs('Active',null,'profile-status') } Active </div>: <div className='d-flex' style={{ marginLeft:'10%' }}>{ handleErrs('Not Active',null,'profile-status')}  Not Active</div> }
                        </td>

                    
                        <td className='text-center'>
                     <button className="btn btn-primary waves-effect waves-light btn-sm" onClick={() => history.push('/learners/student/'+s.stud_userid) }>View Profile <i className="mdi mdi-arrow-right ms-1"></i></button>
                        </td>
                      </tr>
                      )
                  })}
                
                 
                </tbody>
                         </table>

              <div className='text-center'>
              <button className="btn btn-primary waves-effect waves-light btn-sm" onClick={() => history.push('/learners')}>View All Learners <i className="mdi mdi-arrow-right ms-1"></i></button>
                  
              </div></div> : 
               <div>
               { handleErrs(null,"You have no learner at this moment",'not') }
                     <div className='text-center'>
                     <button className="btn btn-primary waves-effect waves-light btn-sm" onClick={() => history.push('/learners?add=learner')}>Add New Learner <i className="mdi mdi-arrow-right ms-1"></i></button>
      
                     </div>
                 </div>
              }

                        </div>

                      </div>

                      </div>

            
           </div>
           
        </div>
        {/* end modal */}
          
          <FootNav />
      </div>
    </div>
    </div>
  )
}

export default ParentDashBoard