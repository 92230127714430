import React,{useState, useEffect} from 'react'
import ErrorMessage from '../../userAuth/ErrorMessage'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import FootNav from '../../Nav/FootNav'

function AccessView() {
      const id  = 'all';
      const [loadDatas, setLoadDatas] = useState(false)
      const [years, setYears] = useState();
      const [centData, setCentData] = useState(null)
      const [loadfull, setLoadFull] = useState(false)
      const [yr, setYr] = useState();
      const [curGrade, setCurGrade] = useState('');
      const [grade, setGrade] = useState();
      const [loadMe, setLoadMe] = useState(false);
      const [cid, setCid] = useState();
      const [stud, setStud] = useState(null);
      const [subject, setSubject] = useState(null);
      const [term, setTerm] = useState();
      const [downloadStatus, setDownloadStatus] = useState(null);
      const [displayDown, setDisplayDown] = useState(false);
      const [studID, setStudID] = useState();
      const [notify, setNotify] = useState(null);
      const [capData, setCapData] = useState(null);
      const [scores, setScore] = useState(null);
    
      


      const handleErrs = ($type,$msg,$uitype) =>{
        let errorChild = {types:$type, msg:$msg, uiType:$uitype}
      return (
        <div>
        <ErrorMessage errors={errorChild} />
        </div>
      
      )
    }


const getScoreRating = (type,score) =>{

    let rate;
   if(type === 'percent'){
         
       switch(score){
           case '7':
                 rate = '80% - 100%'
             break;
           case '6':
             rate = '70% - 79%'
             break;
           case '5':
             rate = '60% - 69%'
             break;
            case '4':
             rate = '50% - 59%'
            break;
            case '3':
             rate = '40% - 49%'
             break;
             case '2':
               rate = '30% - 39%'
               break;
            case '1':
             rate = '0% - 29%'
              break;
              default:
               rate = '-'
       }
   
      
   }else{
   switch(score){
     case '7':
           rate = 'A+'
       break;
     case '6':
       rate = 'A'
       break;
     case '5':
       rate = 'B'
       break;
      case '4':
       rate = 'C'
      break;
      case '3':
       rate = 'D'
       break;
       case '2':
         rate = 'E'
         break;
      case '1':
       rate = 'F'
        break;
        default:
         rate = '-'
   }
   
   
   }
   
   return rate;
   
   }
   
  


    const handleSubject = (tm) => {
        if(tm ===''){
            setSubject(null)
        }else{
            setLoadMe(true);
            setTerm(tm)
        axios.get('/api-fetchgrade-termsubject/'+curGrade+'/'+tm,{
            headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                    setTimeout(()=>{
                         setLoadMe(false)
                        if(res.data.subjectData !== 'empty'){
                            setSubject(res.data.subjectData)   
                      }else{
                          setSubject(null)
                      }
                    },1000)
             
        }).catch((err)=>{
            console.log(err)
        })
    }
}


const controlDownload = (e) =>{
    let tm = e.target.value;
   
        setLoadFull(true)
        setDisplayDown(false)
        axios.get('/fetchdata-capture/'+curGrade+'/null/'+tm+'/'+yr+'/'+id+'/'+studID,{
          headers:{'Content-Type':'application/json'}
      }).then((res)=>{
              setTimeout(()=>{
                    setLoadFull(false);
                    setDisplayDown(true)

                    if(res.data.scoreData !== 'empty'){
                      setCapData(res.data.scoreData)
                   }else
                   {
                       setCapData(null)
                   } 

                      
              },1200)
       }).catch(err =>{
             console.log(err)
       })
}





const handleDownloadData = () =>{

    return(
      <div className="col-lg-12">
       { capData !== null ?
 
        <div className="table-responsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                                     <thead>
                                                <tr>
                                                    <th scope="col" style={{ width: 100 }}>#</th>
                                                    <th scope="col">Subject Name</th>
                                                    <th scope="col">Term</th>
                                                    <th scope="col">Assessment</th>
                                                    <th scope="col">Score Rating</th>
                                                    <th scope="col">Percentage</th>
                                                    <th scope="col">Symbol</th>
                                                  
                                                </tr>
                                            </thead>

                                            <tbody>

                                               { capData && capData.map((x, index)=>{
                                                 return (
                                                  <tr key={x.id}>
                                                  <td>
                                                    
                                                      { index + 1}
                                                  
                                                  </td>
                                                  <td>
                                                      <h5 className="text-truncate font-size-14 text-dark">{ x.subject_name }</h5>
                                                     
                                                  </td>
                                                  <td>Term { x.term }</td>
                                                  <td className='text-dark' style={{ textTransform:'capitalize' }}>{ x.cate }</td>
                                                 
                                                  <td style={{ fontWeight:'bolder' }}>
                                                      { x.score }
                                                  </td>
                                                  <td>
                                                     { getScoreRating('percent',x.score)}
                                                  </td>
                                                  <td style={{ fontWeight:'bolder' }}>
                                                     { getScoreRating(null,x.score)}
                                                  </td>
                                              </tr>
                                                 )
                                               })}
                                               
                                                </tbody>


              </table>
 
 
        </div> : 
 
        handleErrs('danger','Oops! Unable to get details for the selected subject','message') }
 
 
        </div>
    )
 
 }
 


      const getCentreStudent = (grd) => {
                        setCurGrade(grd)
                        setLoadMe(true)
                        if(grd === ''){}else{
                axios.get('/api-fetchcentrestud/'+grd+'/'+cid,{
                     headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                            setTimeout(()=>{
                                 setLoadMe(false)
                                if(res.data.learn !== 'empty'){
                                    setStud(res.data.learn);
                               }else
                               {
                                    setStud(null)
                               }
                            },1000)
                     
                }).catch(err=>{
                     console.log(err)
                })
            }
      }


      const handleCentre = (cid) => {
        if(cid !== ''){
        setLoadMe(true)
        setCid(cid)
        axios.get('/api-list-currentgrade/'+cid+'/Tutor',{
             headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                    setTimeout(()=>{
                        setLoadMe(false)
                      
                        if(res.data.gradeData !== 'empty'){
                            setGrade(res.data.gradeData)
                       }else{
                            setGrade(null)
                       }
                    },1000)
             
        }).catch((err)=>{
              console.log(err)
        })
    }else{ setGrade(null)}
}

      


      const displayCaptureForm = () => {

         return(
                        <div className='row'>
                            <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-body row'>
                                <h3 className='card-title mb-4'>View Assessment </h3>
                                <hr style={{ marginTop:-19, color:'red' }}/>

                            <div className="col-sm-auto col-md-2 mt-2">
                            <label className="" htmlFor="autoSizingSelect">Year</label>
                            <select className='form-select' onChange={(e)=>setYr(e.target.value)}>
                            <option value={''}>Choose...</option>
                            { years && years.map((y)=>{
                                  return (
                                    <option key={y.id} value={y.year}>{y.year}</option>
                                  )
                            })}
                        </select>
                            </div>

                            <div className="col-sm-auto col-md-3 mt-2">
                            <label className="" htmlFor="autoSizingSelect">Centres</label>
                            <select className='form-select' onChange={(e)=>handleCentre(e.target.value)}>
                            <option value={''}>Choose...</option>
                             { centData !== null ? centData && centData.map((c)=>{
                                  return(
                                      <option key={c.id} value={c.centre_id}>{c.centre_name}</option>
                                  )

                             }) : <option value={''} disabled>Not Available</option>}
                        </select>
                            </div>

                            <div className="col-sm-auto col-md-2 mt-2">
                            <label className="" htmlFor="autoSizingSelect">Grade</label>
                            <select className='form-select' onChange={(e) => getCentreStudent(e.target.value)}>
                            <option value={''}>Choose...</option>
                             { grade === null ? <option value={''} disabled>Not Available</option> :
                              grade && grade.map((g)=>{
                                   return (
                                      <option key={g.id} value={g.grade}>{'Grade '+g.grade}</option>
                                   )
                              })
                             }
                        </select>
                            </div>

                            <div className="col-sm-auto col-md-3 mt-2">
                            <label className="" htmlFor="autoSizingSelect">Learner</label>
                             <select className='form-select' onChange={(e)=> setStudID(e.target.value)}>
                                <option value={''}>Choose...</option>
                                { stud === null ? <option disabled>Not Available</option>
                                : stud && stud.map((x)=>{
                                     return(
                                        <option value={x.stud_userid}>{x.names}</option>
                                     )
                                })}
                             </select>
                            </div>

                            <div className="col-sm-auto col-md-2 mt-2">
                            <label className="" htmlFor="autoSizingSelect">Term</label>
                             <select className='form-select' onChange={(e)=> controlDownload(e) } >
                                <option value={''}>Choose...</option>
                            { curGrade === '' ? <option disabled>Not Available</option> : <>
                            <option value={1}>Term 1</option>
                                <option value={2}>Term 2</option>
                                <option value={3}>Term 3</option>
                                <option value={4}>Term 4</option>
                            </>   }
                             </select>
                            </div>

                            { loadMe === false ? null :
                            <div className='col-md-12 mt-4'>
                                <div className='text-center'>
                                        { handleErrs('success',null,'load') }
                                </div>
                            </div> }


                              </div>
                            </div>
                     </div>
                 </div>
                
           
         )

      }

  

      useEffect(()=>{
          try{
    
            axios.get('/api-centreaccademicyear-datas',{
                headers:{'Content-Type':'application/json'}
             }).then((res)=>{
              setTimeout(()=>{
                          
                            setLoadDatas(true)
                            setYears(res.data.accYear);
                         
                         
                          if(res.data.centreData !== 'empty'){
                                   setCentData(res.data.centreData);
                          }else
                          {
                               setCentData(null)
                          }
                         
                       },800)
             }).catch((err)=>{
                 console.log(err)
             })
    
          }catch(err){
                console.log(err)
          }
      
     },[id])

  return (
    <div className="main-content">
<div className="page-content">
  <div className="container-fluid">
  { loadDatas === false ?
      <div id="preloader">
             <div id="status">
                 <div className="spinner-chase">
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                 </div>
             </div>
         </div>  : null }

         { loadfull === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

          <div className="wrapper">
              <span className="loader"></span>
              <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

              <p style={{marginLeft: -30, marginTop: 120}}>Processing your request. Please wait...</p>
          </div>

          </div>
         }

      { displayCaptureForm() }

      { displayDown === false ? null : handleDownloadData()}
      <FootNav />
    </div>
 </div>
</div>
  )
}

export default AccessView