import React, { useState, useEffect} from 'react';
import axios from 'axios'
import ErrorMessage from '../userAuth/ErrorMessage';
import download from 'downloadjs';

function Support() {

    const [supportData, setSupportData] = useState();
    const [secondData, setSecondData] = useState(null);
    const [sid, setSid] = useState();
    const [loadSuport, setLoadSupport] = useState(null);
    const [loading, setLoading] = useState(false)
    const [title,setTitle] = useState();
    const [noti, setNoti] = useState(false);


    const handleErrs = ($type,$msg,$uitype) =>{
      let errorChild = {types:$type, msg:$msg, uiType:$uitype}
    return (
      <div>
      <ErrorMessage errors={errorChild} />
      </div>
    
    )
 }



    const downloadFile = (id,type) => {
            setLoading(true)
          axios.post('/api-downloadsupport',{id},{
             responseType:'blob'
          }).then((res)=>{
             setTimeout(()=>{
              setLoading(false)
          
              const content = res.headers['content-type'];
              download(res.data, title+type, content)
             },500)
             
         
             
          }).catch((err)=>{
              console.log(err)
          })
    }

    const getCompletSupportData = (id,name) => {
                  setTitle(name)
                axios.get('/get-supportdatas/'+id,{
                   headers: {'Content-Type':'application/json'}
                }).then((res)=>{
                       if(res.data.mdata !== 'empty'){
                                setLoadSupport(res.data.mdata)
                       }else
                       {
                                setLoadSupport(null)
                       }
                })
    }

    const displaySecondData = (id) => {
                setSid(id)
             axios.get('/support-second/'+id,{
              headers: { 'Content-Type': 'application/json'}
             }).then((res)=>{
                  setTimeout(()=>{
                 
                    if(res.data.secData !=='empty'){
                      setSecondData(res.data.secData)
                  }else
                  {
                      setSecondData(null)
                  }
                  },1000)
                
             }) 
    }


  const getSupportData = () =>{
            axios.get('/support-datas',{
              headers:{'Content-Type':'application/json'}
           }).then((res)=>{
                 if(res.data.sdata !== 'empty'){
                          setSupportData(res.data.sdata);
                 }else
                 {
                          setSupportData('Not');
                 }
           })
  }

  useEffect(()=>{
        getSupportData();
  },[])

  return (
 


                        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Support</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Files</li>
                      <li className="breadcrumb-item active">Support</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="d-xl-flex">
              <div className="w-100">
                <div className="d-md-flex">
                  <div className="card filemanager-sidebar me-md-2">
                    <div className="card-body">
                      <div className="d-flex flex-column h-100">
                        <div className="mb-4">
                          <div className="mb-3">
                            <div>
                              <button className="btn btn-primary w-100">
                                 Support Data Filesss
                              </button>
                            
                            </div>
                          </div>
                          <ul className="list-unstyled categories-list">
                            { 
                              supportData === 'Not' ? null :
                            supportData && supportData.map((s)=>{

                         return(
                            <li key={s.id} onClick={()=> displaySecondData(s.year_id)}>
                              <div className="custom-accordion">
                                <a className="text-body fw-medium py-1 d-flex align-items-center" data-bs-toggle="collapse" href={"#categories-collapse"+s.year_id} role="button" aria-expanded="false" aria-controls="categories-collapse">
                                  <i className="mdi mdi-folder font-size-16 text-warning me-2" /> Support data for {s.year} <i className="mdi mdi-chevron-up accor-down-icon ms-auto" />
                                </a>
                                <div className="collapse show" id={"categories-collapse"+s.year_id}>

                                  <div className="card border-0 shadow-none ps-2 mb-0">
                                  { 
                                      sid === s.year_id ?
                                  secondData === null ? null :

                                    <ul className="list-unstyled mb-0">
                                       { secondData && secondData.map((x)=>{
                                         return(
                                          <li key={x.id} onClick={()=> getCompletSupportData(x.id,x.name)} >
                                            <a href="#" className="d-flex align-items-center">
                                              <span className="me-auto"> <i className="bx bxs-folder font-size-14 text-warning" /> {x.name}</span>
                                              <span className="badge bg-success rounded-pill ms-2">{x.numdata}</span>
                                               </a></li>
                                         )
                                       })}
                                    
                                        </ul>
                                        : null
                                     
                            }

                                  </div>
                                </div>
                              </div>
                            </li>
                         )

})

}
                           
                           
                          </ul>
                        </div>
                     
                      </div>
                    </div>
                  </div>
                  {/* filemanager-leftsidebar */}

                  <div className="w-100">
                    <div className="card">
                      <div className="card-body">
                           <h5>My Files</h5>
                                <hr className='text-danger' style={{ marginTop:-5 }} />
                     
                        <div>
                         
                        </div>
                        <div className="mt-0">
                          
                      
                            { loadSuport === null ? 

                                <div>
                               <div className="row justify-content-center">
                              
                               <div className="col-sm-4">
                                   <div className="maintenance-img">
                                       <img src="/assets/images/maintenance.svg" alt="" className="img-fluid mx-auto d-block" />
                                   </div>
                               </div>

                            
                             </div>
                                <div className='text-center'>
                           <h5 className="mt-2 mb-4">No File available to download</h5>
                                
                                </div>

                           </div>
                             
                           :

                           <div className="table-responsive">
                            { loading === false ? null : <p> <i className='text-success'>Processing download. Please wait...</i></p> }
                             { noti !== false ? noti : null }
                          <table className="table project-list-table table-nowrap align-middle table-borderless">
                             <thead>
                               <tr>
                               <th scope="col" style={{width: 100}}>#</th>
                                 <th scope="col">File</th>
                                 <th scope="col">Date modified</th>
                                 <th scope="col">Size</th>
                                 <th scope="col">Date modified</th>
                               </tr>
                             </thead>
                             <tbody>
                              { loadSuport && loadSuport.map((d, i)=>{
                                 return (

                                  <tr key={i}>
                                  <td>1</td>
                                   <td>{d.file_type === 'pdf' ? <i className="mdi mdi-file-pdf font-size-16 align-middle text-primary me-2" /> :
                                   <i className="mdi mdi-file-document font-size-16 align-middle text-primary me-2" /> } {d.file}</td>
                                   <td>{d.date_modify}</td>
                                   <td>{d.filesize}</td>
                                   <td>
                                     <div className="dropdown">
                                     <button type="button" className="btn btn-primary btn-sm btn-rounded" onClick={()=> downloadFile(d.id,d.file)}>
                                                                <b> Download File</b>
                                                             </button>
  
  
                                      
                                     </div>
                                   </td>
                                 </tr>

                                 )
                              })}
                              
                             
                            
                             </tbody>
                           </table>
                         </div>

                          }

                         


                        </div>
                      </div>
                    </div>
                    {/* end card */}
                  </div>
                  {/* end w-100 */}
                </div>
              </div>

            
            </div>
            {/* end row */}
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                © Skote.
              </div>
              <div className="col-sm-6">
                <div className="text-sm-end d-none d-sm-block">
                  Design &amp; Develop by Themesbrand
                </div>
              </div>
            </div>
          </div>
        </footer>
                               </div>
            

  )


}

export default Support