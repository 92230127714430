import React, { useState} from 'react'
import { useForm } from 'react-hook-form';
import { useHistory} from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import '../../App.css';

function ParentReg({mychilds, changeOption}) {

     const curDate = new Date().getFullYear();
     const [loadDatas, setLoadDatas] = useState(false);
     const [notis, setNotis] = useState(null)
     const history = useHistory();

    const Validschema = Yup.object().shape({
        fullName: Yup.string()
        .required('Full name is required'),

        passNumb: Yup.string()
        .required('Passport/ID No. is required'),

        phone: Yup.string()
        .required('Phone number is required'),

        address: Yup.string()
        .required('Address is required'),

      });

    const formOpt = { resolver: yupResolver(Validschema) };
    const {register, handleSubmit, formState } = useForm(formOpt);
         const {errors} = formState;
    
  
  const handleSubmitParent = (data) =>{
            
    setLoadDatas(true)
        axios.post('/create-account-user',data,{
            headers: { 'Content-Type': 'application/json'},
        }).then((response) =>{
               
                setTimeout(()=>{
                    setLoadDatas(false)
                   
                    if(response.data.status === 'good'){
                        
                               history.push('/');
                    }else{
                     let noti = mychilds.notify('danger',response.data.status);
                      setNotis(noti)
                      
                    }
                },1000)
            

        })

  }

    
  return (
    <div>
    <div id="layout-wrapper">
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center mb-5 text-muted">
              <div className="d-block auth-logo">
             
              <img src="/logo.png" alt="" height={80} className="auth-logo-dark mx-auto" />
              <img src="/logo.png" alt="" height={80} className="auth-logo-light mx-auto" />
              </div>
              <h5 className="mt-3">Complete your home tutor registration</h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">

            { loadDatas === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

                <div className="wrapper">
                    <span className="loader"></span>
                    <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

                    <p style={{marginLeft: -30, marginTop: 120}}>Setting up your account. Please wait...</p>
                </div>

                </div>
            }

            <div className="card">
              <div className="card-body">
                <h5 className="card-title" id="title" style={{ textTransform:'capitalize' }}>{mychilds.myOption} Information </h5>
                <p className="card-title-desc">Provide all details to complete your registration</p>
             
                <form onSubmit={ handleSubmit(handleSubmitParent) }>
                     
                     { notis === null ? null : notis}

                <input type="hidden" name="userType" defaultValue="Parent" {...register('userType')} />
                   
                <div className="row">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input type="text" name="fullName" id="fullName" placeholder="Enter Full Name" {...register('fullName')} className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}  />
                        <label htmlFor="floatingemailInput">Full Name</label>
                        <div className="invalid-feedback">{errors.fullName?.message}</div>
                      </div>
                      
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input type="text"  name="passNumb" id="passNumb" placeholder="Enter ID/Passport Number" {...register('passNumb')} className={`form-control ${errors.passNumb ? 'is-invalid' : ''}`}   />
                        <label htmlFor="floatingemailInput">Whatsapp Number</label>
                        <div className="invalid-feedback">{errors.passNumb?.message}</div>
                       
                      </div>
                    </div>
                  </div>
                 
             
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input type="email"  name="email" id="email" placeholder="Enter Email address" className='form-control' defaultValue={mychilds.logEmail} disabled/>
                        <label htmlFor="floatingemailInput">Email address</label>
                       
                      </div>
                     
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input type="text"  name="phone" id="phone" placeholder="Enter Phone"  {...register('phone')} className={`form-control ${errors.phone ? 'is-invalid' : ''}`}  />
                        <label htmlFor="floatingemailInput">Phone Number</label>
                        <div className="invalid-feedback">{errors.phone?.message}</div>
                      </div>
                     
                    </div>
                  </div>
                 
                 
                  <div className="form-floating mb-3">
                    <input type="text" name="address" id="address" placeholder="Enter Residential Address"  {...register('address')} className={`form-control ${errors.address ? 'is-invalid' : ''}`} />
                    <label htmlFor="floatingnameInput">Address</label>
                    <div className="invalid-feedback">{errors.address?.message}</div>
                  </div>

                  
                 
                  <div className="d-flex row" >

                    <div className='col-lg-6'>
                    <div className="btn btn-dark w-md submit" onClick={() => changeOption(null)}>Back</div>

                    </div>

                    <div className='col-lg-6'>
                    <button type="submit" className="btn btn-danger w-md submit" style={{ float:'right' }}>Submit</button>
           
                        </div>
                
           
                  
                  
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              { curDate } © {mychilds.companyName}
            </div>
            <div className="col-sm-6">
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
    </div>
       
  )
}

export default ParentReg