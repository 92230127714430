import axios from 'axios';
import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ErrorMessage from '../userAuth/ErrorMessage';
import FootNav from '../Nav/FootNav';
function ViewAssess() {
   const id = 'all';
   const [loader, setLoader] = useState(false);
   const [loadBut, setLoadBut] = useState(false);
   const [grade, setGrade] = useState(null);
   const [pid, setPid] = useState();
   const [studData, setStudData] = useState(null);
   const [studYear, setStudYear] = useState();
   const [currTerm, setCurrTerm] = useState();
   const [currGrade, setCurrGrade] = useState();
   const [mData, setMData] = useState(null);
   const [accYear, setAccYear] = useState(null);
   const [displayDown, setDisplayDown] = useState(false);
   const [downloadStatus, setDownloadStatus] = useState(null);
   const [selSubject, setSelSubject] = useState();
   const [year, setYear] = useState();
   const [capData, setCapData] = useState(null);
   const [studID, setStudID] = useState();


   const handleErrs = ($type,$msg,$uitype) =>{
    let errorChild = {types:$type, msg:$msg, uiType:$uitype}
  return (
    <div>
    <ErrorMessage errors={errorChild} />
    </div>
  
  )
}

const getScoreRating = (type,score) =>{

 let rate;
if(type === 'percent'){
      
    switch(score){
        case '7':
              rate = '80% - 100%'
          break;
        case '6':
          rate = '70% - 79%'
          break;
        case '5':
          rate = '60% - 69%'
          break;
         case '4':
          rate = '50% - 59%'
         break;
         case '3':
          rate = '40% - 49%'
          break;
          case '2':
            rate = '30% - 39%'
            break;
         case '1':
          rate = '0% - 29%'
           break;
           default:
            rate = 'N/A'
    }

   
}else{
switch(score){
  case '7':
        rate = 'A+'
    break;
  case '6':
    rate = 'A'
    break;
  case '5':
    rate = 'B'
    break;
   case '4':
    rate = 'C'
   break;
   case '3':
    rate = 'D'
    break;
    case '2':
      rate = 'E'
      break;
   case '1':
    rate = 'F'
     break;
     default:
      rate = 'N/A'
}


}

return rate;

}


const handleDownloadData = () =>{

   return(
     <div className="col-lg-12">
      { capData !== null ?

       <div className="table-responsive">
              <table className="table project-list-table table-nowrap align-middle table-borderless">
                                     <thead>
                                                <tr>
                                                    <th scope="col" style={{ width: 100 }}>#</th>
                                                    <th scope="col">Subject Name</th>
                                                    <th scope="col">Term</th>
                                                    <th scope="col">Assessment</th>
                                                    <th scope="col">Score Rating</th>
                                                    <th scope="col">Percentage</th>
                                                    <th scope="col">Symbol</th>
                                                  
                                                </tr>
                                            </thead>

                                            <tbody>

                                               { capData && capData.map((x, index)=>{
                                                 return (
                                                  <tr key={x.id}>
                                                  <td>
                                                    
                                                      { index + 1}
                                                  
                                                  </td>
                                                  <td>
                                                      <h5 className="text-truncate font-size-14 text-dark">{ x.subject_name }</h5>
                                                     
                                                  </td>
                                                  <td>Term { x.term }</td>
                                                  <td className='text-dark' style={{ textTransform:'capitalize' }}>{ x.cate }</td>
                                                 
                                                  <td style={{ fontWeight:'bolder' }}>
                                                      { x.score }
                                                  </td>
                                                  <td>
                                                     { getScoreRating('percent',x.score)}
                                                  </td>
                                                  <td style={{ fontWeight:'bolder' }}>
                                                     { getScoreRating(null,x.score)}
                                                  </td>
                                              </tr>
                                                 )
                                               })}
                                               
                                                </tbody>


              </table>

                                           

       </div> : 

       handleErrs('danger','Oops! No result found. Kindly check back later','message') }


       </div>
   )

}

const controlDownload = (e) =>{
                let curtem = e.target.value;

         
              setLoader(true)
              setDisplayDown(false)
             axios.get('/fetchdata-capture/'+currGrade+'/null/'+curtem+'/'+year+'/'+id+'/'+studID,{
                 headers:{'Content-Type':'application/json'}
             }).then((res)=>{
                    setTimeout(()=>{
                          setLoader(false);
                          setDisplayDown(true)

                  
                          if(res.data.scoreData !== 'empty'){
                            setCapData(res.data.scoreData)
                         }else
                         {
                             setCapData(null)
                         } 

                            
                    },1200)
             }).catch(err =>{
                   console.log(err)
             })
}

const getStudent = (e) =>{
        let grd = e.target.value;
         if(grd !== ''){
        setLoadBut(true)
        setCurrGrade(grd)
      axios.get('/api-list-student/'+grd+'/'+pid,{
          headers:{'Content-Type':'application/json'}
      }).then((res)=>{
              setTimeout(()=>{
                setLoadBut(false)
                if(res.data.msData !== 'empty'){
                  setStudData(res.data.msData);
               }
              },1000)
          
      })
    }
}


const getGrades = (parentID,userType) => {
        axios.get('/api-list-currentgrade/'+parentID+'/'+userType,{
           headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                if(res.data.gradeData === 'empty'){
                         setGrade(null);
                    
                }else{
                     setGrade(res.data.gradeData);
                }
        })
}

const handleStudentDis = (e) =>{
            const sid = e.target.value;
            let std = sid.split("=>");
            setStudYear(std[2]);
            setCurrTerm(std[1])
            setStudID(std[0]);
            setLoadBut(true)

            axios.get('/api-fetch-studentdata/'+std[0]+'/'+currGrade,{
                headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                    setTimeout(()=>{
                          setLoadBut(false)
                      setAccYear(res.data.aYear);
                      if(res.data.sData !== 'empty'){
                           setMData(res.data.sData);
                           
                      }
                    },1000)
                 
            })
              
}


   useEffect(()=>{
         setLoadBut(true)
        axios.get('/checklogin/status',{
           headers:{'Content-Type':'application/json'}
        }).then((res)=>{
            setTimeout(()=>{
              document.getElementById('downloadStat').reset();
              setLoadBut(false)
              setDisplayDown(false)
              setDownloadStatus(null)
               setPid(res.data.userData[0].userid)
              getGrades(res.data.userData[0].userid,res.data.userData[0].user_type)
            },1000)
            
        })
   },[id])
  return (
    <div>
    <div className="main-content">
<div className="page-content">
  <div className="container-fluid">

  { loader === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

                <div className="wrapper">
                    <span className="loader"></span>
                    <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

                    <p style={{marginLeft: -30, marginTop: 120}}>Processing your request. Please wait...</p>
                </div>

                </div>
            }

        <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Caputure { id }</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">HLP</li>
                      <li className="breadcrumb-item active">Capture {id}</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
   
   <div className='row'>
   <div className="col-lg-12">

   <div className="card">
            <div className="card-body">
            <h5 className="card-title mb-4" style={{ textTransform:'capitalize' }}>Caputure {id}</h5>
             
            <form id='downloadStat'>
          <div className='row'>

           

          <div className="col-sm-auto col-md-3">
                  <label className="" htmlFor="autoSizingSelect">Grade</label>
                   
                  <select className="form-select form-control" onChange={(e)=> getStudent(e)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Choose </option>
                      {
                         grade === null ? <option disabled>Not available</option> :
                      
                      grade && grade.map((g)=>{
                          return (
                              <option key={g.id} value={g.grade}>Grade {g.grade}</option>
                          )
                      })}
                  </select>
                </div>


                <div className="col-sm-auto col-md-3">
                  <label className="" htmlFor="autoSizingSelect">Learners</label>
                   
                  <select className="form-select form-control" onChange={(e)=> handleStudentDis(e)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Choose </option>
                  { studData === null ? <option disabled>Not available</option>
                  : studData && studData.map((s)=>{
                        return(
                           <option key={s.id} value={s.stud_userid+'=>'+s.current_term+'=>'+s.academic_year}>{ s.names }</option>
                        )
                  })}
                      
                  </select>
                </div>



                <div className="col-sm-auto col-md-3">
                  <label className="" htmlFor="autoSizingSelect">Year</label>
     
                  <select className="form-select form-control" id="autoSizingSelect" onChange={(e)=> setYear(e.target.value)}>
                  <option value=''> Choose </option>
                  { accYear !== null ?
                      accYear && accYear.map((y)=>{
                              return(
                                <option key={y.id} value={y.year}>{y.year === studYear ? y.year + ' Active' : y.year } </option>
                              )
                      })
                      :
                      <option disabled>Not available</option>
                }
                      
                  </select>


                </div>


                <div className="col-sm-auto col-md-3">
                  <label className="" htmlFor="autoSizingSelect">Term</label>
          
                  { currTerm === '1' ? 
                                                                   <select onChange={(e)=> controlDownload(e)} className="form-select form-control" id="autoSizingSelect" style={{textTransform:'capitalize'}}>      
                                                                        <option value="">Choose...</option>
                                                                        <option value={1}>Term 1</option>
                                                                        <option value={2}>Term 2</option>
                                                                        <option value={3}>Term 3</option>
                                                                        <option value={4}>Term 4</option>
                                                                        
                                                                        
                                                                   </select>
                                                                  : currTerm === '2' ?

                                                                  <select onChange={(e)=> controlDownload(e)} className="form-select form-control" id="autoSizingSelect" style={{textTransform:'capitalize'}}>       
                                                                  <option value="">Choose...</option>
                                                                  <option value={1} disabled>Term 1 Not Available</option>
                                                                  <option value={2}>Term 2</option>
                                                                  <option value={3}>Term 3</option>
                                                                  <option value={4}>Term 4</option>
                                                                  
                                                                  
                                                             </select>  : currTerm === '3' ? 
                                                              <select onChange={(e)=> controlDownload(e)} className="form-select form-control" id="autoSizingSelect" style={{textTransform:'capitalize'}}>           
                                                              <option value="">Choose...</option>
                                                              <option value={1} disabled>Term 1 Not Available</option>
                                                              <option value={2} disabled>Term 2 Not Available</option>
                                                              <option value={3}>Term 3</option>
                                                              <option value={4}>Term 4</option>
                                                              
                                                              
                                                         </select>
                                                            : currTerm === '4' ?
                                                            <select onChange={(e)=> controlDownload(e)} className="form-select form-control" id="autoSizingSelect" style={{textTransform:'capitalize'}}>              
                                                            <option value="">Choose...</option>
                                                            <option value={1} disabled>Term 1 Not Available</option>
                                                            <option value={2} disabled>Term 2 Not Available</option>
                                                            <option value={3} disabled>Term 3 Not Available</option>
                                                            <option value={4}>Term 4</option>
                                                            
                                                            
                                                       </select>
                                                         :   <select className="form-select form-control" id="autoSizingSelect" style={{textTransform:'capitalize'}}>            
                                                         <option value="">Choose...</option>
                                                         <option value={1} disabled>Term 1 Not Available</option>
                                                            <option value={2} disabled>Term 2 Not Available</option>
                                                            <option value={3} disabled>Term 3 Not Available</option>
                                                            <option value={4} disabled>Term 4 Not Available</option>
                                                         
                                                       
                                                        </select>
                                                          }



                                                                    </div>




          </div>
          </form>

          <div className='row'>
             <div className='col-md-12 text-center mt-4'>
                 { loadBut !== false ?  handleErrs('success',null,'load') : null}
             </div>
          </div>
           




              </div>
        </div>

    </div>
   </div>


{ displayDown !== false ? 
   <div className='row'>
  


        { handleDownloadData() }

  

   
   </div> : null }
   

    </div>
    <FootNav />
  </div>
   </div>
   </div>
  )
}

export default ViewAssess