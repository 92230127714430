import React, { useEffect, useState } from 'react'
import ErrorMessage from '../userAuth/ErrorMessage'
import axios from 'axios';
import FootNav from '../Nav/FootNav';

function Settings() {

      const [userData, setUserDatas] = useState(null);
      const [userType, setUserType] = useState();
      const [pedit, setPedit] = useState(false);
      const [loadMe, setLoadMe] = useState(false);
      const [showBank, setShowBank] = useState(false);
      const [bnk, setBnk] = useState(null);
      const [notify, setNotify] = useState(null);
      const [oldPass, setOldPass] = useState('');
      const [newPass, setNewPass] = useState('');
      const [conPass, setConPass] = useState('');
      const [logEmail, setLogEmail] = useState('');
      const [userName, setUserName] = useState('');
      const [userPhone, setUserPhone] = useState('');
      const [userAddress, setUserAddress] = useState('');
      const [bankName, setBankName] = useState('');
      const [accName, setAccName] = useState('');
      const [accNumber, setAccNumber] = useState('');
      const [bankCode, setBankCode] = useState('');
      const [loadStatus, setLoadStatus] = useState(false)

    
    

    const handleErrs = ($type,$msg,$uitype) =>{
        let errorChild = {types:$type, msg:$msg, uiType:$uitype}
      return (
        <div>
        <ErrorMessage errors={errorChild} />
        </div>
      
      )
    }

    const apiAxio = (data) =>{
                setLoadStatus(true)
            axios.post('/api-updateuser-data',data,{
                headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                    setTimeout(()=>{
                        setLoadStatus(false)
                        setNotify(handleErrs(res.data.color,res.data.message,'message'));
                    },1000)
            }).catch((err)=>{
                console.log(err)
            })
    }

    const updateUserDatas = (type) =>{
                        setNotify(null)
                switch(type){
                      case 'profile':
                        if(!userName.trim() || !userAddress.trim() || !userPhone.trim === ''){
                            setNotify(handleErrs('danger','Please make sure all input are not empty','message'))
                        }
                        else
                        {
                            const uData = {userName,userPhone,userAddress,type};
                            apiAxio(uData)
                        }
                        break;
                      case 'email':

                      if(logEmail === '')
                      {
                        setNotify(handleErrs('danger','Please provide a valid email address','message'))
                      }
                      else
                      {
                       let emailData = {logEmail,type};
                       apiAxio(emailData)
                      }
                 
                          
                        break;
                    case 'password':
                           if(oldPass === '' || newPass === '' || conPass === '')
                           {
                             setNotify(handleErrs('danger','Please make sure all password input are not empty','message'))
                           }
                           else
                           {
                             if(newPass !== conPass){
                                setNotify(handleErrs('danger','Your new and confirm password did not match','message'))
                             }
                             else
                             {
                            const passData = {oldPass,newPass,type};
                            apiAxio(passData)
                             }
                           }
                      
                         break;
                    case 'bank':
                        if(bankName === '' || accName === '' || accNumber === '')
                        {
                          setNotify(handleErrs('danger','Please make sure all password input are not empty','message'))
                        }
                        else
                        {
                         const bankDat = {bankName,bankCode,accName,accNumber,type};
                         apiAxio(bankDat);
                        }
                        break;
                }
    }


    const profileEdit = () => {
                return(
                   
                        <>  
                        { pedit === false ?
                        <div>
                    <div className="card">
                    <div className="card-body">
                           

                        <div className="media">
                            <div className="me-3">
                            <div className="me-4">
                                                <i className="mdi mdi-account-circle text-primary h1"></i>
                                            </div>
                            </div>
                            <div className="media-body">
                                <div className="media">
                                    <div className="media-body">
                                        <div className="text-muted">
                                            <h6 className="mb-1" style={{ textTransform:'uppercase' }}>{userData[0].names}</h6>
                                            <p className="mb-0">{userType}</p>
                                        </div>

                                    </div>

                                    <div className="dropdown ms-2">
                                        <button className="btn btn-light btn-sm" type="button" onClick={()=> setPedit(true)}>
                                            <i className="bx bxs-edit-alt align-middle me-1"></i> Edit Profile
                                        </button>
                                      
                                    </div>
                                </div>


                                <hr />

                                <div className="row">
                                   
                                  
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Other Information</h4>
          
                                        <div className="table-responsive">
                                            <table className="table table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Phone :</th>
                                                        <td>{ userData[0].phone }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Address :</th>
                                                        <td>{ userData[0].address }</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                </div>
                                :

                                <div className='card'>
                                    <div className='card-body'>
                                        <h6 style={{ textTransform:'uppercase' }}>Update your information</h6>
                                        <hr className='text-danger' style={{ marginTop:-6 }} />
                                      
                                        { notify === null ? null : notify }
                                            <div className='mb-3'>
                                                 <label>Names</label>
                                                <input type='text' onChange={(e)=>setUserName(e.target.value)} className='form-control' defaultValue={userData[0].names} />
                                            </div>

                                            <div className='mb-3'>
                                                 <label>Phone</label>
                                                <input type='text' onChange={(e)=>setUserPhone(e.target.value)} className='form-control' defaultValue={userData[0].phone} />
                                            </div>

                                            <div className='mb-3'>
                                                 <label>Address</label>
                                                <textarea type='text' onChange={(e)=>setUserAddress(e.target.value)} className='form-control' style={{ resize:'none' }}>{userData[0].address}</textarea>
                                            </div>

                                            <div className="row mt-4">
                                            <div className="col-sm-6">
                                                <button onClick={()=> setPedit(false)} className="btn btn-secondary">
                                                    <i className="mdi mdi-arrow-left me-1"></i> BACK </button>
                                            </div> 
                                            <div className="col-sm-6">
                                                <div className="text-sm-end mt-2 mt-sm-0">
                                                    <button onClick={()=> updateUserDatas('profile')} className="btn btn-success">
                                                         UPDATE </button>
                                                </div>
                                            </div> 
                                        </div> 
                                        
                      

                                    </div>
                                </div>
                        }
                </>
             

                )
    }

    const displayBanks = () =>{
           return(
              <>
               { showBank === false ?

<div className='card'>
<div className='card-body'>
    <h6 style={{ textTransform:'uppercase' }}>Update Your Bank Information</h6>
    <hr className='text-danger' style={{ marginTop:-6 }} />
    
    { notify === null ? null : notify }
        <div className='mb-3'>
             <label>Bank Name</label>
            <input type='text' onChange={(e)=>setBankName(e.target.value)} className='form-control' defaultValue={ bnk === null ? '' : bnk[0].bank_name} />
        </div>

        <div className='mb-3'>
             <label>Account Name</label>
            <input type='text' onChange={(e)=>setAccName(e.target.value)} className='form-control' defaultValue={ bnk === null ? '' : bnk[0].account_name} />
        </div>

        <div className='mb-3'>
             <label>Account Number</label>
            <input type='text' onChange={(e)=>setAccNumber(e.target.value)} className='form-control' defaultValue={ bnk === null ? '' : bnk[0].account_number} />
        </div>

        <div className='mb-3'>
             <label>Bank Branch Code</label>
            <input type='text' onChange={(e)=>setBankCode(e.target.value)} className='form-control' defaultValue={ bnk === null ? '' : bnk[0].bank_code} />
        </div>


                                     <div className="row mt-4">
                                            <div className="col-sm-6">
                                            { bnk === null ? null :
                                                <button type='button' className="btn btn-secondary">
                                                    <i className="mdi mdi-arrow-left me-1"></i> BACK</button>
                                                  }
                                            </div> 
                                            <div className="col-sm-6">
                                                <div className="text-sm-end mt-2 mt-sm-0">
                                                    <button onClick={()=> updateUserDatas('bank')} className="btn btn-success">
                                                         UPDATE </button>
                                                </div>
                                            </div> 
                                        </div> 
    
          
          
          
   

</div>
</div>
               
                   
               :
               <div>
               <div className="card">
                       <div className="card-body">
                           <h4 className="card-title mb-4">Your Bank Information</h4>

                           <div className="table-responsive">
                               <table className="table table-nowrap mb-0">
                                   <tbody>
                                       <tr>
                                           <th scope="row">Bank Name :</th>
                                           <td>{ userData[0].phone }</td>
                                       </tr>
                                       <tr>
                                           <th scope="row">Account Name :</th>
                                           <td>{ userData[0].address }</td>
                                       </tr>

                                       <tr>
                                           <th scope="row">Account Number :</th>
                                           <td>{ userData[0].address }</td>
                                       </tr>

                                       <tr>
                                           <th scope="row">Bank Branch Code :</th>
                                           <td>{ userData[0].address }</td>
                                       </tr>
                                       
                                   </tbody>
                               </table>
                           </div>
                       </div>
                   </div>

  </div>          
               }
              </>
           )
    }

    const displayLoginEmail = () =>{
          return (
            <div className='card'>
<div className='card-body'>
    <h6 style={{ textTransform:'uppercase' }}>Update Login Email</h6>
    <hr className='text-danger' style={{ marginTop:-6 }} />
   
    { notify === null ? null : notify }
        <div className='mb-3'>
             <label>Email</label>
            <input type='email' onChange={(e)=> setLogEmail(e.target.value)} className='form-control' value={ userData[0].email} required />
        </div>

      
            <div className='row'>
          
            <div className='col-md-12 text-center'>
            <button onClick={()=> updateUserDatas('email')} className='btn btn-sm btn-success btn-sm w-md'>UPDATE</button>
     
            </div>
            </div>
          

</div>
</div>
        
          )
    }

    const updateLoginPass = () => {
        return (
            <div className='card'>
<div className='card-body'>
    <h6 style={{ textTransform:'uppercase' }}>Update Login Password</h6>
    <hr className='text-danger' style={{ marginTop:-6 }} />
   

    { notify === null ? null : notify }

        <div className='mb-3'>
             <label>Current Password</label>
            <input type='password' onChange={(e)=> setOldPass(e.target.value)} className='form-control' defaultValue={''} />
        </div>

        <div className='mb-3'>
             <label>New Password</label>
            <input type='password' onChange={(e)=> setNewPass(e.target.value)} className='form-control' defaultValue={''} />
        </div>

        <div className='mb-3'>
             <label>Confirm Password</label>
            <input type='password' onChange={(e)=> setConPass(e.target.value)} className='form-control' defaultValue={''} />
        </div>

    
            <div className='row'>
          
            <div className='col-md-12 text-center'>
            <button onClick={()=> updateUserDatas('password')}  className='btn btn-sm btn-success btn-sm w-md'>UPDATE</button>
     
            </div>
            </div>
          

</div>
</div>
        
          )
    }

  
  useEffect(()=>{
    const fetchParentData = () =>{
        try{
            setUserDatas(null)
            setLoadMe(true)
           axios.get('/checklogin/status',{
              headers:{'Content-Type':'application/json'}
           }).then((res)=>{
                 setTimeout(()=>{
                    setLoadMe(false)
                   setUserDatas(res.data.userData);
                   setUserType(res.data.userType);
                            let u = res.data.userData[0];
                        setLogEmail(u.email);
                         setUserName(u.names);
                         setUserAddress(u.address);
                         setUserPhone(u.phone);


                   if(res.data.bank !=='empty'){
                            setBnk(res.data.bank);
                             let b = res.data.bank[0];
                             setAccName(b.account_name);
                             setAccNumber(b.account_number);
                             setBankCode(b.bank_code);
                             setBankName(b.bank_name)

                            setShowBank(true)
                   }else{
                            setShowBank(false);
                            setBnk(null)
                   }
                     
                 },1000)
              
    
           }).catch((err)=>{
              console.log(err)
           })
        }catch(err){
             console.log(err)
        }
      
 }
    fetchParentData();
},[])

  return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">

      { loadStatus === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

                <div className="wrapper">
                    <span className="loader"></span>
                    <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

                    <p style={{marginLeft: -30, marginTop: 120}}>Processing your request. Please wait...</p>
                </div>

                </div>
            }


            { loadMe === false ? null :
             <div id="preloader">
             <div id="status">
                 <div className="spinner-chase">
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                 </div>
             </div>
         </div> }

         {  userData === null ? null :
        <div className='row'>
            <div className='col-lg-12'>
                <div className='card'>
                    <div className='card-body'>
                        <h5>Personal Information</h5>
                        <hr className='text-danger' style={{ marginTop:-5 }} />

                              <div className='row'>
                                           
                                             <div className="col-md-3">
                                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                <a onClick={()=> setNotify(null)} className="nav-link mb-2 active" id="v-pills-home-tab" data-bs-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Account Settings</a>
                                                <a onClick={()=> setNotify(null)} className="nav-link mb-2" id="v-pills-profile-tab" data-bs-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Bank Account Settings</a>
                                                <a onClick={()=> setNotify(null)} className="nav-link mb-2" id="v-pills-messages-tab" data-bs-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Login Settings</a>
                                                <a onClick={()=> setNotify(null)} className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Password Settings</a>
                                                </div>
                                            </div>

                                            <div className="col-md-9">
                                            <div className="tab-content text-muted mt-4 mt-md-0" id="v-pills-tabContent">

                                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                            <div className='row'>
                                                            <div className='col-lg-1'>

                                                                </div>

                                                               <div className='col-lg-8'>
                                                                        { profileEdit() }
                                                                </div>
                                                                    

                                                            </div>


                                                    </div>


                                                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                              

                                                                <div className='row'>
                                                            <div className='col-lg-1'>

                                                                </div>

                                                               <div className='col-lg-8'>
                                                               { displayBanks()}
                                                                </div>
                                                                    

                                                            </div>
                                                      </div>

                                                    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                                                <div className='row'>
                                                                    <div className='col-lg-2'>
                                                                        </div>

                                                                        <div className='col-lg-8'>
                                                                            { displayLoginEmail() }
                                                                        </div>
                                                                    </div>
                                                    </div>

                                                    <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                                     
                                                    <div className='row'>
                                                                    <div className='col-lg-2'>
                                                                        </div>

                                                                        <div className='col-lg-8'>
                                                                            { updateLoginPass() }
                                                                        </div>
                                                                    </div>
                                                   
                                                    </div>
                                               
                                                </div>

                                                </div>

                            
                        </div>


                    </div>
                </div>

            </div>

            

        </div>
}
<FootNav />
        </div>
        </div>
        </div>
  )


}

export default Settings