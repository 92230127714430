import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import $ from 'jquery'

function Forgot() {
   const [email, setEmail] = useState('');
   const [sectionCate, setSectionCate] = useState('email');
   const [newPass, setNewPass] = useState('');
   const [comPass, setComPass] = useState('');
   const [code, setCode] = useState('');
   const [notify, setNotify] = useState(null);
   const [load, setLoad] = useState(false);

   const viewReset = () => {
          switch(sectionCate){
              
                case 'code':
                  return(

                    <>
      
                    <div class="mb-3">
                                      <div className='text-center'>
                                      <strong className='mb-2'>We want to make sure it's really you. In order to further verify
              your identity, enter the verification code that was sent to your {email}</strong>
                                      </div>
                                     
                                     
                                      <label for="useremail" class="form-label mt-4">Enter the verification</label>
                                      <input type="text" class="form-control" onChange={(e)=> setCode(e.target.value)} id="useremail" placeholder="Enter code" defaultValue={code} />
                                  </div>
              
                                  <div class="text-center">
                                      { load === false ? 
                                      <button onClick={()=> handleReset('code')} class="btn btn-danger w-md waves-effect waves-light" type="button">Submit Code</button>
                                          :
                                          <button class="btn btn-danger w-md waves-effect waves-light" disabled>Please wait...</button>
                                      }
              
                                        <p className='mt-4'>
                                        <strong className='mt-4 text-primary' onClick={()=> handleReset('email')} style={{ cursor:'pointer' }}>Resend Code</strong>
                                        </p>
                                        
                                      </div>
                
                              </>
              
                   
                  )
                  break;
                case 'reset':
                  return(
                    <>
    
                    <div class="mb-3">
                        <label for="useremail" class="form-label">New Password</label>
                        <input type="password" onChange={(e)=>setNewPass(e.target.value)} class="form-control" id="useremail" placeholder="" defaultValue={newPass} />
                    </div>

                    <div class="mb-3">
                        <label for="useremail" class="form-label">Confirm Password</label>
                        <input type="password" onChange={(e)=>setComPass(e.target.value)} class="form-control" id="useremail" placeholder="" defaultValue={comPass} />
                    </div>

                    <div class="text-center">
                        { load === false ? 
                        <button onClick={()=> handleReset('reset')} class="btn btn-danger w-md waves-effect waves-light" type="button">Update Password</button>
                            :
                            <button class="btn btn-danger w-md waves-effect waves-light" disabled>Please wait...</button>
                        }

                        </div>

                </>
                  )
                 break;
                 default:
                  return(
                    <>

                    <div class="mb-3">
                        <label for="useremail" class="form-label">Provide your email address</label>
                        <input type="email" class="form-control" onChange={(e)=>setEmail(e.target.value)} id="useremail" placeholder="Enter email" required defaultValue={email} />
                    </div>
  
                    <div class="text-center">
                        { load === false ? 
                        <button onClick={()=> handleReset('email')} class="btn btn-danger w-md waves-effect waves-light" type="button">Submit</button>
                            :
                            <button class="btn btn-danger w-md waves-effect waves-light" disabled>Please wait...</button>
                        }

                        </div>

                </>

  
                   )
          }
   }

  const handleReset = (type) => {
    setNotify(null)
            switch(type){
                case 'code':
                  if(!code.trim()){
                    setNotify(<div className='alert alert-danger text-center'>Please provide the code that was sent to your email</div>)
                }else
                {
                  const data = {reset:code,type,ema:email}
                  processApi(data)
                }

                  break;
                case 'reset':
                  if(!newPass.trim() || !comPass.trim()){
                    setNotify(<div className='alert alert-danger text-center'>Please provide your email address</div>)
              
                  }else
                  {
                     if(newPass !== comPass){
                      setNotify(<div className='alert alert-danger text-center'>New and comfirm password does not match</div>)
              
                     }else{
                    const data = {reset:newPass,type,ema:email}
                    processApi(data)
                     }
                  }
                break
                case 'email':
                    if(!email.trim()){
                        setNotify(<div className='alert alert-danger text-center'>Please provide your email address</div>)
                    }else
                    {
                      const data = {reset:email,type,ema:email}
                      processApi(data)
                    }
                 
                  break;

            }

  }


  const processApi = (data) =>{
          setLoad(true)
          
        axios.post('/api-process-secure-data',data,{
           headers:{'Content-Type':'application/json'}
        }).then((res)=>{
            setTimeout(()=>{
             
               setComPass('');
               setCode('');
               setNewPass('');
               setSectionCate(res.data.sec);
               setNotify(<div className={`alert alert-${res.data.color}`}>{res.data.message}</div>)
               setLoad(false)
            },1000)
        }).catch((err)=>{
            console.log(err)
        })
  }

  return (
    <div class="account-pages my-5 pt-sm-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">

                 <div className='row mb-3'>
                        <div className='col-12 text-center'>
                          <img src='/logo.png' style={{ height:'80px' }} />
                        </div>
                       </div>
                <div class="card overflow-hidden">

                    <div class=" bg-soft">
                        <div class="row">
                            <div class="col-12">
                                <div class="text-primary p-4 text-center">
                                    <h5 class="text-dark"> Reset Password</h5>
                                 
                                </div>
                            </div>
                         
                        </div>
                    </div>
                    <div class="card-body pt-0"> 
                       
                        <div class="p-2">
                        <form class="form-horizontal" id='restForm'>
                              { notify }
                           { viewReset() }
                           </form>
                        </div>
    
                    </div>
                </div>
                <div class="mt-5 text-center">
                    <p>Remember It ? <Link to="/login" class="fw-medium text-primary"> Sign In here</Link> </p>
                    <p>© { new Date().getFullYear()} Horion Education (Pty) Ltd </p>
                </div>

            </div>
        </div>
    </div>
</div>
  )
}

export default Forgot