import axios from 'axios';
import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ErrorMessage from '../userAuth/ErrorMessage';
import FootNav from '../Nav/FootNav';
import download from 'downloadjs';


function AssessDownload() {

   const [loader, setLoader] = useState(false);
   const [loadBut, setLoadBut] = useState(false);
   const [grade, setGrade] = useState(null);
   const [pid, setPid] = useState();
   const [studData, setStudData] = useState(null);
   const [studYear, setStudYear] = useState();
   const [currTerm, setCurrTerm] = useState();
   const [currGrade, setCurrGrade] = useState();
   const [mData, setMData] = useState(null);
   const [accYear, setAccYear] = useState(null);
   const [displayDown, setDisplayDown] = useState(false);
   const [downloadStatus, setDownloadStatus] = useState(null);
   const [selSubject, setSelSubject] = useState();
   const [year, setYear] = useState();
   const [term , setTerm] = useState();
   const [profileStatus, setProfileStatus] = useState();
   const [loading, setLoading] = useState(false);
   const [cate, setCate] = useState('');
   const [assCate, setAssCate] = useState([]);

    const id = cate;

   const handleErrs = ($type,$msg,$uitype) =>{
    let errorChild = {types:$type, msg:$msg, uiType:$uitype}
  return (
    <div>
    <ErrorMessage errors={errorChild} />
    </div>
  
  )
}



const ACat = (datas,tm) =>{
  let ms;
   switch(tm){
      case "1":
        ms = datas.term1
      break;
      case "2":
        ms = datas.term2
      break;
      case "3":
        ms = datas.term3
        break;
      case "4":
        ms = datas.term4
        break;
   }

            let sd = ms.split(',');
           setAssCate(sd);

}


const getAssessmentCate = (tm) => {
    setTerm(tm)
    setLoadBut(true)
  if(!tm.trim()){}else{
  axios.post('/get-assess-cate',{tm},{
     headers:{'Content-Type' : 'application/json'}
  }).then((res)=>{

        setTimeout(()=>{
          setLoadBut(false)
          if(res.data.termData === 'empty'){
            setAssCate([]);
          }else
          {
               ACat(res.data.termData[0],tm)
    
          }
        },1000)

      
  }).catch((err)=>{
     console.log(err)
  })
}
}


const downloadFile = (id,title,type,dtype) => {
  setLoading(true)
axios.post('/downloadassessment-file',{id,dtype},{
   responseType:'blob'
}).then((res)=>{
   setTimeout(()=>{
    setLoading(false)

    const content = res.headers['content-type'];
    download(res.data, title+type, content)
   },500)
   

   
}).catch((err)=>{
    console.log(err)
})
}


const handleDownloadData = () =>{

   return(
     <div className="col-lg-12">
      { downloadStatus !== null ?

       <div className="table-responsive">
              { loading === false ? null : <p className='text-success'>Downloading requested file. Please wait...</p>}
              <table className="table project-list-table table-nowrap align-middle table-borderless">
                                     <thead>
                                                <tr>
                                                    <th scope="col" style={{ width: 100 }}>#</th>
                                                    <th scope="col">Subject Name</th>
                                                    <th scope="col">Assessment</th>
                                                    <th scope="col">Due Date</th>
                                                    <th scope="col">Grade</th>
                                                    <th scope='col'>Memo</th>
                                                    <th scope="col">File Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                      
                                                        1
                                                    
                                                    </td>
                                                    <td>
                                                        <h5 className="text-truncate font-size-14 text-dark">{ downloadStatus.subject_name }</h5>
                                                       
                                                    </td>
                                                    <td className='text-dark' style={{ textTransform:'capitalize' }}>{ id }</td>
                                                    <td>{ downloadStatus.due_date }</td>
                                                    <td>{ downloadStatus.grade }</td>
                                                    <td>{ downloadStatus.memo === null ? <span className="badge bg-info">Not Available</span> :  <button onClick={()=> downloadFile(downloadStatus.id,downloadStatus.subject_name,'pdf','memo')} className="btn btn-info btn-sm btn-rounded">
                                                              <i className='mdi mdi-download'></i> Download
                                                            </button> } </td>
                                                    <td><span className="badge bg-success">
                                                    { downloadStatus.status === 'Yes' ? 'Available' : 'Not Available' }
                                                      </span></td>
                                                    <td>
                                                        <div className="dropdown">
                                                            
                                                            <button className="btn btn-info btn-sm btn-rounded" onClick={()=> downloadFile(downloadStatus.id,downloadStatus.subject_name,'pdf','downloadfile')}>
                                                              <i className='mdi mdi-download'></i> Download
                                                            </button>
                                                            
                                                         
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>


              </table>
       </div> : 

       handleErrs('danger','No download available for '+selSubject,'message') }


       </div>
   )

}

const controlDownload = (e) =>{
          let subj = e.target.value;
           let ms = subj.split('=>');
           setSelSubject(ms[1])
              setLoader(true)
              setDisplayDown(false)
             axios.get('/api-fetchassessment-data/'+currGrade+'/'+ms[0]+'/'+term+'/'+year+'/'+id,{
                 headers:{'Content-Type':'application/json'}
             }).then((res)=>{
                    setTimeout(()=>{
                          setLoader(false);
                          setDisplayDown(true)
                          if(res.data.dData !== 'empty'){
                             setDownloadStatus(res.data.dData[0])
                          }else{
                             setDownloadStatus(null)
                          }
                            
                    },1200)
             })
}

const getStudent = (e) =>{
        let grd = e.target.value;
         if(grd !== ''){
        setLoadBut(true)
        setCurrGrade(grd)
      axios.get('/api-list-student/'+grd+'/'+pid,{
          headers:{'Content-Type':'application/json'}
      }).then((res)=>{
              setTimeout(()=>{
                setLoadBut(false)
                if(res.data.msData !== 'empty'){
                  setStudData(res.data.msData);
               }
              },1000)
          
      })
    }
}


const getGrades = (parentID,userType) => {
        axios.get('/api-list-currentgrade/'+parentID+'/'+userType,{
           headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                if(res.data.gradeData === 'empty'){
                         setGrade(null);
                    
                }else{
                     setGrade(res.data.gradeData);
                }
        })
}

const handleStudentDis = (e) =>{
            const sid = e.target.value;
            let std = sid.split("=>");
            setStudYear(std[2]);
            setCurrTerm(std[1])
            setLoadBut(true)

             setProfileStatus(std[3])

            axios.get('/api-fetch-studentdata/'+std[0]+'/'+currGrade,{
                headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                    setTimeout(()=>{
                          setLoadBut(false)
                      setAccYear(res.data.aYear);
                      if(res.data.sData !== 'empty'){
                           setMData(res.data.sData);
                           
                      }
                    },1000)
                 
            })
              
}


   useEffect(()=>{
         setLoadBut(true)
        axios.get('/checklogin/status',{
           headers:{'Content-Type':'application/json'}
        }).then((res)=>{
            setTimeout(()=>{
              document.getElementById('downloadStat').reset();
              setLoadBut(false)
              setDisplayDown(false)
              setDownloadStatus(null)
               setPid(res.data.userData[0].userid)
              getGrades(res.data.userData[0].userid,res.data.userData[0].user_type)
            },1000)
            
        })
   },[])
  return (
    <div>
    <div className="main-content">
<div className="page-content">
  <div className="container-fluid">

  { loader === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

                <div className="wrapper">
                    <span className="loader"></span>
                    <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

                    <p style={{marginLeft: -30, marginTop: 120}}>Processing your request. Please wait...</p>
                </div>

                </div>
            }

        <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Download Assessment</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">HLP</li>
                      <li className="breadcrumb-item active">Download Assessment</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
   
   <div className='row'>
   <div className="col-lg-12">

   <div className="card">
            <div className="card-body">
            <h5 className="card-title" style={{ textTransform:'capitalize' }}>{id} Download</h5>
             <hr className='text-danger mb-4' style={{ marginTop:-5 }}  />
            <form id='downloadStat'>
          <div className='row'>

           

          <div className="col-sm-auto col-md-2">
                  <label className="" htmlFor="autoSizingSelect">Grade</label>
                   
                  <select className="form-select form-control" onChange={(e)=> getStudent(e)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Choose </option>
                      {
                         grade === null ? <option disabled>Not available</option> :
                      
                      grade && grade.map((g)=>{
                          return (
                              <option key={g.id} value={g.grade}>Grade {g.grade}</option>
                          )
                      })}
                  </select>
                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="" htmlFor="autoSizingSelect">Learners</label>
                   
                  <select className="form-select form-control" onChange={(e)=> handleStudentDis(e)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Choose </option>
                  { studData === null ? <option disabled>Not available</option>
                  : studData && studData.map((s)=>{
                        return(
                           <option value={s.stud_userid+'=>'+s.current_term+'=>'+s.academic_year+'=>'+s.profile_status}>{ s.names }</option>
                        )
                  })}
                      
                  </select>
                </div>



                <div className="col-sm-auto col-md-2">
                  <label className="" htmlFor="autoSizingSelect">Year</label>
     
                  <select className="form-select form-control" id="autoSizingSelect" onChange={(e)=> setYear(e.target.value)}>
                  <option value=''> Choose </option>
                  { accYear !== null ?
                      accYear && accYear.map((y)=>{
                              return(
                                <option key={y.id} value={y.year}>{y.year === studYear ? y.year + ' Active' : y.year } </option>
                              )
                      })
                      :
                      <option disabled>Not available</option>
                }
                      
                  </select>


                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="" htmlFor="autoSizingSelect">Term</label>
          
                  { currTerm === '1' ? 
                                                                   <select onChange={(e)=> getAssessmentCate(e.target.value)} className="form-select form-control" id="autoSizingSelect" style={{textTransform:'capitalize'}}>      
                                                                        <option value="">Choose...</option>
                                                                        <option value={1}>Term 1</option>
                                                                        <option value={2}>Term 2</option>
                                                                        <option value={3}>Term 3</option>
                                                                        <option value={4}>Term 4</option>
                                                                        
                                                                        
                                                                   </select>
                                                                  : currTerm === '2' ?

                                                                  <select onChange={(e)=> getAssessmentCate(e.target.value)} className="form-select form-control" id="autoSizingSelect" style={{textTransform:'capitalize'}}>       
                                                                  <option value="">Choose...</option>
                                                                  <option value={1} disabled>Term 1 Not Available</option>
                                                                  <option value={2}>Term 2</option>
                                                                  <option value={3}>Term 3</option>
                                                                  <option value={4}>Term 4</option>
                                                                  
                                                                  
                                                             </select>  : currTerm === '3' ? 
                                                              <select onChange={(e)=> getAssessmentCate(e.target.value)} className="form-select form-control" id="autoSizingSelect" style={{textTransform:'capitalize'}}>           
                                                              <option value="">Choose...</option>
                                                              <option value={1} disabled>Term 1 Not Available</option>
                                                              <option value={2} disabled>Term 2 Not Available</option>
                                                              <option value={3}>Term 3</option>
                                                              <option value={4}>Term 4</option>
                                                              
                                                              
                                                         </select>
                                                            : currTerm === '4' ?
                                                            <select onChange={(e)=> getAssessmentCate(e.target.value)} className="form-select form-control" id="autoSizingSelect" style={{textTransform:'capitalize'}}>              
                                                            <option value="">Choose...</option>
                                                            <option value={1} disabled>Term 1 Not Available</option>
                                                            <option value={2} disabled>Term 2 Not Available</option>
                                                            <option value={3} disabled>Term 3 Not Available</option>
                                                            <option value={4}>Term 4</option>
                                                            
                                                            
                                                       </select>
                                                         :   <select className="form-select form-control" id="autoSizingSelect" style={{textTransform:'capitalize'}}>            
                                                         <option value="">Choose...</option>
                                                         <option value={1} disabled>Term 1 Not Available</option>
                                                            <option value={2} disabled>Term 2 Not Available</option>
                                                            <option value={3} disabled>Term 3 Not Available</option>
                                                            <option value={4} disabled>Term 4 Not Available</option>
                                                         
                                                       
                                                        </select>

                                                          }



                                                                    </div>




                   <div className="col-sm-auto col-md-2">
                  <label className="" htmlFor="autoSizingSelect">Assessment Category</label>
                   
                  <select className="form-select form-control"  onChange={(e)=> setCate(e.target.value)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Choose </option>
                  { assCate.length > 0 ? assCate && assCate.map((c,i)=>{
                                                            return(
                                                              <option key={i} value={c}>{c}</option>
                                                            )
                                                     }) : <option disabled>Not available</option> }
                                                  
                      
                  </select>
                </div>



                      <div className="col-sm-auto col-md-2">
                  <label className="" htmlFor="autoSizingSelect">Subject</label>
                   
                  <select className="form-select form-control"  onChange={(e)=> controlDownload(e)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Choose </option>
                   { mData !== null ? 
                      mData && mData.map((s)=>{
                         return(
                          <option key={s.id} value={s.subject_id+'=>'+s.subject_name}>{ s.subject_name }</option>
                         )
                      })
                      : <option disabled>No Available</option>
                  }
                 
                      
                  </select>
                </div>

              



          </div>
          </form>

          <div className='row'>
             <div className='col-md-12 text-center mt-4'>
                 { loadBut !== false ?  handleErrs('success',null,'load') : null}
             </div>
          </div>
           




              </div>
        </div>

    </div>
   </div>

   { displayDown !== false ? 
   <div className='row'>
  


        { handleDownloadData() }

  

   
   </div> : null }
   

    </div>
    <FootNav />
  </div>
   </div>
   </div>
  )
}

export default AssessDownload