import React,{useState} from 'react'
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import '../../App.css';

function TutorReg({mychilds, changeOption}) {
     const curDate = new Date().getFullYear();
     const [loadDatas, setLoadDatas] = useState(false)
     const [notis, setNotis] = useState(null)
     const history = useHistory();
    const schema = Yup.object().shape({
        tutorName: Yup.string()
        .required('Tutor name is required'),
        tutorId: Yup.string()
        .required('Passport ID/ ID No. is required'),
        tutorPhone: Yup.string()
        .required('Phone number is required'),
        tutorAddress: Yup.string()
        .required('Address is required'),
       
    });

    const formOption = { resolver: yupResolver(schema)};
const {register, handleSubmit, formState } = useForm(formOption);
    const {errors} = formState;

    const handleSubmitParent = (data) =>{
       
setLoadDatas(true)
    axios.post('/create-account-user',data,{
        headers: { 'Content-Type': 'application/json'},
    }).then((response) =>{
                console.log(response.data)
            setTimeout(()=>{
                setLoadDatas(false)
                if(response.data.status === 'good'){
                            history.push('/');
                }else{
                 let noti = mychilds.notify('danger',response.data.status);
                  setNotis(noti)
                  
                }
            },1000)
        

    })

}


  return (
     <div>
          
        <div id="layout-wrapper">
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
            { loadDatas === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

                <div className="wrapper">
                    <span className="loader"></span>
                    <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

                    <p style={{marginLeft: -30, marginTop: 120}}>Setting up your account. Please wait...</p>
                </div>

                </div>
            }
              <div className="col-lg-12">
                <div className="text-center mb-5 text-muted">
                  <div className="d-block auth-logo">
                  <img src="/logo.png" alt="" height={80} className="auth-logo-dark mx-auto" />
                 <img src="/logo.png" alt="" height={80} className="auth-logo-light mx-auto" />
                  </div>
                  <p className="mt-3">Complete your home tutor registration</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title" id="title">{mychilds.myOption} Information </h5>
                    <p className="card-title-desc">Provide all details to complete your registration</p>
                
                    <form key={2} onSubmit={handleSubmit(handleSubmitParent)}>

                    { notis === null ? null : notis}

                    <input type="hidden" name="userType" defaultValue="Tutor" {...register('userType')} />
                   
  
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="text"  name="tutorName" id="tutorName" placeholder="Enter Your Full Names" {...register('tutorName')} className={`form-control ${errors.tutorName ? 'is-invalid' : ''}`} />
                          <label htmlFor="floatingemailInput">Full Name</label>
                          <div className="invalid-feedback">{errors.tutorName?.message}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="text"  name="tutorId" id="tutorId" placeholder="Enter id/passport number" {...register('tutorId')}  className={`form-control ${errors.tutorId ? 'is-invalid' : ''}`} />
                          <label htmlFor="floatingemailInput">Whatsapp Number</label>
                          <div className="invalid-feedback">{errors.tutorId?.message}</div>
                        </div>
                      </div>
                    </div>
    
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="email"  name="tutorEmail" id="tutoremail" placeholder="Enter Email address" className='form-control' defaultValue={mychilds.logEmail} disabled />
                            <label htmlFor="floatingemailInput">Email address</label>
                          
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text"  name="tutorPhone" id="tutorPhone" placeholder="Enter Phone Number" {...register('tutorPhone')}  className={`form-control ${errors.tutorPhone ? 'is-invalid' : ''}`} />
                            <label htmlFor="floatingemailInput">Phone Number</label>
                            <div className="invalid-feedback">{errors.tutorPhone?.message}</div>
                          </div>
                        </div>
                      </div>
                     
                     
                      <div className="form-floating mb-3">
                        <input type="text"  name="tutorAddress" id="tutorAddress" placeholder="Enter Home Address" {...register('tutorAddress')} className={`form-control ${errors.tutorAddress ? 'is-invalid' : ''}`}/>
                        <label htmlFor="floatingnameInput">Address</label>
                        <div className="invalid-feedback">{errors.tutorAddress?.message}</div>
                      </div>
  
          
                     
                      <div className='row'>
                        <div className='col-lg-6'>
                        <div className="btn btn-dark w-md submit" onClick={() => changeOption(null)}>Back</div>
                     
                        </div>
                        <div className='col-lg-6'>
                        <button type="submit" className="btn btn-danger w-md submit" style={{ float:'right' }}>Submit</button>
                     
                        </div>
                        
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
          </div>
          <footer className="footer mt-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  { curDate } © {mychilds.companyName}
                </div>
                <div className="col-sm-6">
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
       
       
        </div>
  )
}

export default TutorReg