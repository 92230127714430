import React, { useState, useEffect } from 'react'
import {  BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import axios from 'axios';
import Register from './component/userAuth/register';
import ErrorPage from './component/userAuth/ErrorPage';
import Forgot from './component/userAuth/forgot';
import HeaderNav from './component/Nav/HeaderNav';
import ProtectRoute from './component/route/ProtectRoute';
import ParentDashBoard from './component/Parent/ParentDashBoard';
import FootNav from './component/Nav/FootNav';
import Login from './component/userAuth/login';
import Learners from './component/Parent/Learners';
import Wallet from './component/General/Wallet';
import Support from './component/General/Support';
import Invoice from './component/General/Invoice';
import LearnerProfile from './component/Parent/LearnerProfile';
import InvoiceView from './component/General/InvoiceView';
import AssessDownload from './component/hlp/AssessDownload';
import AssessCapture from './component/hlp/AssessCapture';
import ViewAssess from './component/hlp/ViewAssess';
import TermFinal from './component/hlp/TermFinal';
import TutorDashBoard from './component/Tutor/TutorDashBoard';
import Centres from './component/Tutor/Centres';
import ViewCentre from './component/Tutor/ViewCentre';
import AccessDownload from './component/Tutor/academic/AccessDownload';
import AccessCapture from './component/Tutor/academic/AccessCapture';
import AccessView from './component/Tutor/academic/AccessView';
import TermsFinal from './component/Tutor/academic/TermsFinal';
import Settings from './component/General/Settings';
import AddStudentToCentre from './component/General/AddStudentToCentre';
import NotifyUser from './component/General/NotifyUser';

const live_server = 'https://horion.co.za';
const local_server = 'http://localhost:3001';

axios.defaults.baseURL = live_server;
axios.defaults.withCredentials = true;

function App() {
   const siteName = 'horion';
   const loginStatusLink = "/checklogin/status";
   const [auth, setAuth] = useState(false);
   const [userType, setUserType] = useState('empty');


   const notifyUsers = (type,msg) =>{
           return (<div>
                <div className={`alert mt-4 alert-${type} text-center`}>{msg}</div>
           </div>)
   }



   const authUserLog = (status) =>{
       setAuth(status);
   }



   const parentSection = () =>{

    return(
        <div>
          <Router>
            <HeaderNav userTypes={'Parent'} />
            <Switch>
                <ProtectRoute exact path="/" Component={ParentDashBoard} isAuth={auth}/>
                 <ProtectRoute exact path="/learners" Component={Learners} isAuth={auth} />
                <ProtectRoute exact path="/account/wallet" Component={Wallet} isAuth={auth}/>
                <ProtectRoute exact path="/account/invoice" Component={Invoice} isAuth={auth}/>
                <ProtectRoute exact path="/settings" Component={Settings} isAuth={auth}/>
                <ProtectRoute exact path="/support" Component={Support} isAuth={auth} />
                <ProtectRoute exact path="/learners/student/:sid" Component={LearnerProfile} isAuth={auth} />
                <ProtectRoute exact path="/account/invoice/:vid" Component={InvoiceView} isAuth={auth} />
                <ProtectRoute exact path="/hlp/assess-download" Component={AssessDownload} isAuth={auth} />
                <ProtectRoute exact path="/hlp/assess-capture" Component={AssessCapture} isAuth={auth} />
                <ProtectRoute exact path="/hlp/view-assessment" Component={ViewAssess} isAuth={auth} />
                <ProtectRoute exact path="/hlp/terms-final" Component={TermFinal} isAuth={auth} />
                <ProtectRoute exact path="/add-learner-centre/:sid" Component={AddStudentToCentre} isAuth={auth} />
                <ProtectRoute exact path="/notification" Component={NotifyUser} isAuth={auth} />
                

                <Route exact path="*" component={ErrorPage} >
                 <ErrorPage checkLogin={auth} />
               </Route>
            </Switch>
          
          </Router>
        </div>
    )
   }
 
   const tutorSection = () =>{
    return(
      <>
        <Router>
        <HeaderNav userTypes={'Tutor'} />
          <Switch>
            <ProtectRoute exact path="/" Component={TutorDashBoard} isAuth={auth} />
            <ProtectRoute exact path="/support" Component={Support} isAuth={auth} />
            <ProtectRoute exact path="/centres" Component={Centres} isAuth={auth} />
            <ProtectRoute exact path="/wallet" Component={Wallet} isAuth={auth}/>
            <ProtectRoute exact path="/settings" Component={Settings} isAuth={auth}/>
            <ProtectRoute exact path="/centres/:cid" Component={ViewCentre} isAuth={auth} />
                <ProtectRoute exact path="/hlp/assess-download" Component={AccessDownload} isAuth={auth} />
                <ProtectRoute exact path="/hlp/assess-capture" Component={AccessCapture} isAuth={auth} />
                <ProtectRoute exact path="/hlp/view-assessment" Component={AccessView} isAuth={auth} />
                <ProtectRoute exact path="/hlp/terms-final" Component={TermsFinal} isAuth={auth} />
                <ProtectRoute exact path="/notification" Component={NotifyUser} isAuth={auth} />

             <Route exact path="*" component={ErrorPage} >
                 <ErrorPage checkLogin={auth} />
               </Route>

          </Switch>
        </Router>
        </>
    )
   }

   const errorViewStatus = () =>{
      return (
          <div>
            <h4>Server is down kindly check back later</h4>
          </div>
      )
   }


   const controlUserType = () =>{
          switch(userType){
              case 'Parent':
                return(
                  <div>
                      {    parentSection() }
                  </div>
                )
             
                break;
                case 'Tutor':
                    return (
                       <div>
                          {  tutorSection() }
                       </div>
                    )
                 
                  break;
                  default:
                      return (
                         <div>
                          {   errorViewStatus() }
                         </div>
                      )
                   
          }
   }

   const loginSection = () =>{
    return(
      <div className="App">
          <Router>
             <Switch>
             <Route exact path="/" Component={Login}>
             <Login  siteName={siteName} notify={notifyUsers} />
              </Route>

              <Route exact path="/login" Component={Login}>
              <Login  siteName={siteName} notify={notifyUsers} />
              </Route>

              <Route exact path="/register" Component={Register}>
                <Register siteName={siteName} notify={notifyUsers} />
              </Route>

              <Route exact path="/forgot" Component={Forgot}>
                 <Forgot notify={notifyUsers} />
              </Route>

               <Route exact path="*" Component={ErrorPage} >
                 <ErrorPage checkLogin={auth} />
               </Route>
             </Switch>
          </Router>
        </div>
    ) 
   }


   useEffect(()=>{

         const userlogStatus =  () =>{
                  try{

                    axios.get(loginStatusLink,{
                      headers: { 'Content-Type': 'application/json'},
                      withCredentials: true
                    }).then((res)=>{
  
                         
                         if(res.data.logStatus === 'allow'){
                               setAuth(true);
                               setUserType(res.data.userType);
                              
                         }else
                         {
                              setAuth(false)
                              setUserType('empty');
                            
                         }
                    }).catch(err =>{
                          console.log(err)
                    })

                  }catch(err){
                     console.log(err)
                  }

                

                  
         }
             userlogStatus();

             window.addEventListener('error', e => {
              if (e.message === 'ResizeObserver loop limit exceeded') {
                  const resizeObserverErrDiv = document.getElementById(
                      'webpack-dev-server-client-overlay-div'
                  );
                  const resizeObserverErr = document.getElementById(
                      'webpack-dev-server-client-overlay'
                  );
                  if (resizeObserverErr) {
                      resizeObserverErr.setAttribute('style', 'display: none');
                  }
                  if (resizeObserverErrDiv) {
                      resizeObserverErrDiv.setAttribute('style', 'display: none');
                  }
              }
          });
   },[auth])

     
    return (
        <div>
           {  auth === false ? 
           loginSection() 
           : controlUserType() }
        </div>
    )
 
   
}

export default App;
