import React from 'react'

function ErrorMessage({errors}) {

        const handleErrors = () =>{
           
            let viewStatus = "";
            switch(errors.uiType){
                  case 'load':
                      viewStatus = <div className={`spinner-border text-${errors.types} m-1`} role="status">
                      <span className="sr-only">Loading...</span>
                  </div>
                    break;
                    case 'message':
                      
                          viewStatus = <div className={`alert mt-4 alert-${errors.types} text-center`}>{errors.msg}</div>
                       
                    break;
                    case 'not':
                           viewStatus = <div> <div className="row justify-content-center">
                              
                          
                        
                         </div>
                         <div className='text-center'>
                         <h5 className="mt-2 mb-4 font-size-14">{errors.msg}</h5>
                              
                              </div>
                              </div>
                         
                      break;

                      case 'profile-status':
                        
                           if(errors.types === 'Active'){
                            viewStatus =  <div className="spinner-grow text-success m-1" style={{ width:10, height:10 }} role="status">
                                     <span className="sr-only">Loading...</span>
                                    </div>
                           }else
                           {
                            viewStatus =  <div className="spinner-grow text-danger m-1" style={{ width:10, height:10 }} role="status">
                                          <span className="sr-only">Loading...</span>
                                         </div>
                           }
                        break;

                        case 'check-empty':
                              if(errors.msg === null || errors.msg === ''){
                                   viewStatus = 'N/A';
                              }else
                              {
                                   viewStatus = errors.msg;
                              }
                          break;
                          case 'warn':
                               viewStatus =  <blockquote className="p-4 border-light border bg-warning rounded mb-4">
                
                               <div className="d-flex">
                                   <div className="me-3">
                                       <i className="bx bxs-quote-alt-left text-dark font-size-24"></i>
                                   </div>
                                   <div>
                                       <strong className="mb-0"> Creating a final report kindly confirm the following:</strong>
                                       <p className="mb-0"> 1) All marks have been finalised and verified.</p>
                                       <p className="mb-0">2) I accept that adjusting marks will no longer be possible after this confirmation.</p>
                                       <p className="mb-0">3) The academic year for this learner will be completed and closed with the result achieved..</p>
                   
                                       <strong className="mb-0">If you wish to continue kindly click on create report</strong>
                                       
                                       
                                   </div>
                               </div>
                               
                           </blockquote>
                            break;

            }

              return (
                 <div>
                    { viewStatus }
                 </div>
              )
           
        }

  return (
    <div>
         { handleErrors() }
    </div>
  )
}

export default ErrorMessage