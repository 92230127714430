import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import ErrorMessage from '../userAuth/ErrorMessage';
import Pagination from './Pagination';

let PageSize = 5;


function Invoice() {

  const [paidData, setPaidData] = useState([]);
  const [unPaidData, setUnPaidData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const Currency = (num) =>{
    let bala = parseInt(num)
return bala.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

  const handlePaid = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return paidData.slice(firstPageIndex, lastPageIndex);
  };
  const currentTableDataPaid = handlePaid();

  const handlePageer = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return unPaidData.slice(firstPageIndex, lastPageIndex);
  };

   const  currentTableUnPaid = handlePageer();



  const handleErrs = ($type,$msg,$uitype) =>{
    let errorChild = {types:$type, msg:$msg, uiType:$uitype}
  return (
    <div>
    <ErrorMessage errors={errorChild} />
    </div>
  
  )
}

  const getInvoice = () => {
        axios.get('/api-invoice-data',{
           headers:{'Content-Type':'application/json'}
        }).then((res)=>{
            // res.json({unPaidData,paidData})
            if(res.data.unPaidData === 'empty'){
                  setUnPaidData([])
            }else{
              setUnPaidData(res.data.unPaidData)
            }

            if(res.data.paidData === 'empty'){
                   setPaidData([])
            }else{
                  setPaidData(res.data.paidData)
            }

            

        })
  }

  const invoiceView = (type) =>{

     if(type === 'Paid'){
    return (
      <div>
        { paidData.length === 0 ? handleErrs(null,'No Paid Invoice','not') : 
        <>
        <div className="table-responsive">
          <table className="table align-middle table-nowrap table-hover">
            <thead className="table-success">
              <tr>
                <th scope="col" style={{width: '70px'}}>#</th>
                <th scope="col">Purpose</th>
                <th scope="col">Amount</th>
                <th scope="col">#Invoice ID</th>
                <th scope="col">Date Created</th>

                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
               { currentTableDataPaid && currentTableDataPaid.map((u, index)=>{
                   return (

                    <tr>
                    <td>
                    {index + 1}
                    </td>
                    <td>
                    
                      <p className="text-muted mb-0">{ u.purpose }</p>
                    </td>
                    <td>
                    
                      <p className="text-muted mb-0">R { Currency(u.amount) }</p>
                    </td>
                    <td>{ u.invoice_id }</td>
                   
                    <td>
                       { u.date_created }
                    </td>
                    <td>
                    <button onClick={()=> history.push("/account/invoice/"+u.invoice_id)} className="btn btn-primary btn-sm btn-rounded">
                                                                  <b> view invoice</b>
                                                               </button>
                    </td>
                  </tr>

                   )
               })}
             
             
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-lg-12">
                                                 <Pagination
                                                        className="justify-content-center mt-4"
                                                        currentPage={currentPage}
                                                        totalCount={paidData.length}
                                                        pageSize={PageSize}
                                                        onPageChange={page => setCurrentPage(page)}
                                                    />
          </div>
        </div>
        </>
     }

      </div>
    );

     }else{

      return (
        <div>
        { unPaidData.length === 0 ? handleErrs(null,'No UnPaid Invoice','not') : 
        <>
        <div className="table-responsive">
          <table className="table align-middle table-nowrap table-hover">
            <thead className="table-danger">
              <tr>
                <th scope="col" style={{width: '70px'}}>#</th>
                <th scope="col">Purpose</th>
                <th scope="col">Amount</th>
                <th scope="col">#Invoice ID</th>
                <th scope="col">Date Created</th>

                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
               { currentTableUnPaid && currentTableUnPaid.map((u, index)=>{
                   return (

                    <tr>
                    <td>
                    {index + 1}
                    </td>
                    <td>
                    
                      <p className="text-muted mb-0">{ u.purpose }</p>
                    </td>
                    <td>
                    
                      <p className="text-muted mb-0">R { Currency(u.amount) }</p>
                    </td>
                    <td>{ u.invoice_id }</td>
                   
                    <td>
                       { u.date_created }
                    </td>
                    <td>
                    <button onClick={()=> history.push("/account/invoice/"+u.invoice_id)} className="btn btn-primary btn-sm btn-rounded">
                                                                  <b> view invoice</b>
                                                               </button>
                    </td>
                  </tr>

                   )
               })}
             
             
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-lg-12">
                                                 <Pagination
                                                        className="justify-content-center mt-4"
                                                        currentPage={currentPage}
                                                        totalCount={unPaidData.length}
                                                        pageSize={PageSize}
                                                        onPageChange={page => setCurrentPage(page)}
                                                    />
          </div>
        </div>
        </>
     }

      </div>
  );

     }
  }

  useEffect(()=>{
      getInvoice();
  },[]);

  return (
   
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
         

      <div className="col-xl-12">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title mb-2">INVOICE</h4>
            <hr className='text-danger mb-4' style={{ marginTop:-5 }} />
          
            {/* Nav tabs */}
            <ul className="nav nav-pills nav-justified mt-4" role="tablist">
              <li className="nav-item waves-effect waves-light">
                <a className="nav-link active" data-bs-toggle="tab" href="#paid-1" role="tab">
                  <span className="d-block d-sm-none"><i className="fas fa-home" /></span>
                  <span className="d-none d-sm-block">UnPaid Invoice</span> 
                </a>
              </li>
              <li className="nav-item waves-effect waves-light">
                <a className="nav-link" data-bs-toggle="tab" href="#unpaid-1" role="tab">
                  <span className="d-block d-sm-none"><i className="far fa-user" /></span>
                  <span className="d-none d-sm-block">Paid Invoice</span> 
                </a>
              </li>
             
            </ul>
            {/* Tab panes */}
            <div className="tab-content p-3 text-muted">
              <div className="tab-pane active" id="paid-1" role="tabpanel">
              { invoiceView('Unpaid')}
              
              </div>
              <div className="tab-pane" id="unpaid-1" role="tabpanel">
              { invoiceView('Paid')}
              </div>
             
            
            </div>
          </div>
        </div>
      </div>


        </div>
      </div>
     </div>
  )
}

export default Invoice