import React from 'react'
import {useState, useEffect, useMemo} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import axios from 'axios';

import ErrorMessage from '../userAuth/ErrorMessage';
import SubjectAddHandler from '../userAuth/SubjectAddHandler';
import FootNav from '../Nav/FootNav';
import Pagination from '../General/Pagination'
import '../../App.css';


let PageSize = 4;

function LearnerProfile() {
        const {sid} = useParams();
      
        const [studentData, setStudentData] = useState(false);
        const [sname, setSname] = useState();
        const [fee, setFee] = useState();
        const [dueDate, setDueDate] = useState();
        const [subData, setSubData] = useState([]);
        const [profileStatus, setProfileStatus] = useState(false);
        const [grade, setGrade] = useState();
        const [selGrade, setSelGrade] = useState('');
        const [loading, setLoading] = useState(false);
        const [termcheck, setTermCheck] = useState();
        const [notify, setNotify] = useState(null);
        const [displayTerm, setDisplayTerm] = useState(false)
        const [selTerm, setSelTerm] = useState('');
        const [subScribe, setSubScribe] = useState('');
        const [loadunRegSub, setLoadUnRegSub] = useState(false);
        const [loadSubject, setLoadSubject] = useState(false);
        const [priceTerm, setTermPrice] = useState();
        const [priceYear, setPriceYear] = useState();
        const [loadBut, setLoadBut] = useState(false);
        const [cartItems, setCartItems] = useState([]);
        const [checkInvoice, setCheckInvoice] = useState(null);
        const [centreData, setCentreData] = useState(false);
        const [currTerm, setCurrTerm] = useState(null);
        const [currGrade, setCurrGrade] = useState(null);
        const [subCount, setSubCount] = useState(0);
        const [msData, setMSData] = useState();
        const [singleSID, setSingleSID] = useState();
        const [suName, setSuname] = useState();
        const [remSub, setRemSub] = useState(false);
        const [moreSubject, setMoreSubject] = useState(null);
        const [extraCart, setExtraCart] = useState([]);
        const [query, setQuery] = useState('');
        const [filterSearch, setFilterSearch] = useState([]);
        const [loadam, setLoadAm] = useState(false);
        const [currentPage, setCurrentPage] = useState(1);
        const [notifer, setNotifer] = useState(null);
        const [loadUp, setLoadUp] = useState(false);
        const [lnames, setLNames] = useState('');
        const [ldob, setLDob] = useState('');
        const [laddress, setLAddress] = useState('');
        const [lregion, setLRegion] = useState('');
        const [lrace, setLRace] = useState('');
        const [lgender, setLGender] = useState('');
        const [lhomeLang, setLHomelang] = useState();
        const [lotherLang, setLOtherLang] = useState('');
        const [upNotices, setUpNotices] = useState(null);
        const [allregion, setAllRegion] = useState([]);
        const [lgrade, setLGrade] = useState();
        const [updateStatus, setUpdateStatus] = useState(false);
        const [studCent, setStudCent] = useState(null)
        const [requestDetails, setRequestDetails] = useState('');
        const [noti, setNoti] = useState(null);
        const [actionType, setActionType] = useState('');
        const [loadR, setLoadR] = useState(false)
       


        const handleRequest = () =>{
                    setNoti(null)
              if(!requestDetails.trim() || !actionType.trim()){
                    setNoti(handleErrs('danger','Please request details is required','message'))
              }else
              {
                    setLoadR(true)
                 axios.post('/api-processrequest',{requestDetails,sid,utype:'Parent',actionType},{
                    headers:{'Content-Type':'application/json'}
                 }).then((res)=>{
                     setTimeout(()=>{
                        setLoadR(false)
                        setNoti(handleErrs(res.data.color,res.data.message,'message'))
                     },1000)
                   
                 }).catch((err)=>{
                    console.log(err)
                 })
              }
        }


        const handlePage = () => {
            const firstPageIndex = (currentPage - 1) * PageSize;
            const lastPageIndex = firstPageIndex + PageSize;
            return subData.slice(firstPageIndex, lastPageIndex);
          };
      
            const currentTableData = handlePage()
        const history = useHistory();

            let exCount = extraCart.length;

        const removeExtraSubject = (sid) =>{
    
            const updatedCartItems = extraCart.filter((item) => item.id !== sid);

                    setExtraCart(updatedCartItems);
        }

        const removeLearnerCent = (cid,sid) => {
                        setLoadAm(true)
                        axios.post('/remove-studentcent',{cid,sid},{
                             headers:{'Content-Type':'application/json'}
                        }).then((res)=>{
                             setTimeout(()=>{
                                   setLoadAm(false)
                                if(res.data.status === 'success'){
                                     
                                    setCentreData(false)
                              }
                             },1000)
                               
                        }).catch((err)=>{
                                console.log(err)
                        }) 
        }

        const viewStudeCentre = () => {
                    return (
                        <>
                        <div className="col-lg-6">
                              <div className="media">
                              <div className="mb-4 me-3">
                                                <i className="mdi mdi-account-circle text-primary h1"></i>
                                            </div>
                                  <div className="media-body align-self-center">
                                      <div className="text-muted">
                                        
                                          <h5 className="mb-1">{ centreData[0].centre_name } </h5>
                                            <p style={{ fontWeight:'bolder' }}>Centre ID: { centreData[0].cid }</p>
                                          <p style={{ marginTop:-15 }}>Date Join: { centreData[0].date_join }</p>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="col-lg-6 align-self-center">
                              <div className="text-lg-end mt-4 mt-lg-0">
                                { centreData[0].status === 'Pending' ? <strong style={{ textTransform:'capitalize' }}>  <b className="text-danger">PENDING</b>  </strong> : <strong> <b className="text-success"> CONNECTED </b> </strong>}
                              </div>

                              <div className="clearfix mt-4 mt-lg-0">
                                  <div className=" float-end">

                                    { studCent[0].admin_status !== 'allow' ? 
                                  <button type="button" className="btn btn-danger btn-sm mt-4 waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                  <i className="bx bx-trash-alt align-middle me-1"></i>    Request to remove Student
                                            </button>
                                            :
                                            loadam === false ? 
                                      <button className="btn btn-danger btn-sm wave-effect mt-4" type="button" onClick={()=> removeLearnerCent(centreData[0].cid,sid)}>
                                          <i className="bx bx-trash-alt align-middle me-1"></i> Remove Student
                                      </button> :

                                      <button type="button" className="btn btn-danger btn-sm waves-effect mt-4" disabled>
                                                <i className="bx bx-hourglass bx-spin font-size-14 align-middle me-2"></i> Loading
                                            </button>
                                        }


                                     
                                  </div>
                              </div>
                          </div>

                                      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="staticBackdropLabel">Request to remove student from centre</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">

                                                            { noti !== null ? noti : null}
                                                         
                                                            <p>Please kindly put down the reason why you want to remove this learner from his current centre.<br></br>
                                                                 <strong className='text-danger'>Note: You must include the centre name and ID for quick request approval</strong> </p>
                                                            <select className='form-select mb-3' onChange={(e)=> setActionType(e.target.value)}>
                                                                <option value={''}>Select Reason type</option>
                                                                <option value={'removal'}>Remove learner</option>
                                                                <option value={'Complain'}>Complain</option>
                                                            </select>
                                                            <textarea className='form-control' onChange={(e)=> setRequestDetails(e.target.value)} placeholder='Your reasons'></textarea>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                           { loadR === false ? 
                                                            <button type="button" className="btn btn-primary" onClick={()=> handleRequest()}>Submit Request</button>
                                                            :
                                                            <button type="button" className="btn btn-primary" disabled>Please wait...</button>
                                                           }
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>

                        
                          </>
                    )
        }

        

    const addExtraSubjects = async () =>{
                let uexpirice= calExtraCart();
                const data = {extraCart,currGrade,uexpirice,sid}
               await axios.post('/api-extrasubjectdatas',data,{
                     headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                     setTimeout(()=>{
                              if(res.data.status !== 'success'){
                                  window.location.href='/account/invoice/'+res.data.invid;
                              }else{
                                setRemSub(true);
                                
                              }
                     },1000)
                })
    }


        const handleLiveSearch = async (e) => {
                        let q = e.target.value;
                         setQuery(q);
                        if(q !== ''){
                            const filteredData = moreSubject.filter((item) => {
                                return Object.values(item).join('').toLowerCase().includes(q.toLowerCase())
                            })

                            setFilterSearch(filteredData)

                        }else
                        {
                            setFilterSearch(moreSubject)
                        }


                 
        }

        const calExtraCart = () =>{
                    let price = 0;

                 for (let i=0; i<extraCart.length; i++){
                    let mprice;
                    if(extraCart[i].price === '' || extraCart[i].price === null){
                             mprice = 0
                    }else{
                            mprice = extraCart[i].price;
                    }

                    price = price + parseInt(mprice)
                 

                 }  
                 
               
                 return price;
              
                       
        }

        const handleExtraAddSubject = (sid,price,names,cate) =>{
             
            const newItem = {
                 id:sid,
                 price:price,
                 names:names,
                 cate:cate
            }

            setExtraCart((prevItems)=>[...prevItems, newItem])

}

const checkIfExtraSubjectExist = (subID) =>{
               
    if(extraCart.find((item) => item.id === subID)){
        return true;
    } 
            
}


      const getMoreSubject = (grd) =>{

            axios.get('/get-subject/'+grd,{
                 headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                    if(res.data.subjectData !== 'empty'){
                          setNotifer(null)
                          setMoreSubject(res.data.subjectData);
                    }else
                    {
                         setMoreSubject(null)
                        setNotifer(handleErrs('danger',`We are unable to retrieve all subject for Grade ${grd}`,'message'))
                    }
            })
      

      }

      const checkIfSubjectExist = (subID) =>{
               
        if(subData.find((item) => item.subject_id === subID)){
            return true;
        } 
               
          
    }

        const addMoreSubject = () =>{

              return (
                <div className="modal fade" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            
                            
                               
                                <input type='text' className='form-control' onChange={(e)=> handleLiveSearch(e)} placeholder='Search for subject...' />

                                       
                          
                        </div>

                        
                        <div className="modal-body">
                                    { exCount > 0 ?
                                 <div className="table-responsive">
                                            <table className="table mb-0">
                                                <tbody className=' text-dark'>
                                                   
                                                    <tr>
                                                        <th>{ exCount > 1 ? exCount+' Subjects' : exCount+' Subject'}</th>
                                                        <th className='text-end'>Total:  &nbsp; &nbsp; &nbsp; R { calExtraCart() }</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> : null }
                               
                                { moreSubject !== null ?
                           <div data-simplebar style={{ maxHeight: 390 }}>

                                    <table className="table align-middle table-nowrap">
                                                        <tbody>


                                                {
                                                  query.length > 0 ? 
                                                  
                                                  filterSearch && filterSearch.map((x, index)=>{
                                                    return (

                                                      <tr key={1 + index}>
                                                      
                                                      <td>
                                                          <div>
                                                              <h5 className="font-size-14 mb-1">{ x.names }</h5>
                                                            
                                                          </div>
                                                      </td>

                                                      <td>
                                                          <div className="text-end">
                                                              <h5 className="font-size-14 mb-0">R { checkPrice(x.price) }</h5>
                                                          </div>
                                                      </td>

                                                      <td>
                                                          <div className="text-end">
                                                              <h5 className="font-size-14 mb-0">{ x.cate }</h5>
                                                          </div>
                                                      </td>

                                                      <td>
                                                          <div className="text-end">
                                                              { checkIfSubjectExist(x.subject_id) === true ? 
                                                               <button className='btn btn-sm btn-success' disabled>  <i className="bx bx-plus align-middle me-1 font-size-15"></i> Add Subject</button>
                                                             
                                                              :

                                                            checkIfExtraSubjectExist(x.subject_id) === true ?
                                                            <button type="button" onClick={()=> removeExtraSubject(x.subject_id)} className="btn btn-danger btn-sm btn-rounded">
                                                            <i className="bx bx-trash font-size-16 align-middle me-2"></i>  Remove
                                                            </button>   :
                                                               <button className='btn btn-sm btn-success' onClick={()=> handleExtraAddSubject(x.subject_id,x.price,x.names,x.cate)}>  <i className="bx bx-plus align-middle me-1 font-size-15"></i> Add Subject</button>
                                                           
                                                             }
                                                              </div>
                                                      </td>
                                                  </tr>

                                                    )
                                              })
                                                       
                                                  :
                                                
                                                moreSubject && moreSubject.map((x, index)=>{
                                                      return (

                                                        <tr key={1 + index}>
                                                        
                                                        <td>
                                                            <div>
                                                                <h5 className="font-size-14 mb-1">{ x.names }</h5>
                                                              
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="text-end">
                                                                <h5 className="font-size-14 mb-0">R { checkPrice(x.price) }</h5>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="text-end">
                                                                <h5 className="font-size-14 mb-0">{ x.cate }</h5>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="text-end">
                                                                { checkIfSubjectExist(x.subject_id) === true ? 
                                                                 <button className='btn btn-sm btn-success' disabled>  <i className="bx bx-plus align-middle me-1 font-size-15"></i> Add Subject</button>
                                                               
                                                                :

                                                              checkIfExtraSubjectExist(x.subject_id) === true ?
                                                              <button type="button" onClick={()=> removeExtraSubject(x.subject_id)} className="btn btn-danger btn-sm btn-rounded">
                                                              <i className="bx bx-trash font-size-16 align-middle me-2"></i>  Remove
                                                              </button>   :
                                                                 <button className='btn btn-sm btn-success' onClick={()=> handleExtraAddSubject(x.subject_id,x.price,x.names,x.cate)}>  <i className="bx bx-plus align-middle me-1 font-size-15"></i> Add Subject</button>
                                                             
                                                               }
                                                                </div>
                                                        </td>
                                                    </tr>

                                                      )
                                                })}
                                                          

                                                           
                                                        </tbody>
                                                    </table>
                     
                         </div> :
                                                    notifer }

                             
                        </div>
                        <div className="modal-footer">
                           
                                                    
                          
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={()=> addExtraSubjects()}>Continue</button>
                            
                           
                            </div>
                    </div>
                </div>
            </div>
              )
        }


        const removeSubject = () => {
                axios.post('/remove-subjectdata',{subID:singleSID,sid:sid},{

                }).then((res)=>{
                        if(res.data.subam === 'success'){
                              setRemSub(true)
                        }
                }).catch((err)=>{
                    console.log(err)
                })
        }

   
        const checkPrice = (price) => {
            if(price === null || price === ''){
                  return 0;
            }else{
                  return price;
            }
    }

        const handleDurationPrice = () => {
                        let mtotal;
                    if(subScribe === 'Yearly'){
                           mtotal = priceYear;
                    }else{
                        mtotal = priceTerm; 
                    }

                     return mtotal;
        }

        const removeSubjectFromCart = (sid) =>{
    
            const updatedCartItems = cartItems.filter((item) => item.id !== sid);

                    setCartItems(updatedCartItems);
        }




        const handleSubjectRemoval = (sid,subname) =>{
                    setSingleSID(sid);
                    setSuname(subname)

        }
        

        const handleAddSubjectCart = () => { 
            const cartHand = {handleDurationPrice, subScribe,cartItems,loading,notify,grade,displayTerm,
                termcheck,selGrade,loadunRegSub,loadSubject,removeSubjectFromCart, handleGrade,handleAddSubject,handleTermSelection,handleErrs, handleSub}
                return(
                    <div>
                        { <SubjectAddHandler cartHandler={cartHand}  />}
                    </div>
                )
        }


        const handleErrs = ($type,$msg,$uitype) =>{
            let errorChild = {types:$type, msg:$msg, uiType:$uitype}
          return (
            <div>
            <ErrorMessage errors={errorChild} />
            </div>
          
          )
       }

       const handleSub = (msub) => {
                setSubScribe(msub);

       }

       const handleTermSelection = (mterm) => {
                  
                    setLoading(true)
                    setTimeout(()=>{
                        setSelTerm(mterm);
                        setLoadUnRegSub(true);
                        setLoading(false);

                        axios.get('/get-subject/'+selGrade,{
                             headers:{'Content-Type':'application/json'}
                        }).then((res)=>{
                                if(res.data.subjectData !== 'empty'){
                                      setNotify(null)
                                      setLoadSubject(res.data.subjectData);
                                }else
                                {
                                     setLoadSubject(false)
                                    setNotify(handleErrs('danger',`We are unable to retrieve all subject for Grade ${selGrade}`,'message'))
                                }
                        })
                    },1000)
       }

       const handleGrade = (g) =>{
                  let sp = g.split("=>");
                   setLoading(true)
                   setDisplayTerm(false)
                   setSelGrade(sp[0]);
                   setTermCheck(sp[1]);
                   if(sp[2] === null){
                        setTermPrice(0)
                   }else{
                        setTermPrice(sp[2])
                   }

                   if(sp[3] === null){
                    setPriceYear(0)
               }else{
                    setPriceYear(sp[3])
               }
                   setTimeout(()=>{
                         setLoading(false)
                         setDisplayTerm(true)
                   },1000)     
                    
       }

       const handleTerms = () =>{
               
               setNotify(null)
               if(selGrade === '' || selTerm === '' || subScribe === '' || cartItems.length === 0){
                   setNotify(handleErrs('danger','Please all selection field are required','message'));
               }else
               {
                  setNotify(null)
                  setLoadBut(true);

                const durationPrice =  handleDurationPrice();

                  axios.post('/api-add-subject-student',{selGrade,selTerm,subScribe,cartItems,sid,durationPrice},{
                      headers:{'Content-Type':'application/json'}
                  }).then((res)=>{
                            setTimeout(()=>{
                                if(res.data.status === 'success'){
                                    setNotify(null);
                                    history.push("/account/invoice/"+res.data.invoiceID)
                                }else{
                                     setNotify(handleErrs('danger',res.data.message,'message'));
                                }
                            },1000)
                     
                  })
                  


               }
       }



       const handleLearnerUpdate = () => {
                    setUpNotices(null)
         if(!lnames.trim() || !laddress.trim() || !ldob.trim() ||
          !lgender.trim() || !lhomeLang.trim() ||
           !lotherLang.trim() || !lrace.trim() || !lregion.trim()){
              setUpNotices(handleErrs('danger','Please make sure all form input are not empty','message'))
           }else
           {
                 setLoadUp(true)
               let updatas = {lgrade,lnames,laddress,ldob,lgender,lhomeLang,lrace,lotherLang,lregion,sid}
                axios.post('/api-updatelearner-data',updatas,{
                     headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                     setTimeout(()=>{
                          setLoadUp(false)
                        setUpNotices(handleErrs(res.data.color,res.data.message,'message'))
                     },1000)
                       
                }).catch( err => {
                     console.log(err)
                })

           }

       }




 const handleAddSubject = (sid,price,names,cate) =>{
             
                const newItem = {
                     id:sid,
                     price:price,
                     names:names,
                     cate:cate
                }

                setCartItems((prevItems)=>[...prevItems, newItem])

 }
     

        const viewStudentData = () => {
                        return (
                            <div className='row'>

                                { 
                                 checkInvoice !== null ? checkInvoice : 
                                profileStatus === false ? <div className='alert alert-danger text-center'>Student profile account is not active. </div>: null }

        
                            <div className="col-xl-4">
                                <div className="card">
                                    <div className="card-body">

                                    <div className="modal fade" id="staticBack" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="staticBackdropLabel">Learners Settings</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">

                                                            { upNotices === null ? null : upNotices }


                                                            <div className="mb-3">
                                                            <label htmlFor="formrow-firstname-input" className="form-label">Learner Names</label>
                                                            <input type="text" name="names" onChange={(e) => setLNames(e.target.value) } className="form-control" defaultValue={msData.names} />
                                                            </div>

                                                            <div className="mb-1">
                                                            <label htmlFor="formrow-firstname-input" className="form-label">Grade</label>
                                                            <select  name="names"  className="form-select" onChange={(e)=> setLGrade(e.target.value)}>
                                                                 <option value={msData.grade}>Grade {msData.grade}</option>
                                                                  <option value={1}>Grade 1</option>
                                                                  <option value={2}>Grade 2</option>
                                                                  <option value={3}>Grade 3</option>                      
                                                                  <option value={4}>Grade 4</option>
                                                                  <option value={5}>Grade 5</option>
                                                                  <option value={6}>Grade 6</option>
                                                                  <option value={7}>Grade 7</option>
                                                                  <option value={8}>Grade 8</option>
                                                                  <option value={9}>Grade 9</option>
                                                                  <option value={10}>Grade 10</option>
                                                                </select>
                                                                <small className='text-danger'>Fee will be change if grade is change</small>
                                                            </div>

                                                          <div className="mb-3 row">
                                                                <div className='col-lg-6'>
                                                                <label htmlFor="formrow-firstname-input" className="form-label">Gender</label>
                                                                 <select  name="names"  className="form-select" onChange={(e) => setLGender(e.target.value)}>
                                                                 <option value={msData.gender}>{msData.gender}</option>
                                                                    <option value={'Male'}>Male</option>
                                                                    <option value={'Female'}>Female</option>
                                                                </select>
                                                                </div>

                                                                <div className='col-lg-6'>
                                                                <label htmlFor="formrow-firstname-input" className="form-label">Date of Birth</label>
                                                                      <input type="date" name="names" onChange={(e) => setLDob(e.target.value)}  className="form-control" defaultValue={msData.dob} />
                                                                    </div>
                                                       
                                                           
                                                        </div>


                                                            <div className='row'>
                                                                <div className='col-lg-6'>

                                                                <div className="mb-3">
                                                            <label htmlFor="formrow-firstname-input" className="form-label">Home Language</label>
                                                            <input type="text" name="names" className="form-control" onChange={(e)=> setLHomelang(e.target.value)} defaultValue={msData.home_lang} />
                                                           
                                                             </div>

                                                                </div>

                                                                <div className='col-lg-6'>

                                                                <div className="mb-3">
                                                            <label htmlFor="formrow-firstname-input" className="form-label">Other Language</label>
                                                            <input type="text" name="names" onChange={(e) => setLOtherLang(e.target.value)} className="form-control" defaultValue={msData.other_lang} />
                                                           
                                                                    </div>
                                                                    
                                                                    </div>
                                                            </div>



                                                            <div className="mb-3 row">
                                                                <div className='col-lg-6'>

                                                                <label htmlFor="formrow-firstname-input" className="form-label">Race</label>
                                                            <select  className="form-select" onChange={(e)=>setLRace(e.target.value)}>
                                                               <option value={msData.race}>{ msData.race }</option>
                                                            <option value={'Black African'}>Black African</option>
                                                                <option value={'Colored'}>Colored</option>
                                                                <option value={'White'}>White</option>
                                                                <option value={'Indian / Asia'}>Indian / Asia</option>
                                                                
                                                            </select>

                                                                </div>
                                                                <div className='col-lg-6'>

                                                                <label htmlFor="formrow-firstname-input" className="form-label">Region</label>
                                                            <select  name="names"  className="form-select" onChange={(e)=> setLRegion(e.target.value)}>
                                                                 <option value={msData.region}> {msData.region}</option>
                                                                 { allregion && allregion.map((g)=>{
                                                                            return (
                                                                                <option key={g.id} value={g.location}>{ g.location } </option>
                                                                            )
                                                                 }) }

                                                                </select>
                                                               

                                                                </div>
                                                           
                                                           
                                                        </div>

                                                   
                                                        <div className="mb-3">
                                                            <label htmlFor="formrow-firstname-input" className="form-label"> Address</label>
                                                            <textarea style={{ resize:'none' }} onChange={(e)=> setLAddress(e.target.value)} className="form-control">{ msData.address }</textarea>
                                                           
                                                        </div>

                                                        </div>
                                                        <div className="modal-footer">
                                                           { loadUp === false ?
                                                            <button type="button" onClick={()=> handleLearnerUpdate()} className="btn btn-dark">Update Learner</button>
                                                            :   <button type="button" className="btn btn-dark waves-effect" disabled>
                                                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Updating...
                                                        </button>
                                                             }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        <div className="media">
                                        <div className="mb-4 me-3">
                                                <i className="mdi mdi-account-circle text-primary h1"></i>
                                            </div>
                                            <div className="media-body">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <div className="text-muted">
                                                            <h5 className="mb-2" style={{ textTransform:'capitalize' }}>{sname}</h5>
                                                            <p className="mb-0 text-dark">ID: {sid}</p>
                                                            <div className='d-flex text-dark mt-1'>Status: { profileStatus === false ? <div className='d-flex'> { handleErrs('Not Active',null,'profile-status') } Not Active</div> : <div className='d-flex'> { handleErrs('Active',null,'profile-status') } Active</div> } </div>
                                                           
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="dropdown ms-2">
                                                        <button className="btn btn-light btn-sm" type="button" data-bs-toggle="modal" data-bs-target="#staticBack">
                                                            <i className="bx bxs-cog align-middle me-1"></i> Edit Profile
                                                        </button>
                                                        
                                                    </div>  
                                                </div>
                                                

                                                <hr />

                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className='text-center'>
                                                            <h6 className="text-dark text-truncate mb-2 font-size-12">Fee Paid</h6>
                                                            <h5 className="mb-0 font-size-15 text-dark" style={{ fontWeight:'bolder' }}>R { fee === null ? '0.00' : fee }</h5>
                                                        </div>
                                                    </div>
                                                   

                                                    <div className="col-4">
                                                        <div className='text-center'>
                                                            <h6 className="text-dark text-truncate mb-2 font-size-12">Term</h6>
                                                            <h5 className="mb-0 font-size-15 text-dark" style={{ fontWeight:'bolder' }}> { handleErrs(null,currTerm,'check-empty') }</h5>
                                                        </div>
                                                    </div>
 
                                                    <div className="col-4">
                                                        <div className='text-center'>
                                                            <h6 className="text-dark text-truncate mb-2 font-size-12">Grade</h6>
                                                            <h5 className="mb-0 font-size-15 text-dark" style={{ fontWeight:'bolder' }}> <span style={{ width:10 }}></span>  { handleErrs(null,currGrade,'check-empty') }</h5>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            
                                        </div>
                                        
                                    </div>
                                </div>


                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Other Learner Information</h4>
                   
                                        <div className="table-responsive">
                                            <table className="table table-nowrap mb-0">
                                                <tbody>
                                                <tr>
                                                        <th scope="row">Gender :</th>
                                                        <td>{ msData.gender }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Date of Birth :</th>
                                                        <td>{ msData.dob }</td>
                                                    </tr>
                                                  

                                                    <tr>
                                                        <th scope="row">Home Language :</th>
                                                        <td>{ msData.home_lang} </td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row">Other Language :</th>
                                                        <td>{ msData.other_lang } </td>
                                                    </tr>

                                                
                                                    <tr>
                                                        <th scope="row">{ msData.id_type } :</th>
                                                        <td>{msData.stud_id_no}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Address</th>
                                                        <td>{ msData.address }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Region</th>
                                                        <td>{ msData.region }</td>
                                                    </tr>
                                                  
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            

             

                            </div>


                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                
                                                <div className="d-flex flex-wrap">
                                                    <div className="me-3">
                                                        <p className="text-muted mb-2">Accademic Year</p>
                                                        <h5 className="mb-0 d-flex me-2 font-size-14">
                                                           { msData.academic_year } 
                                                        </h5>
                                                    </div>
    
                                                    <div className="avatar-sm ms-auto">
                                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                            <i className="bx bxs-book-bookmark"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-4">
                                        <div className="card blog-stats-wid">
                                            <div className="card-body">

                                                <div className="d-flex flex-wrap">
                                                    <div className="me-3">
                                                        <p className="text-muted mb-2">Due Date</p>
                                                        <h5 className="mb-0 d-flex font-size-12">
                                                        { dueDate === null ? 'N/A' : dueDate }
                                                       
                                                        </h5>
                                                    </div>
    
                                                    <div className="avatar-sm ms-auto">
                                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                            <i className="bx bxs-note"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="card blog-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <div className="me-3">
                                                        <p className="text-muted mb-2">Total Subject</p>
                                                        <h5 className="mb-0 d-flex font-size-14">
                                                                { subCount > 1 ? subCount+' Subjects' : subCount+' Subject'}
                                                        </h5>
                                                    </div>
    
                                                    <div className="avatar-sm ms-auto">
                                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                            <i className="bx bxs-message-square-dots"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                        { profileStatus === false ? null :
                                    <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        
                                        <div className="row">
                                            { centreData === false ? <>
                                                <div className="col-lg-12 align-self-center">
                                                <div className="text-lg-center mt-4 mt-lg-0">
                                                    <div className="row">

                                                        <div className='col-lg-2'></div>

                                                        <div className='col-lg-8'>
                                                        <button onClick={()=> history.push('/add-learner-centre/'+sid)} className="btn btn-outline-danger dropdown-toggle" type="button">
                                                            <i className="bx bx-user-plus align-middle me-1"></i> <b>Add student to centre</b>
                                                        </button>
                                                        </div>
                                                      
                                                  
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            </> :
                                             viewStudeCentre()
                                          }

                                        </div>
                                     
                                    </div>
                                </div>
                            </div>
                                     </div> }
                     

                                </div>
                             

                                <div className="card">
                                    <div className="card-body">

                                        
                                        { addMoreSubject() }

                                        <div className="d-flex">
                                            <h5 className="card-title me-2">Student Subjects</h5>

                                                   
                                
                                        </div>
                                        { subData.length > 0 ? 
                                        <div style={{ float:'right', marginTop:-35 }}>
                                            
                                            <button type="button" className="btn btn-primary waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#exampleModalScrollable">Add More Subject</button>

                                                        </div> : null }

                                        <hr className="mb-1" />

                                       

                                        { subData.length === 0 ? <div> {handleErrs(null,`${sname} has not register any subject`,'not')} 
                                                    <div className='text-center'>
                                                    <button className="btn btn-success btn-sm" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                            <i className="bx bx-plus align-middle me-1"></i> Add Subject
                                                        </button>
                                                    </div>

                                                    <div className="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="staticBackdropLabel">Complete Student Registration</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">

                                                            { handleAddSubjectCart() }

                                                           
                                                        </div>
                                                        <div className="modal-footer">
                                                         
                                                              
                                                            <button type="button" onClick={()=> handleTerms()} data-bs-dismiss="modal" className="btn btn-success">Continue</button>
                                                             
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         : 

                                        <div className="table-responsive">
                                        <table className="table project-list-table table-nowrap align-middle table-borderless">
                                            <thead>
                                            <tr>
                                           
                                            <th scope="col">S/N</th>
                                                <th scope="col">Name of Subject</th>
                                                <th scope="col">Subject ID</th>
                                                <th scope="col">Category</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Price</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                { currentTableData && currentTableData.map((sub, index)=>{
                                                      return (
                                                        <tr>
                                                        <td>{ index + 1 }</td>
                                                        <td>{ sub.subject_name }</td>
                                                        <td>{ sub.subject_id }</td>
                                                        <td>{ sub.subject_cate }</td>
                                                        <td>{ sub.status }</td>
                                                        <td>{ checkPrice(sub.price) }</td>
                                                        <td>
                                                           <div style={{ cursor:'pointer' }} onClick={()=> handleSubjectRemoval(sub.subject_id,sub.subject_name)} data-bs-toggle="modal" data-bs-target=".bs-example-modal-sm">
                                                           <i className='bx bx-trash text-danger'></i>
                                                           </div> 
                                                        </td>
                                                    </tr>
                                                      )
                                                })}
                               
                                            
                                            
                                            </tbody>
                                        </table>

                 <div className="modal fade bs-example-modal-sm" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content modal-filled">
                        <div className="modal-body p-4">
                            <div className="text-center text-dark">
                                <i className="dripicons-wrong h1 text-dark"></i>
                                <h4 className="mt-2">Oh snap!</h4>
                                <p className="mt-3">You are about to delete <strong>"{ suName }"</strong> from learner subject list. All details of this Subject
                                 will be removed permanently.</p>
                                <h6 className="mt-1">Are you sure you want to continue.</h6>
                               
                               
                            </div>
                        </div>

                        <div className="modal-footer justify-content-center">
                                  <button type="button" className="btn btn-success" data-bs-dismiss="modal" >Close</button>
                                 <button type="button" onClick={()=> removeSubject()} className="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                             </div>
                    </div>
                </div>
            </div>

                                       
                                        <div className="row">
                                                    <div className="col-lg-12">

                                                    <Pagination
                                                        className="justify-content-center mt-4"
                                                        currentPage={currentPage}
                                                        totalCount={subData.length}
                                                        pageSize={PageSize}
                                                        onPageChange={page => setCurrentPage(page)}
                                                    />
                                                    </div>
                                                </div>
                                       
                                        </div>
                                                  }

                                            
                                                
                                      
                                    </div>
                                </div>
                            </div>
                          
                         

                                 </div>
                        );
        }
        

    
        useEffect(()=>{
 
            const getStudent = () => {
               
                axios.get('/get-student-data/'+sid,{
                     headers: {'Content-Type':'application/json'}
                }).then((res)=>{
                            if(res.data.subjectData !== 'empty'){
                                        setSubData(res.data.subjectData);
                                         let coutSub = res.data.subjectData;
                                          setSubCount(coutSub.length);
                            }else{
                                setSubData([]);
                                //setSubData(0)
                            }

                            if(res.data.studCent !== 'empty'){
                                 setStudCent(res.data.studCent)
                            }else
                            {
                                 setStudCent(null)
                            }


                             if(res.data.centData !== 'empty'){
                                        setCentreData(res.data.centData);
                             }else
                             {
                                        setCentreData(false)
                             }

                             
                       if(res.data.studentData !== 'empty'){
                                setStudentData(res.data.studentData);
                                setSname(res.data.studentData[0].names);
                                setFee(res.data.studentData[0].term_fee);
                                setDueDate(res.data.studentData[0].due_date);
                                setCurrTerm(res.data.studentData[0].current_term)
                                setCurrGrade(res.data.studentData[0].grade)
                                getMoreSubject(res.data.studentData[0].grade);

                                 setMSData(res.data.studentData[0]);
                                    let udata = res.data.studentData[0];

                                 setLNames(udata.names);
                                 setLDob(udata.dob);
                                 setLGender(udata.gender);
                                 setLRace(udata.race);
                                 setLAddress(udata.address);
                                 setLRegion(udata.region);
                                 setLHomelang(udata.home_lang);
                                 setLOtherLang(udata.other_lang);
                                 setLGrade(udata.grade)


                                    setAllRegion(res.data.region)
        
                                if(res.data.inStatus === 'ava'){
                                        setCheckInvoice(handleErrs('info','You have an unpaid invoice. Invoice need to be cleared before student account can be active','message'))
                                }else{
                                    setCheckInvoice(null)
                                }
                         
                                setGrade(res.data.grade)
                                if(res.data.studentData[0].profile_status === 'Active'){
                                    setProfileStatus(true)
                                }else
                                {
                                    setProfileStatus(false)
                                }
                               
                       }else
                       {
        
                                    setStudentData(false);
                       }
                })
        }

             getStudent();

        },[remSub])
  return (
    <div>

        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          { loadBut === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

                <div className="wrapper">
                    <span className="loader"></span>
                    <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

                    <p style={{marginLeft: -30, marginTop: 120}}>Processing your request. Please wait...</p>
                </div>

                </div>
            }

          
            { studentData === false ? handleErrs(null,'Oops! unable to get student data','not')
                    : viewStudentData() }
          

        </div>
        <FootNav />
        </div>
     </div>
    </div>
  )
}

export default LearnerProfile