import React, {useState} from 'react'
import { Link, useHistory} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import '../../App.css';
import ParentReg from './ParentReg';
import TutorReg from './TutorReg';

function Login({checklogin, siteName, notify}) {
   let dates = new Date().getFullYear();

   const [loadDatas, setLoadDatas] = useState(false);
   const [optionForm, setOptionForm] = useState(false);
   const [userType, setUserType] = useState(null);
   const [currEmail, setCurrEmail] = useState();
   const [notis, setNotis] = useState(null);


   const validateUser = Yup.object().shape({
    email: Yup.string()
    .required('Email is required')
    .email('Email is invalid'),

    userPassword: Yup.string()
    .min(6,'Password must be at least 6 chacracter long')
    .required('Password is required')
});

const formOption = { resolver: yupResolver(validateUser)};

const {register, handleSubmit, formState } = useForm(formOption);
const {errors} = formState;

const submitForm = async (data) =>{
          setLoadDatas(true)
     await axios.post('/authenticate-users',data,{
        headers: { 'Content-Type': 'application/json'},
      }).then( async (response)=>{ 

                  setTimeout(()=>{

                    setLoadDatas(false)

                    if(response.data.status === 'allow'){
                     window.location = "/"
                    }
                    else
                     if(response.data.status === 'Pending'){
                       setCurrEmail(response.data.logEmail)
                       setOptionForm(true)
                     }else{
                       let noti = notify('danger',response.data.status);
                       setNotis(noti)
                     }

                  },1000)
             
                 
               
             
            
      })
}


 const loginView = () => {
      return(
        <div className="account-pages my-5 pt-sm-5">
           { loadDatas === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

                <div className="wrapper">
                    <span className="loader"></span>
                    <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

                    <p style={{marginLeft: -30, marginTop: 120}}>Processing your request. Please wait...</p>
                </div>

                </div>
            }
        <div className="container">
          <div className="row justify-content-center">
          
            <div className="col-md-8 col-lg-6 col-xl-5" style={{ marginTop:-50 }}>
                      
                       <div className='row mb-3'>
                        <div className='col-12 text-center'>
                          <img src='/logo.png' style={{ height:'80px' }} />
                        </div>
                       </div>

              <div className="card overflow-hidden">
                
                <div className="">
                  <div className="row">
                    <div className="col-12">
                         
                      <div className="text-dark p-4 text-center">
                        <h3 className="text-dark" style={{ fontWeight:'bolder' }}>Sign In</h3>
                        <p className="text-dark">Welcome back! Sign in to your {siteName} account</p>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div className="card-body pt-0"> 
                
                  <div className="p-2">
                  <form className="form-horizontal" onSubmit={handleSubmit(submitForm)} >
                      { notis === null ? null : notis}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Enter Email</label>
                    <input type="text" name="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} 
                    id="email" placeholder="" {...register('email')}  />
                     <div className="invalid-feedback">{errors.email?.message}</div>
                    
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Enter Password</label>
                    <div className="input-group auth-pass-inputgroup">
                      <input type="password" name="userPassword" className={`form-control ${errors.userPassword ? 'is-invalid' : ''}`}
                      placeholder="" aria-label="Password" aria-describedby="password-addon" {...register('userPassword')}   />
                      <div className="invalid-feedback">{errors.userPassword?.message}</div>
                     </div>
                    

                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="remember-check" />
                    <label className="form-check-label" htmlFor="remember-check">
                      Remember me
                    </label>
                  </div>
                  <div className="mt-3 d-grid">
                   
                  
                    <button className="btn btn-success waves-effect waves-light" type="submit">

                      Log In</button>
                     

                  </div>
                  <div className="mt-4 text-center">
                  
                  </div>
                  <div className="mt-4 text-center">
                    <Link to="/forgot" className="text-muted"><i className="mdi mdi-lock me-1" /> Forgot your password?</Link>
                  </div>
                </form>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <div>
                  <p>Don't have an account ? <Link to="/register" className="fw-medium text-primary"> Register now </Link> </p>
                  <p>© { dates}  Horion Education (Pty) Ltd</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
 }

 const accountOption = () =>{

            
     let mychild = {myOption:userType, logEmail:currEmail, companyName:"Horion", notify:notify}
     
     switch(userType){
        case 'parent':
           return (
             <div>
               <ParentReg mychilds={mychild} changeOption={selectUserOption} />
             </div>
           )
       
          break;
          case 'tutor':
            return (
              <div>
            <TutorReg  changeOption={selectUserOption} mychilds={mychild} />
            </div>
            )
         break;
            default:

            return (
              <div className="account-pages my-5 pt-sm-5 App">
              <div className="container">


                <div className="row justify-content-center">


            


                <div className='col-lg-12 mb-3'>
                  <div className='text-center'>
                  <img src='/logo192.png' style={{ height:'100px' }} />
                  <p className='mt-2 font-size-18 bolder'>Kindly Select the type of account you wish to continue with</p>
                  </div>
                 
                </div>

                 <div className="col-lg-6 mb-3">
                 <div className="card">
                                    <img className="card-img img-fluid" src="assets/images/pat.jpg" alt="Card image" style={{ borderRadius:5 }} />
                                    <div className="card-img-overlay">
                                    <div className='text-center' style={{ marginTop:140 }}>
                                    <button type="button" onClick={()=> setUserType('parent')} className="btn btn-light waves-effect waves-light btn-rounded">  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Register as a Parent</button>
                                    </div>
                                   
                                    </div>
                            </div>
                 </div>

            <div className="col-lg-6  mb-3" style={{ borderRadius:5 }}>
                <div className="card" >
                                    <img className="card-img img-fluid" src="assets/images/tut.jpeg" alt="Card image" style={{ borderRadius:5 }} />
                                    <div className="card-img-overlay">
                                      
                                            <div className='text-center' style={{ marginTop:130 }}>
                                            <button type="button" onClick={()=> setUserType('tutor')} className="btn btn-light waves-effect waves-light text-end btn-rounded">  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Register as a Tutor</button>
                           
                                            </div>
                                    </div>
                            </div>
            </div>
    
         

            <div>
              <hr />
            <h6>© { dates}  {siteName}</h6>
        
        </div>
        </div>
        </div>
             </div>
             )
       }

    
 }


 const selectUserOption = (type) =>{
  setUserType(type)
}


   return (
          <div>
            { optionForm === false ? loginView() : accountOption() }
          </div>
    
    )
}

export default Login