import React,{useEffect, useState } from 'react'
import axios from 'axios';
import ErrorMessage from '../userAuth/ErrorMessage';
import { useHistory } from 'react-router-dom';
import FootNav from '../Nav/FootNav';

function TutorDashBoard() {

    const [cents, setCents] = useState(null);
    const [loadMe, setLoadMe] = useState(false);
    const [totalWithdraw, setTotalWithdraw] = useState();
    const [wallet, setWallet] = useState();
    const [uData, setUData] = useState();
    const [uType, setUType] = useState();
    const history = useHistory();


    const handleErrs = ($type,$msg,$uitype) =>{
        let errorChild = {types:$type, msg:$msg, uiType:$uitype}
      return (
        <div>
        <ErrorMessage errors={errorChild} />
        </div>
      
      )
    }



    const displayCountDatas = () =>{
                let ms, tot = totalWithdraw.length
           if(cents === null){
                    ms=0
           }else{
                  ms = cents.length
           }

            
         return(
            <>
                         <div className="col-sm-6">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="media">
                                                    <div className="me-3 align-self-center">
                                                        <i className="mdi mdi-clipboard-list-outline h2 text-warning mb-0"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <p className="text-muted mb-2">Total Centre</p>
                                                        <h5 className="mb-0">{ms > 1 ? tot+' Centres' : ms+' Centre' }</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div className="col-sm-6">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="media">
                                                    <div className="me-3 align-self-center">
                                                       <h2 className='text-info'>R</h2>
                                                    </div>
                                                    <div className="media-body">
                                                        <p className="text-muted mb-2">Total Withdrawal</p>
                                                        <h5 className="mb-0">{tot > 1 ? tot+' Withdrawals' : tot+' Withdrawal' }</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            </>
         )
    }


    const displayFewCentres = () =>{
          return (
              <>
                <div className="table-responsive">
                                  <h4 className='font-size-14' style={{textTransform:'uppercase' }}>Centres</h4>
                                  <hr className='text-danger' style={{ marginTop:-5 }}/>
                                 { cents === null ? <div className='text-center'>
                                            { handleErrs(null,'You have no registered centre','not')}
      <button type="button" onClick={() => history.push('/centres?add=centre')} className="btn btn-primary w-xl waves-effect waves-light" id="create-report"> Add Centre </button>
               

                                 </div> : 
                                <table className="table project-list-table table-nowrap align-middle table-borderless">
                                           <thead>
                                                <tr>
                                                    <th scope="col" style={{width: 100}}>#</th>
                                                    <th scope="col">Names</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Region</th>
                                                    <th scope="col">Date Added</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            { cents && cents.map((x, index)=>{
                                                 return (
                                                    <tr>
                                                    <td>{ 1 + index}  </td>
                                                    <td>
                                                        <h5 className="text-truncate font-size-14 text-dark">{ x.centre_name }</h5>
                                                       
                                                    </td>
                                                    <td className='text-center'>
                           { x.status === 'Active' ? <div className='d-flex'> { handleErrs('Active',null,'profile-status') } Active </div>: <div className='d-flex'>{ handleErrs('Not Active',null,'profile-status')}  Not Active</div> }
                        </td>
                                                    <td>{ x.region }</td>
                                                    <td>
                                                      { x.date_reg }
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                        <button className="btn btn-dark btn-sm" onClick={() => history.push('/centres/'+x.centre_id) }>View Centre <i className="mdi mdi-arrow-right ms-1"></i></button>
                                      
                                                           
                                                        </div>
                                                    </td>
                                                    </tr>
                                                 )
                                            })}
                                           
                                            </tbody>
                                  </table> }

                                  </div>
              </>
          )
    }

    const displayUserIdentity = () => {
                          let wall
                    if(wallet === null || wallet === ''){
                                wall = '0.00';
                    }else
                    {
                                 wall = wallet
                    }
                    return(
                        <>
                         <div className="card">
                                    <div className="card-body">
                                        
                                        <div className="media">
                                            <div className="me-4">
                                                <i className="mdi mdi-account-circle text-primary h1"></i>
                                            </div>

                                            <div className="media-body">
                                                <div className="text-muted">
                                                    <h5>{ uData[0].names }</h5>
                                                    <p className="mb-1">{ uData[0].email }</p>
                                                    <p className="mb-0">{ uType+' ID: '+uData[0].userid}</p>
                                                </div>
                                                
                                            </div>

                                            <div className="dropdown ms-2">
                                               
                                             
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body border-top">
                                        
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div>
                                            
                                                    <p className="text-muted mb-2">
                                                    <i className="bx bx-wallet"></i>  Wallet Balance </p>
                                                    <h5>R { wall }</h5>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                               
                                            </div>
                                        </div>
                                    </div>

                                  
                                </div>
                        </>
                    )
    }

  const tutorData = () => {
            setLoadMe(false)
    axios.get('/checklogin/status',{
      headers: { 'Content-Type': 'application/json'},
     }).then((res) =>{
              setTimeout(function(){
                  setLoadMe(true)
                  setTotalWithdraw(res.data.tanstot);
                   setWallet(res.data.wallet[0].balance);
                   setUData(res.data.userData);
                   setUType(res.data.userType);

                if(res.data.fewCent !== 'empty'){
                    setCents(res.data.fewCent)
                    }else{
                        setCents(null)
                    }
              },1000)
         
     })

   }

useEffect(() => {

  tutorData();

},[]);

  return (
 
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
      { loadMe === false ?
             <div id="preloader">
             <div id="status">
                 <div className="spinner-chase">
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                 </div>
             </div>
         </div> : null }

        { loadMe === false ? null :
        <div className='row'>

                     <div className="col-xl-4">

                               { displayUserIdentity() }

                         </div>

                         <div className="col-xl-8">
                         <div className="row">

                                 { displayCountDatas() }
                                </div>
                            
                              <div className='col-md-12'>

                                { displayFewCentres() }

                                  </div>

                          </div>
       
                </div> }

                <FootNav />
        </div>
      </div>
   </div>


  )
}

export default TutorDashBoard