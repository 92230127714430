import React, { useState } from 'react'
import { Link} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import '../../App.css';
import ParentReg from './ParentReg';
import TutorReg from './TutorReg';

function Register ({siteName,notify}) {
     const [alertUser, setAlertUser] = useState(null);
     const [loadBut, setLoadBut] = useState(false);
     const [loadUserOption, setLoadUserOption] = useState(false);
     const [userType, setUserType] = useState(null);
     const [currEmail, setCurrEmail] = useState();

  let dates = new Date().getFullYear();

    const schema = Yup.object().shape({
          email: Yup.string().required('Email is require')
          .email('Please provide a valid email address'),
          password: Yup.string()
          .min(6, 'Password must be at least 6 characters')
         .max(8,'Password must not be more than 8 characters long')
         .required('Please provide your password'),
         confirmPassword:Yup.string()
         .oneOf([Yup.ref('password'), null], 'Passwords must match')
         .required('Confirm Password is required'),
         tandc:Yup.boolean().oneOf([true],'You must accept the terms and conditions')
    })


    const formOptions = { resolver: yupResolver(schema)};

    const {register, handleSubmit, formState } = useForm(formOptions);
    const {errors} = formState;

    const disableBtnProps = {};

         if(loadBut === true){
          disableBtnProps.disabled = true;
         }else{
         
          disableBtnProps.disabled = false;
         }


         
    

  const userOption = () =>{

     let mychild = {myOption:userType, logEmail:currEmail, companyName:"Horion", notify:notify}
     
     switch(userType){
        case 'parent':
           return (
             <div>
               <ParentReg mychilds={mychild} changeOption={selectUserOption} />
             </div>
           )
       
          break;
          case 'tutor':
            return (
              <div>
            <TutorReg  changeOption={selectUserOption} mychilds={mychild} />
            </div>
            )
         break;
          default:
            return (

              <div className="account-pages my-5 pt-sm-5 App">
              <div className="container">


                <div className="row justify-content-center">


            


                <div className='col-lg-12 mb-3'>
                  <div className='text-center'>
                  <img src='/logo.png' style={{ height:'80px' }} />
                  <p className='mt-2 font-size-18 bolder'>Kindly Select the type of account you wish to continue with</p>
                  </div>
                 
                </div>

                 <div className="col-lg-6 mb-3">
                 <div className="card">
                                    <img className="card-img img-fluid" src="assets/images/pat.jpg" alt="Card image" style={{ borderRadius:5 }} />
                                    <div className="card-img-overlay">
                                    <div className='text-center' style={{ marginTop:140 }}>
                                            <button type="button" onClick={()=> setUserType('parent')} className="btn btn-light waves-effect waves-light btn-rounded"> <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Register as a  Parent</button>
                                    </div>
                                   
                                    </div>
                            </div>
                 </div>

            <div className="col-lg-6  mb-3" style={{ borderRadius:5 }}>
                <div className="card" >
                                    <img className="card-img img-fluid" src="assets/images/tut.jpeg" alt="Card image" style={{ borderRadius:5 }} />
                                    <div className="card-img-overlay">
                                      
                                            <div className='text-center' style={{ marginTop:130 }}>
                                            <button type="button" onClick={()=> setUserType('tutor')} className="btn btn-light waves-effect waves-light btn-rounded">  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Register as a Tutor</button>
                           
                                            </div>
                                    </div>
                            </div>
            </div>
    
         

            <div>
              <hr />
            <h6>© { dates}  {siteName}</h6>
        
        </div>
        </div>
        </div>
             </div>
         
             
         )

     }

   

  }



  const firstRegisterBody = () =>{
     return(
      <div className="account-pages my-5 pt-sm-5 App">
      <div className="container">
        <div className="row justify-content-center" style={{ marginTop:-70 }}>
 
    <div className="col-md-8 col-lg-6 col-xl-5">

                   <div className='row mb-3'>
                        <div className='col-12 text-center'>
                          <img src='/logo.png' style={{ height:'80px' }} />
                        </div>
                       </div>
    
    <div className="card overflow-hidden">
      <div className="">
        <div className="row">
          <div className="col-12">
                
            <div className=" p-4 text-center">
            <h3 className="text-dark" style={{ fontWeight:'bolder' }}>Sign Up</h3>
            <p className="text-dark">Welcome! Join us by creating a free account</p>
            </div>
          </div>
         
        </div>
      </div>



      <div className="card-body pt-0"> 
     
        <div className="p-2">
           { alertUser === null ? null : alertUser }
        <form className="needs-validation" noValidate onSubmit={ handleSubmit(handleRegistration) }>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" name="email" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} 
               id="useremail" placeholder="Email"  required />
                 <div className="invalid-feedback">{errors.email?.message}</div>

            </div>
            <div className="mb-3">
              <label className="form-label">Password</label>
              <div className="input-group auth-pass-inputgroup">
                <input type="password" name="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="userpassword"
               placeholder="Password"   required /> 
                <div className="invalid-feedback">{errors.password?.message}</div>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="userpassword" className="form-label">Confirm Password</label>
              <input type="password" name="" {...register('confirmPassword')} className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
               id="userpassword" placeholder="Confirm password"    required />
                <div className="invalid-feedback">{errors.confirmPassword?.message}</div>

            </div>

            <div className="form-check">
              <input className={`form-check-input ${errors.tandc ? 'is-invalid' : ''}`} name="tandc" {...register('tandc')} type="checkbox" id="tandc" />
              <label className="form-check-label" htmlFor="remember-check">
                I agree with the terms and conditions of { siteName }
              </label>
              <div className="invalid-feedback">{errors.tandc?.message}</div>
            </div>
            <div className="mt-3 d-grid">
              <button className="btn btn-success waves-effect waves-light" id='loadbut' type="submit" {...disableBtnProps}>
                 { loadBut === true ? <div className="spinner-border text-white" role="status">
                                      <span className="sr-only">Loading...</span>
                                  </div>: 'Sign Up'}
              </button>
            </div>
           
         
          </form> 
        </div>
      </div>
    </div>
    <div className="mt-5 text-center">
      <div>
        <p>Already have an account ? <Link to="/login" className="fw-medium text-primary"> Login now </Link> </p>
        <p>© { dates}  Horion Education (Pty) Ltd</p>
      </div>
    </div>
  </div>
  </div>
        </div>
      </div>
     )

  }

  const handleRegistration = (data) => {
           
          setAlertUser(null)
              setLoadBut(true)
            
          axios.post('/register-user',data,{
          headers: { 'Content-Type': 'application/json'},
         }).then((response) =>{
                  setTimeout(()=>{
                     setLoadBut(false)
                    if(response.data.userStatus === 'account-option'){
                        setLoadUserOption(true)
                        setCurrEmail(response.data.uemail)
                       
                    }else{
                      
                         let noti = notify('danger',response.data.userStatus);
                           setAlertUser(noti);  
                    }
                  },1000)
              
         });
     
       

  }

  const selectUserOption = (type) =>{
        setUserType(type)
  }

  return (
          <div>
            { loadUserOption !== false ?
            
            
            userOption()
            
            : 
            
            firstRegisterBody()
            
            }

      </div>
  )
}

export default Register