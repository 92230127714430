import React,{useState, useEffect} from 'react'
import ErrorMessage from '../../userAuth/ErrorMessage'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import FootNav from '../../Nav/FootNav'

function AccessCapture() {
    
      const [loadDatas, setLoadDatas] = useState(false)
      const [years, setYears] = useState();
      const [centData, setCentData] = useState(null)
      const [loadfull, setLoadFull] = useState(false)
      const [yr, setYr] = useState();
      const [curGrade, setCurGrade] = useState('');
      const [grade, setGrade] = useState();
      const [loadMe, setLoadMe] = useState(false);
      const [cid, setCid] = useState();
      const [stud, setStud] = useState(null);
      const [subject, setSubject] = useState(null);
      const [term, setTerm] = useState();
      const [downloadStatus, setDownloadStatus] = useState(null);
      const [displayDown, setDisplayDown] = useState(false);
      const [studID, setStudID] = useState();
      const [notify, setNotify] = useState(null);
      const [capData, setCapData] = useState(null);
      const [scores, setScore] = useState(null);
      const [bnotify, setBNotify] = useState(null);
      const [cate, setCate] = useState('');
      const [assCate, setAssCate] = useState([]);

      const id = cate;
    
      


      const handleErrs = ($type,$msg,$uitype) =>{
        let errorChild = {types:$type, msg:$msg, uiType:$uitype}
      return (
        <div>
        <ErrorMessage errors={errorChild} />
        </div>
      
      )
    }



const ACat = (datas,tm) =>{
    let ms;
     switch(tm){
        case "1":
          ms = datas.term1
        break;
        case "2":
          ms = datas.term2
        break;
        case "3":
          ms = datas.term3
          break;
        case "4":
          ms = datas.term4
          break;
     }
  
              let sd = ms.split(',');
             setAssCate(sd);
  
  }
  
  
  const getAssessmentCate = (tm) => {
      setTerm(tm)
      setLoadMe(true)
    if(!tm.trim()){}else{
    axios.post('/get-assess-cate',{tm},{
       headers:{'Content-Type' : 'application/json'}
    }).then((res)=>{
  
          setTimeout(()=>{
            setLoadMe(false)
            if(res.data.termData === 'empty'){
              setAssCate([]);
            }else
            {
                 ACat(res.data.termData[0],tm)
      
            }
          },1000)
  
        
    }).catch((err)=>{
       console.log(err)
    })
  }
  }
  


const getScoreRating = (type,score) =>{

    let rate;
   if(type === 'percent'){
         
       switch(score){
           case '7':
                 rate = '80% - 100%'
             break;
           case '6':
             rate = '70% - 79%'
             break;
           case '5':
             rate = '60% - 69%'
             break;
            case '4':
             rate = '50% - 59%'
            break;
            case '3':
             rate = '40% - 49%'
             break;
             case '2':
               rate = '30% - 39%'
               break;
            case '1':
             rate = '0% - 29%'
              break;
              default:
               rate = '-'
       }
   
      
   }else{
   switch(score){
     case '7':
           rate = 'A+'
       break;
     case '6':
       rate = 'A'
       break;
     case '5':
       rate = 'B'
       break;
      case '4':
       rate = 'C'
      break;
      case '3':
       rate = 'D'
       break;
       case '2':
         rate = 'E'
         break;
      case '1':
       rate = 'F'
        break;
        default:
         rate = '-'
   }
   
   
   }
   
   return rate;
   
   }
   
  


    const handleSubject = (tm) => {
        if(tm ===''){
            setSubject(null)
        }else{
            setLoadMe(true);
            setCate(tm)
        axios.get('/api-fetchgrade-termsubject/'+curGrade+'/'+term,{
            headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                    setTimeout(()=>{
                         setLoadMe(false)
                        if(res.data.subjectData !== 'empty'){
                            setSubject(res.data.subjectData)   
                      }else{
                          setSubject(null)
                      }
                    },1000)
             
        }).catch((err)=>{
            console.log(err)
        })
    }
}


const controlDownload = (e) =>{
    let subj = e.target.value;
   
        if(!subj.trim()){}else{
        setLoadFull(true)
        setDisplayDown(false)
       axios.get('/fetchdata-capture/'+curGrade+'/'+subj+'/'+term+'/'+yr+'/'+id+'/'+studID,{
           headers:{'Content-Type':'application/json'}
       }).then((res)=>{
              setTimeout(()=>{
                    setLoadFull(false);
                    setDisplayDown(true)

                   

                    if(res.data.status === 'not-allow'){
                        
                        setBNotify(handleErrs(res.data.color,res.data.message,'message'));
                                
                        }else
                        {

                            if(res.data.dData !== 'empty'){
                                setDownloadStatus(res.data.dData[0])
                            }else{
                                setDownloadStatus(null)
                            }
                
                            if(res.data.scoreData !== 'empty'){
                            setCapData(res.data.scoreData[0])
                            }else
                            {
                                setCapData(null)
                            } 
                            
                        
                        }

                      
              },1000)
       }).catch(err =>{
             console.log(err)
       })
    }
}


const handleCapture = (type) =>{
       
    if(scores === '' || scores === null){
      setNotify(handleErrs('danger','Please kindly enter the score for this subject','message'))
    }else{
      setLoadMe(true)
      setNotify(null)
  let datas = { score:scores, stud_id:studID, did:downloadStatus.did, type:type}
  axios.post('/api-capturedata-learner',datas,{
      headers:{'Content-Type':'application/json'}
  }).then((res)=>{
        setTimeout(()=>{
          setLoadMe(false)
           if(res.data.status === 'ok'){
                 setNotify(handleErrs('success',res.data.message,'message'))
           }else{
            setNotify(handleErrs('danger',res.data.message,'message'))
           }
        },1000)
  }).catch(err =>{
    setNotify(handleErrs('danger','Server error occur. Please be patient while we restore the server','message'))
    console.log(err)
  })
}
}



const handleDownloadData = () =>{

    return(
      <div className="col-lg-12">
       { downloadStatus !== null ?
 
        <div className="table-responsive">
               <table className="table project-list-table table-nowrap align-middle table-borderless">
                                      <thead>
                                                 <tr>
                                                     <th scope="col" style={{ width: 100 }}>#</th>
                                                     <th scope="col">Subject Name</th>
                                                     <th scope="col">Grade</th>
                                                     <th scope="col">Assessment</th>
                                                     <th scope="col">Rate Learner</th>
                                                     <th scope="col">Percentage</th>
                                                     <th scope="col">Symbol</th>
                                                     <th scope="col">Action</th>
                                                 </tr>
                                             </thead>
 
                                             <tbody>
                                                 <tr>
                                                     <td>
                                                       
                                                         1
                                                     
                                                     </td>
                                                     <td>
                                                         <h5 className="text-truncate font-size-14 text-dark">{ downloadStatus.subject_name }</h5>
                                                        
                                                     </td>
                                                     <td>{ downloadStatus.grade }</td>
                                                     <td className='text-dark' style={{ textTransform:'capitalize' }}>{ id }</td>
                                                    
                                                     <td style={{ fontWeight:'bolder' }}>
                                                        { scores !== null ? scores : capData !== null ? capData.score : <h4> - </h4> }
                                                     </td>
                                                     <td>
                                                      { capData === null ? getScoreRating('percent',scores) : getScoreRating('percent',capData.score)}
                                                   </td>
                                                   <td style={{ fontWeight:'bolder' }}>
                                                       { capData === null ?  getScoreRating(null,scores) : getScoreRating(null,capData.score) }
                                                   </td>
                                                     <td>
                                                         <div className="dropdown">
                                                            
                                                         { capData !== null ? 
                                                           
 
                                                             <button className="btn btn-success btn-sm btn-rounded" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                               Update Capture
                                                             </button>
                                                      
                                                         
                                                         :  <button className="btn btn-success btn-sm btn-rounded" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                          Capture Learner
                                                       </button> }
                                                            
                                                         
                                                          
                                                         </div>
                                                     </td>
                                                 </tr>
                                                 </tbody>
 
 
               </table>
 
                                             <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                 <div className="modal-dialog modal-dialog-centered" role="document">
                                                     <div className="modal-content">
                                                         <div className="modal-header">
                                                             <h5 className="modal-title" id="staticBackdropLabel">Capture Score</h5>
                                                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                         </div>
                                                         <div className="modal-body">
                                                         <div className="mb-3">
                                                            { notify === null ? null : notify }
                                                           <label for="formrow-firstname-input" className="form-label">Capture</label>
                                                           <select className='form-select' onChange={(e)=> setScore(e.target.value)}>
                                                             {
                                                               capData !== null ? 
                                                               <>
                                                               <option value={capData.score}>{capData.score}</option>
                                                               </>
                                                               :   <>
                                                               <option value={''}>Choose...</option>
                                                               </> 
                                                             }
                                                            
                                                            <option value={'1'}>1, { getScoreRating('percent','1') },  { getScoreRating('null','1') }</option>
                                                            <option value={'2'}>2, { getScoreRating('percent','2') },  { getScoreRating('null','2') }</option>
                                                            <option value={'3'}>3, { getScoreRating('percent','3') },  { getScoreRating('null','3') }</option>
                                                            <option value={'4'}>4, { getScoreRating('percent','4') },  { getScoreRating('null','4') }</option>
                                                            <option value={'5'}>5, { getScoreRating('percent','5') },  { getScoreRating('null','5') }</option>
                                                            <option value={'6'}>6, { getScoreRating('percent','6') },  { getScoreRating('null','6') }</option>
                                                            <option value={'7'}>7, { getScoreRating('percent','7') },  { getScoreRating('null','7') }</option>
                                                           </select>
                                                        
                                                         </div>
                                                              { loadMe === true ? <div className='mt-2 text-center'> {handleErrs('danger',null,'load') } </div> : null }
                                                         </div>
                                                         <div className="modal-footer">
                                                             <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                              
                                                             { loadMe === true ?  <button type="button" className="btn btn-primary" disabled>Please wait...</button> : capData !== null ?  <button type="button" className="btn btn-primary" onClick={()=> handleCapture('update')}>Update</button> : 
                                                              <button type="button" className="btn btn-primary" onClick={()=> handleCapture('none')}>Submit</button>
                                                             }
                                                            
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
 
        </div> : 
           
        bnotify === null ? handleErrs('danger','Oops! Unable to get details for the selected subject 20202','message') :
         bnotify
     }

   
 
 
        </div>
    )
 
 }
 


      const getCentreStudent = (grd) => {
                        setCurGrade(grd)
                        setLoadMe(true)
                        if(grd === ''){}else{
                axios.get('/api-fetchcentrestud/'+grd+'/'+cid,{
                     headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                            setTimeout(()=>{
                                 setLoadMe(false)
                                if(res.data.learn !== 'empty'){
                                    setStud(res.data.learn);
                               }else
                               {
                                    setStud(null)
                               }
                            },1000)
                     
                }).catch(err=>{
                     console.log(err)
                })
            }
      }


      const handleCentre = (cid) => {
        if(cid !== ''){
        setLoadMe(true)
        setCid(cid)
        axios.get('/api-list-currentgrade/'+cid+'/Tutor',{
             headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                    setTimeout(()=>{
                        setLoadMe(false)
                      
                        if(res.data.gradeData !== 'empty'){
                            setGrade(res.data.gradeData)
                       }else{
                            setGrade(null)
                       }
                    },1000)
             
        }).catch((err)=>{
              console.log(err)
        })
    }else{ setGrade(null)}
}

      


      const displayCaptureForm = () => {

         return(
                        <div className='row'>
                            <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-body row'>
                                <h3 className='card-title mb-4'>Capture {id} </h3>
                                <hr style={{ marginTop:-19, color:'red' }}/>

                            <div className="col-sm-auto col-md-2 mt-2">
                            <label className="" htmlFor="autoSizingSelect">Year</label>
                            <select className='form-select' onChange={(e)=>setYr(e.target.value)}>
                            <option value={''}>Choose...</option>
                            { years && years.map((y)=>{
                                  return (
                                    <option key={y.id} value={y.year}>{y.year}</option>
                                  )
                            })}
                        </select>
                            </div>

                            <div className="col-sm-auto col-md-2 mt-2">
                            <label className="" htmlFor="autoSizingSelect">Centres</label>
                            <select className='form-select' onChange={(e)=>handleCentre(e.target.value)}>
                            <option value={''}>Choose...</option>
                             { centData !== null ? centData && centData.map((c)=>{
                                  return(
                                      <option key={c.id} value={c.centre_id}>{c.centre_name}</option>
                                  )

                             }) : <option value={''} disabled>Not Available</option>}
                        </select>
                            </div>

                            <div className="col-sm-auto col-md-2 mt-2">
                            <label className="" htmlFor="autoSizingSelect">Grade</label>
                            <select className='form-select' onChange={(e) => getCentreStudent(e.target.value)}>
                            <option value={''}>Choose...</option>
                             { grade === null ? <option value={''} disabled>Not Available</option> :
                              grade && grade.map((g)=>{
                                   return (
                                      <option key={g.id} value={g.grade}>{'Grade '+g.grade}</option>
                                   )
                              })
                             }
                        </select>
                            </div>

                            <div className="col-sm-auto col-md-2 mt-2">
                            <label className="" htmlFor="autoSizingSelect">Learner</label>
                             <select className='form-select' onChange={(e)=> setStudID(e.target.value)}>
                                <option value={''}>Choose...</option>
                                { stud === null ? <option disabled>Not Available</option>
                                : stud && stud.map((x)=>{
                                     return(
                                        <option value={x.stud_userid}>{x.names}</option>
                                     )
                                })}
                             </select>
                            </div>

                            <div className="col-sm-auto col-md-2 mt-2">
                            <label className="" htmlFor="autoSizingSelect">Term</label>
                             <select className='form-select' onChange={(e)=> getAssessmentCate(e.target.value)} >
                                <option value={''}>Choose...</option>
                            { curGrade === '' ? <option disabled>Not Available</option> : <>
                            <option value={1}>Term 1</option>
                                <option value={2}>Term 2</option>
                                <option value={3}>Term 3</option>
                                <option value={4}>Term 4</option>
                            </>   }
                             </select>
                            </div>


                            <div className="col-sm-auto col-md-2 mt-2">
                  <label className="" htmlFor="autoSizingSelect">Assessment Category</label>
                   
                  <select className="form-select form-control"  onChange={(e)=> handleSubject(e.target.value)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Choose </option>
                  { assCate.length > 0 ? assCate && assCate.map((c,i)=>{
                                                            return(
                                                              <option key={i} value={c}>{c}</option>
                                                            )
                                                     }) : <option disabled>Not available</option> }
                                                  
                      
                  </select>
                </div>

                            <div className="col-sm-auto col-md-2 mt-4">
                            <label className="" htmlFor="autoSizingSelect">Subject</label>
                             <select className='form-select' onChange={(e)=>controlDownload(e)}>
                                <option value={''}>Choose...</option>
                                { subject === null ? <option disabled>Not Available</option>
                            : subject && subject.map((s)=>{
                                    return(
                                          <option value={s.subject_id}>{ s.names }</option>
                                    )
                            })}
                             </select>
                            </div>


                            { loadMe === false ? null :
                            <div className='col-md-12 mt-4'>
                                <div className='text-center'>
                                        { handleErrs('success',null,'load') }
                                </div>
                            </div> }


                              </div>
                            </div>
                     </div>
                 </div>
                
           
         )

      }



      useEffect(()=>{
          try{
    
            axios.get('/api-centreaccademicyear-datas',{
                headers:{'Content-Type':'application/json'}
             }).then((res)=>{
              setTimeout(()=>{
                          
                            setLoadDatas(true)
                            setYears(res.data.accYear);
                         
                         
                          if(res.data.centreData !== 'empty'){
                                   setCentData(res.data.centreData);
                          }else
                          {
                               setCentData(null)
                          }
                         
                       },800)
             }).catch((err)=>{
                 console.log(err)
             })
    
          }catch(err){
                console.log(err)
          }
      
     },[id])

  return (
    <div className="main-content">
<div className="page-content">
  <div className="container-fluid">
  { loadDatas === false ?
      <div id="preloader">
             <div id="status">
                 <div className="spinner-chase">
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                 </div>
             </div>
         </div>  : null }

         { loadfull === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

          <div className="wrapper">
              <span className="loader"></span>
              <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

              <p style={{marginLeft: -30, marginTop: 120}}>Processing your request. Please wait...</p>
          </div>

          </div>
         }

      { displayCaptureForm() }

      { displayDown === false ? null : handleDownloadData()}

      <FootNav />
    </div>
 </div>
</div>
  )
}

export default AccessCapture