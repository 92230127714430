import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'

function HeaderNav() {
           
            const [uDatas, setUDatas] = useState();
            const [userType, setUserType] = useState();
            const [notis, setNotis] = useState([]);
            const history = useHistory();


  
    const logoutUsers = () =>{
             axios.get('/kill-session',{
                headers: { 'Content-Type': 'application/json'},
             }).then((response)=>{
                    if(response.data.status === 'gone'){
                         window.location = "/"
                    }
             }).catch(err =>{
                console.log(err)
             })
    }

    const getUsers = () =>{
           axios.get('/user-datas',{
            headers: { 'Content-Type': 'application/json'},
           }).then((response) =>{
                            let mds = response.data.users;
                              let sdc = mds.split(" ");
                 setUDatas(sdc[0])

                  setUserType(response.data.userType)
           }).catch(err=>{
             console.log(err)
           })
    }

      const tutorMenu = () => {
        return (
          <div>
          <div className="topnav">
              <div className="container-fluid">
                <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
                  <div className="collapse navbar-collapse" id="topnav-menu-content">
                    <ul className="navbar-nav">
                      <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle arrow-none" to="/" id="topnav-dashboard" role="button">
                          <i className="bx bx-home-circle me-2" /><span key="t-dashboards">Dashboard</span>
                        </Link>
                       
                      </li>
      

                    
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/centres" id="topnav-more" role="button">
                    <i className="bx bx-file me-2" /><span key="t-extra-pages">Centres</span> 
                  </Link>
               
                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/support" id="topnav-pages" role="button">
                    <i className="bx bx-customize me-2" /><span key="t-apps">Support</span>
                  </Link>
                </li>
      
           
                     <li className="nav-item dropdown">
                     <div className="nav-link dropdown-toggle arrow-none"  id="topnav-finance" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="bx bx-customize me-2"></i><span key="t-apps"> Horion Learning Portal </span> <div className="arrow-down"></div>
                                    </div>
                                    <div className="dropdown-menu" aria-labelledby="topnav-finance">

                                        <Link to="/hlp/assess-download" className="dropdown-item" key="t-calendar">Assessment Download</Link>
                                        <Link to="/hlp/assess-capture" className="dropdown-item" key="t-chat">Capture Assessment</Link>
                                        <Link to="/hlp/view-assessment" className="dropdown-item" key="t-chat">View Assessment</Link>
                                        <Link to="/hlp/terms-final" className="dropdown-item" key="t-chat">Terms & Final</Link>
                                       
                               
                                      
                                    </div>
                                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/wallet" id="topnav-more" role="button">
                    <i className="bx bx-file me-2" /><span key="t-extra-pages">My Wallet</span> 
                  </Link>
               
                </li>

                    
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
                    </div>
        )
      }

      const parentMenu = () => {
            return (
              <div>
    <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
            <div className="collapse navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/" id="topnav-dashboard" role="button">
                    <i className="bx bx-home-circle me-2" /><span key="t-dashboards">Dashboards</span>
                  </Link>
                 
                </li>


                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/learners" id="topnav-more" role="button">
                    <i className="bx bx-file me-2" /><span key="t-extra-pages">Learners</span> 
                  </Link>
               
                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/support" id="topnav-pages" role="button">
                    <i className="bx bx-customize me-2" /><span key="t-apps">Support</span>
                  </Link>
                </li>


                       <li className="nav-item dropdown">
                            <div className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-pages" role="button">
                                        <i className="bx bx-customize me-2"></i><span key="t-apps"> Horion Learning Portal </span> <div className="arrow-down"></div>
                                    </div>
                                      <div className="dropdown-menu" aria-labelledby="topnav-pages">

                                      <Link to="/hlp/assess-download" className="dropdown-item" key="t-calendar">Assessment Download</Link>
                                        <Link to="/hlp/assess-capture" className="dropdown-item" key="t-chat">Capture Assessment</Link>
                                        <Link to="/hlp/view-assessment" className="dropdown-item" key="t-chat">View Assessment</Link>
                                        <Link to="/hlp/terms-final" className="dropdown-item" key="t-chat">Terms & Final</Link>
                                      
                                    </div>
                                </li>





              <li className="nav-item dropdown">
              <div className="nav-link dropdown-toggle arrow-none" id="topnav-facilitator" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="bx bx-file me-2" /><span>Account</span> <div className="arrow-down" />
              </div>

              <div className="dropdown-menu" aria-labelledby="topnav-facilitator">
                
               

              <div className="dropdown">
                  <Link className="dropdown-item" to="/account/invoice">
                    <span>Invoice</span>
                  </Link>
                </div>



                <div className="dropdown">
                <Link className="dropdown-item" to="/account/wallet">
                    <span>Wallet</span>
                  </Link>
                 
                </div>
             
                
             
              </div>
            </li>

              

               

              
              </ul>
            </div>
          </nav>
        </div>
      </div>
              </div>
            )
      }

     const menuController = () =>{
              if(userType === 'Parent'){
                   return(
                      <div>
                       {parentMenu()}
                      </div>
                   ) 
              }else
              {
                  return (
                      <div>
                          {  tutorMenu() }
                      </div>
                  )
                   
              }
     }

     const getNotify = () => {
            axios.get('/all-notification',{
                headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                  let dap = res.data.notiData;
                   if(dap.length > 0){
                      setNotis(dap.length)
                   }else
                   {
                       setNotis(0)
                   }
            }).catch((err)=>{
                    console.log(err)
            })
     }

    useEffect(()=>{

            getNotify();
           getUsers()

        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });

    },[])
  return (
    <div>
          <div id="layout-wrapper">
          <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* LOGO */}
            <div className="navbar-brand-box">
              <a href="index.html" className="logo logo-dark">
                <span className="logo-sm">
                  <img src="/assets/images/logo.svg" alt="" height={22} />
                </span>
                <span className="logo-lg">
                  <img src="/assets/images/logo.png" alt="" height={17} />
                </span>
              </a>
              <a href="index.html" className="logo logo-light">
                <span className="logo-sm">
                  <img src="/assets/images/logo.png" alt="" height={40} />
                </span>
                <span className="logo-lg">
                  <img src="/assets/images/logo.png" alt="" height={70} />
                </span>
              </a>
            </div>
            <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
              <i className="fa fa-fw fa-bars" />
            </button>
          
          
         
          </div>
          <div className="d-flex">
           
         
          
            <div className="dropdown d-inline-block">
              <button type="button" className="btn header-item noti-icon waves-effect" onClick={()=>history.push('/notification')}>
                <i className="bx bx-bell bx-tada" />
                <span className="badge bg-danger rounded-pill">{ notis }</span>
              </button>
            
            </div>
            <div className="dropdown d-inline-block">
              <button type="button"  className="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               
              <img class="rounded-circle header-profile-user" src="/assets/images/avater.jpeg"
                                    alt="Header Avatar" />   
                <span className="d-none d-xl-inline-block ms-1" key="t-henry" >{ 'Hello! '+ uDatas }</span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
              
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                {/* item*/}
              
                <Link className="dropdown-item d-block" to="/settings"> <i className="bx bx-wrench font-size-16 align-middle me-1" /> <span key="t-settings">Settings</span></Link>

                <div className="dropdown-divider" />
                <div onClick={()=> logoutUsers()} style={{ cursor:'pointer' }} className="dropdown-item text-danger" to="/"><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" /> <span key="t-logout">Logout</span></div>
              </div>
            </div>
            <div className="dropdown d-inline-block">
             
            </div>
          </div>
        </div>
      </header>

        { menuController() }
  

          </div>
    </div>
  )
}

export default HeaderNav