import axios from 'axios';
import React, {useState, useEffect, useRef} from 'react'
import { useParams } from 'react-router-dom'
import {PDFExport, savePDF} from '@progress/kendo-react-pdf';
import ErrorMessage from '../userAuth/ErrorMessage';
import '../../App.css';

function InvoiceView() {
       const {vid} = useParams();
       const pdfExportResult = useRef(null);
       const [invoiceData, setInvoiceData] = useState(false)
       const [adminAcc, setAdminAcc] = useState();
       const [loadBut, setLoadBut] = useState(false);
       const [notify, setNotify] = useState(null);
       const [parentData, setParentData] = useState();
       const [payStatus, setPayStatus] = useState();
       const [pName, setPName] = useState();
       const [pEmail, setPEmail] = useState();
       const [pPhone, setPPhone] = useState();
       const [pAddress, setPAddress] = useState();

     
     
       const downloadInvoice = (type) =>{
            if(type === 'print'){
                window.print();
            }else{
              pdfExportResult.current.save();
            }
          
       }

       


       const handleErrs = ($type,$msg,$uitype) =>{
        let errorChild = {types:$type, msg:$msg, uiType:$uitype}
      return (
        <div>
        <ErrorMessage errors={errorChild} />
        </div>
      
      )
   }

    const ProcessPayment = () => {
           setLoadBut(true)
          axios.post('/api-process-payment',{amt:invoiceData.amount,invID:invoiceData.invoice_id},{
             headers: {'Content-Type':'application/json'}
          }).then((res)=>{
                setTimeout(()=>{
                  setLoadBut(false)
                  if(res.data.mstatus !== 'bad'){
                        setPayStatus('Paid')
                       setNotify(handleErrs('success',res.data.status,'message'));
                  }else{
                    setNotify(handleErrs('danger',res.data.status,'message'));
                    setPayStatus('Unpaid')
                  }
                },1000)
              
          }).catch(err=>{
            console.log(err)
         })
    }

     


       useEffect(()=>{

          axios.get('/checklogin/status',{
               headers:{'Content-Type':'application/json'}
          }).then((res)=>{
               
                setPName(res.data.userData[0].names)
                setPEmail(res.data.userData[0].email)
                setPPhone(res.data.userData[0].phone)
                setPAddress(res.data.userData[0].address)

          }).catch(err=>{
            console.log(err)
         })


           axios.get('/api-invoice-details/'+vid,{
            headers: {'Content-Type':'application/json'}
           }).then((res) => {
                if(res.data.invoiceData !== 'empty'){
                        setInvoiceData(res.data.invoiceData[0]);
                        setAdminAcc(res.data.adminAc[0])
                         
                }else
                {
                        setInvoiceData(false)
                }
           }).catch(err=>{
              console.log(err)
           })

       },[vid])
  return (
    <div>
                <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          { loadBut === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

                <div className="wrapper">
                    <span className="loader"></span>
                    <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

                    <p style={{marginLeft: -30, marginTop: 120}}>Processing your payment. Please wait...</p>
                </div>

                </div>
            }
                  
                    <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">

                                      { invoiceData !== false ?
                                        <div>
                                <PDFExport ref={pdfExportResult} margin="1cm" paperSize="auto">
                      
                                        <div className="invoice-title">
                                            <h4 className="float-end font-size-16">Order # { invoiceData.invoice_id }</h4>
                                            <div className="mb-2">
                                                <img src="/assets/images/logo1.png" alt="logo" height="50"/>
                                            </div>
                                        </div>

                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-6">
                                              <h5>Inssue to</h5>
                                              <address>
                                                { pName },<br />
                                                { pEmail},<br />
                                                { pPhone},<br />
                                                { pAddress }.
                                              </address>
                                           
                                                
                                            </div>
                                            <div className="col-sm-6 text-sm-end">
                                               { invoiceData.payment_status === 'Paid' ? 
                                                 <h1 className='text-success' style={{ fontWeight:'bolder' }}>PAID</h1> :
                                                 payStatus === 'Paid' ?  <h1 className='text-success' style={{ fontWeight:'bolder' }}>PAID</h1> :
                                                 <h1 className='text-danger' style={{ fontWeight:'bolder' }}>UNPAID</h1>
                                              }
                                              
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-6 mt-3">


                                            <address>
                                                    <strong>Date Created:</strong><br />
                                                    { invoiceData.date_created }<br /><br />
                                                </address>
                                              
                                            
                                            </div>
                                            <div className="col-sm-6 mt-3">

                                            <div className="card border border-success">
                                    <div className="card-header bg-transparent border-success">
                                        <h5 className="my-0 text-danger" style={{ fontSize:15 }}> To fund your HLP wallet, Do an EFT payment to the account details below</h5>
                                        <hr />
                                    </div>
                                  
                                    <div className="card-body">
                                       
                                           <address style={{ marginLeft:10 }}>
                                                    
                                                    <p><b>Bank Name: { adminAcc.bank_name }</b></p>
                                                    <p><b> Bank Branch Code: { adminAcc.branch_code }</b></p>
                                                    <p><b> Account Name: { adminAcc.account_name }</b></p>
                                                    <p><b> Account Number: { adminAcc.account_number }</b></p>
                                                    
                                                    </address>
                                    </div>
                                </div>



                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                 { notify !== null ? notify : null }
                                              </div>
                                        </div>
                                        <div className="py-2 mt-3">
                                            <h3 className="font-size-15 font-weight-bold">Order summary</h3>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th style={{width: 70}}>S/N</th>
                                                        <th>Purpose</th>
                                                        <th className="text-end">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>01</td>
                                                        <td>{ invoiceData.purpose }</td>
                                                        <td className="text-end">R { invoiceData.amount }</td>
                                                    </tr>
                                                    
                                                    
                                                    <tr>
                                                        <td colSpan="2" className="text-end">Sub Total</td>
                                                        <td className="text-end">R { invoiceData.amount }</td>
                                                    </tr>
                                                   
                                                    <tr>
                                                        <td colSpan="2" className="border-0 text-end">
                                                            <strong>Total</strong></td>
                                                        <td className="border-0 text-end"><h4 className="m-0">R { invoiceData.amount }</h4></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </PDFExport>
                                        <div className="d-print-none">
                                            <div className="float-end">
                                                <button onClick={()=>  downloadInvoice('print') } className="btn btn-success waves-effect waves-light me-1"><i className="fa fa-print"></i> Print</button>

                                                <button onClick={()=>  downloadInvoice('download') } className="btn btn-primary waves-effect waves-light me-1"><i className="fa fa-download"></i> Download</button>
                                                 
                                                  { invoiceData.payment_status === 'Paid' ? null :

                                               payStatus === 'Paid' ? null :<button onClick={()=> ProcessPayment()} className="btn btn-success w-md waves-effect waves-light">Pay Now</button>
                                                   }
                                            </div>
                                        </div>
                                        </div>      : 

                                          handleErrs(null,'Oops unable to get invoice details','not')
                                         
                                      }
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                      
            </div>
            </div>
          </div>
        </div>
  )
}

export default InvoiceView