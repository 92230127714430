import React, { useState, useEffect} from 'react'
import { Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import '../../App.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ErrorMessage from '../userAuth/ErrorMessage';
import SubjectAddHandler from '../userAuth/SubjectAddHandler';
import FootNav from '../Nav/FootNav';
import Pagination from '../General/Pagination';




function Learners() {

  let PageSize = 6;

      const schema = Yup.object().shape({
              fullname:Yup.string().required('Please provide student full-name'),
              gender:Yup.string().required('Gender is required'),
              dob:Yup.string().required('Date of birth is required'),
              home_lang:Yup.string().required("Home language is required"),
              other_lang:Yup.string().required('Please enter other language'),
              race:Yup.string().required('Student race is required'),
              idtype:Yup.string().required('Student identity type is required'),
              idNO:Yup.string().required('ID number is required'),
              region:Yup.string().required('Region is required'),
              home_address:Yup.string().required('Student address is required')


      });

        const formOptions = {resolver: yupResolver(schema)}
        const {register, handleSubmit, formState } = useForm(formOptions);
        const {errors} = formState;

     const [availableStud, setAvailableStud] = useState(false);
     const [addBtn, setAddBtn] = useState(false);
     const [identity, setIdentity] = useState(false);
     const [idType, setIdType] = useState();
     const [loadData, setLoadData] = useState(false);
     const [displayForm, setDisplayForm] = useState(false);
     const [notify, setNotify] = useState(null);
     const [loadProgress, setLoadProg] = useState(false);
     const [loadRegion, setLoadRegion] = useState(null);
     const [loadStudent, setLoadStudent] = useState([]);
     const [completeReg, setCompleteReg] = useState(false);
    
     const [grade, setGrade] = useState();
     const [selGrade, setSelGrade] = useState('');
     const [loading, setLoading] = useState(false);
     const [termcheck, setTermCheck] = useState();
  
     const [displayTerm, setDisplayTerm] = useState(false)
     const [selTerm, setSelTerm] = useState('');
     const [subScribe, setSubScribe] = useState('');
     const [loadunRegSub, setLoadUnRegSub] = useState(false);
     const [loadSubject, setLoadSubject] = useState(false);
     const [priceTerm, setTermPrice] = useState();
     const [priceYear, setPriceYear] = useState();
     const [cartItems, setCartItems] = useState([]);
     const [sid, setSid] = useState();
     const [currentPage, setCurrentPage] = useState(1);

   const handlePage = () => {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      return loadStudent.slice(firstPageIndex, lastPageIndex);
    };


    const currentTable  = handlePage();

     const history = useHistory();
   

     let ndate = new Date().getFullYear() + 6;
     let allYears = [];
  for(let i=2020; i < ndate; i++){
       allYears.push(i);
  }

  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


  const handleTerms = () =>{
               
    setNotify(null)
    if(selGrade === '' || selTerm === '' || subScribe === '' || cartItems.length === 0){
        setNotify(handleErrs('danger','Please all selection field are required','message'));
    }else
    {
       setNotify(null)
       setLoadData(true);

     const durationPrice =  handleDurationPrice();

       axios.post('/api-add-subject-student',{selGrade,selTerm,subScribe,cartItems,sid,durationPrice},{
           headers:{'Content-Type':'application/json'}
       }).then((res)=>{
                 setTimeout(()=>{
                     if(res.data.status === 'success'){
                         setNotify(null);
                         setLoadData(false);
                         window.location ="/account/invoice/"+res.data.invoiceID
                     }else{
                          setNotify(handleErrs('danger',res.data.message,'message'));
                     }
                 },1000)
          
       })
       


    }
}





  const handleDurationPrice = () => {
    let mtotal;
if(subScribe === 'Yearly'){
       mtotal = priceYear;
}else{
    mtotal = priceTerm; 
}

 return mtotal;
}

  const removeSubjectFromCart = (sid) =>{
    
    const updatedCartItems = cartItems.filter((item) => item.id !== sid);

            setCartItems(updatedCartItems);
}

const handleAddSubject = (sid,price,names,cate) =>{
             
  const newItem = {
       id:sid,
       price:price,
       names:names,
       cate:cate
  }

  setCartItems((prevItems)=>[...prevItems, newItem])

}


const handleTermSelection = (mterm) => {
                  
  setLoading(true)
  setTimeout(()=>{
      setSelTerm(mterm);
      setLoadUnRegSub(true);
      setLoading(false);

      axios.get('/get-subject/'+selGrade+'/'+mterm,{
           headers:{'Content-Type':'application/json'}
      }).then((res)=>{
              if(res.data.subjectData !== 'empty'){
                    setNotify(null)
                    setLoadSubject(res.data.subjectData);
              }else
              {
                   setLoadSubject(false)
                  setNotify(handleErrs('danger',`We are unable to retriev all subject for Grade ${selGrade}`,'message'))
              }
      })
  },1000)
} 

const handleGrade = (g) =>{
  let sp = g.split("=>");
   setLoading(true)
   setDisplayTerm(false)
   setSelGrade(sp[0]);
   setTermCheck(sp[1]);
   if(sp[2] === null){
        setTermPrice(0)
   }else{
        setTermPrice(sp[2])
   }

   if(sp[3] === null){
    setPriceYear(0)
}else{
    setPriceYear(sp[3])
}
   setTimeout(()=>{
         setLoading(false)
         setDisplayTerm(true)
   },1000)     
    
}

const handleSub = (msub) => {
  setSubScribe(msub);

}


     const handleErrs = ($type,$msg,$uitype) =>{
          let errorChild = {types:$type, msg:$msg, uiType:$uitype}
        return (
          <div>
          <ErrorMessage errors={errorChild} />
          </div>
        
        )
     }


     const handleAddSubjectCart = () => { 
      const cartHand = {handleDurationPrice, subScribe,cartItems,loading,notify,grade,displayTerm,
          termcheck,selGrade,loadunRegSub,loadSubject,removeSubjectFromCart, handleGrade,handleAddSubject,handleTermSelection,handleErrs, handleSub}
          return(
              <div>
                <SubjectAddHandler cartHandler={cartHand}  />
              </div>
          )
  }
 

     const submitFormData = async (data) => {
            setLoadData(true);
            await axios.post('/add-student-data',data,{
               headers: {'Content-Type': 'application/json'}
            }).then((res)=>{
                  setTimeout(()=>{
                    setLoadData(false);
                      setSid(res.data.sid);
                     if(res.data.status === 'success'){
                      setNotify(null)
                        setCompleteReg(true)
                     }else{
                          setNotify(handleErrs('danger',res.data.message,'message'));
                     }
                  },1000);
            });
     }

     const getidentity = (e) =>{
            let id = e.target.value;

             let ent;
         
              switch(id){
                 case 'Student ID Number':
                  ent = 'ID Number';
                  break;
                  case 'ID Passport':
                    ent = 'Passport Number'
                  break;
              }
            setIdentity(true);
            setIdType('Enter your '+ent);

     }

     const detectValidYear = (e) => {
      let nd = new Date().getFullYear();
       let selectDate = e.target.value;
          setLoadProg(true)
          setNotify(null)
          setDisplayForm(false)
        setTimeout(()=>{
          setLoadProg(false)
          if(selectDate >= nd){
         
            setDisplayForm(true)
            setNotify(null)
        
            
       }else{
     
        setDisplayForm(false)
         setNotify(handleErrs('danger','Selected Academic year is invalid. Kindly select a valid academic year','message'));
       }
        },1000)
       


     }
           

     const displayStudent = () =>{

         
      if(!addBtn){

      if(availableStud){
          return (
               <div>
                {  studentAvailable() }
               </div>
             
          )
      }else{

        return (
          <div>
           {   studentNotAvailable() }
          </div>
        
            )
                    
              }

       }else{

         return (
              <div>
                { addStudent() }
              </div>
         )
       }


         

  }

  const studentAvailable = () =>{
            return (
            
              <div>
               <div className="row">



               <div className="col-sm-6">
                <div className='text-start'>
                <h4>Learners</h4>
                </div>
                </div>
                <div className="col-sm-6">
                                                <div className="text-sm-end">
                                                    <button onClick={() => setAddBtn(true)} type="button" className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"><i className="mdi mdi-plus me-1"></i> Add New Learner</button>
                                                </div>
                                            </div>
        <div className="col-lg-12">
          <div>
            <div className="table-responsive">
              <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                  <tr>
                    <th scope="col" style={{width: '100px'}}>#</th>
                    <th scope="col" className='text-center'>Names</th>
                    <th scope="col" className='text-center'>Academic Year</th>
                    <th scope="col" className='text-center'>Grade</th>
                    <th scope="col" className='text-center'>Registration Status</th>
                    <th scope="col" className='text-center'>Profile Status</th>
                    <th scope="col" className='text-center'>Due Date</th>
                     <th scope="col" className='text-center'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  { currentTable && currentTable.map((s, index)=>{
                      return (
                        <tr key={s.id}>
                        <td> <h5 className="text-truncate font-size-14 text-dark">{1 + index}</h5></td>
                        <td className='text-center'>
                          <h5 className="text-truncate font-size-14 text-dark">{s.names}</h5>
                         
                        </td>
                        <td className='text-center'>{ s.academic_year }</td>
                        <td className='text-center'>{ s.grade === null ? 'N/A' : 'Grade '+s.grade }</td>
                        <td className='text-center'>
                           { s.reg_prog === 'Complete' ? <span className="badge bg-success">Complete</span>
                           :
                          <span className="badge bg-danger">UnCompleted</span>
                      }
                          </td>

                          <td className='text-center'>
                           { s.profile_status === 'Active' ? <div className='d-flex' style={{ marginLeft:70 }}> { handleErrs('Active',null,'profile-status') } Active </div>: <div className='d-flex' style={{ marginLeft:'10%' }}>{ handleErrs('Not Active',null,'profile-status')}  Not Active</div> }
                        </td>

                        <td className='text-center'>
                           { s.due_date === null ? 'Not Available' : s.due_date }
                        </td>
                        <td className='text-center'>
                     <button className="btn btn-primary btn-sm" onClick={() => history.push('/learners/student/'+s.stud_userid) }>View Profile <i className="mdi mdi-arrow-right ms-1"></i></button>
                        </td>
                      </tr>
                      )
                  })}
                
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
                 </div>
                     <div className="row">
                            <div className="col-12">
                                               <Pagination
                                                        className="justify-content-center mt-4"
                                                        currentPage={currentPage}
                                                        totalCount={loadStudent.length}
                                                        pageSize={PageSize}
                                                        onPageChange={page => setCurrentPage(page)}
                                                    />
                            </div>
                        </div>
                      
              </div>
           
            )
  }

  const studentNotAvailable = () =>{
          return (
              <div>
                <div className="my-5 pt-sm-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
             
              <div className="row justify-content-center">
                <div className="col-sm-4">
                  <div className="maintenance-img" style={{ marginTop:-70 }}>
                    <img src="/assets/images/coming-soon.svg" alt="" className="img-fluid mx-auto d-block" />
                  </div>
                </div>
              </div>
              <h4>You have no learner. Use the button below to add new learner</h4>
              <button type="button" onClick={() => setAddBtn(true)} className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2">
                                                <i className="bx bx-plus-medical font-size-16 align-middle me-2"></i> Add Student
                                            </button>
             
            </div>
          </div>
        </div>
      </div>
    </div>
              </div>
          )
  }

  const addStudent = () =>{
          
            return (
              
               <div>
                  { completeReg !== false ?
                   <div className='row'>
                     <div className='col-md-2'>
                      </div>
                    <div className='col-md-7'>
                      <div className='card'>
                      <div className='card-body'>

                        { handleAddSubjectCart() }

                      
                          <div className="d-grid gap-2">
              
                            <button type="submit" onClick={()=> handleTerms()}  className="btn btn-success btn-sm waves-effect waves-light">Continue</button>
                            </div>
                 </div>
                      </div>
                       </div>
                  </div>
                  
                  : 
                 <div className="row">
                   
                 <div className="col-xl-3">
                  </div>

             <div className="col-xl-6">
             { loadStudent.length > 0 ?
                                               <div className="text-sm-end">
                                                    <button onClick={() => setAddBtn(false)} type="button" className="btn btn-success btn-rounded btn-sm waves-effect waves-light mb-2 me-2"><i className="mdi mdi-arrow-left me-1"></i> Back </button>
                                                </div> : null }
        <div className="card">
          <div className="card-body">
                                             
           
            <form onSubmit={handleSubmit(submitFormData)}>

          

                  { notify !== null ? notify  : null}

               <div className="mb-3">
                    <label htmlFor="formrow-email-input" className="form-label">Select year you are applying for</label>
                    <select  name="year" id="year" {...register('year')} className="form-control" onChange={(e) => detectValidYear(e)}>
                       <option value="">Choose...</option>
                        { allYears.map((x)=> {
                             return (
                                <option value={x}>{x}</option>
                             )
                        })}
                    </select>
                  </div>
                  <div className='text-center'>
                          { loadProgress !== false ? handleErrs('dark',null,'load') : null}
                  </div>

                  { displayForm === false ? <div></div> :
                    <div>

              <h4 className="card-title">Complete the student form below</h4>
              <hr style={{ marginTop:-5, color:'red', fontWeight:'bolder' }} />
              <div className="mb-3">
                <label htmlFor="formrow-firstname-input" className="form-label">Full name</label>
                <input type="text" name="fullname" id="fullname" {...register('fullname')} className={`form-control ${errors.fullname ? 'is-invalid' : ''}`}  />
                <div className="invalid-feedback">{errors.fullname?.message}</div>
              </div>

              

              <div className="row">

              <div className="col-md-6">


                <div className="mb-3">
                  <label htmlFor="formrow-email-input" className="form-label">Gender</label>
                  <select name="gender" id="gender" {...register('gender')} className={`form-select ${errors.gender ? 'is-invalid' : ''}`}  >
                    <option value="">Choose...</option>
                    <option value={'Male'}>Male</option>
                    <option value={'Female'}>Female</option>
                  </select>
                  <div className="invalid-feedback">{errors.gender?.message}</div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                
                  <label htmlFor="formrow-password-input" className="form-label">Date of Birth</label>
                  <input type="date" name="dob" id="dob" {...register('dob')} className={`form-control ${errors.dob ? 'is-invalid' : ''}`} />
                  <div className="invalid-feedback">{errors.dob?.message}</div>
                
                </div>
              </div>



              </div>

              <div className="row">
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label htmlFor="formrow-inputCity" className="form-label">Learner Race</label>
                    <select name="race" id="race" {...register('race')} className={`form-select ${errors.race ? 'is-invalid' : ''}`} >
                       <option value="">Choose...</option>
                      <option value={'Black African'}>Black African</option>
                      <option value={'Colored'}>Colored</option>
                      <option value={'White'}>White</option>
                      <option value={'Indian / Asia'}>Indian / Asia</option>
                     
                    </select>
                    <div className="invalid-feedback">{errors.race?.message}</div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                  <label htmlFor="formrow-inputZip" className="form-label">Home Language</label>
                    <input type="text" name='home_lang' id='home_lang' {...register('home_lang')} className={`form-control ${errors.home_lang ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.home_lang?.message}</div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label htmlFor="formrow-inputZip" className="form-label">Other Language</label>
                    <input type="text" name='other_lang' id='other_lang' {...register('other_lang')} className={`form-control ${errors.other_lang ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.other_lang?.message}</div>
                  </div>
                </div>
              </div>


               
              <div className="mb-3">
                    <label htmlFor="formrow-inputZip" className="form-label">Address</label>
                    <input type='text' name="home_address" id="phone" placeholder="Enter home address" {...register('home_address')} className={`form-control ${errors.home_address ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.home_address?.message}</div>
                 </div>


                  <div className='row'>
                    <div className='col-md-6'>

                    <div className="mb-3">
                <label htmlFor="formrow-firstname-input" className="form-label">Student Region</label>
                <select name='region' id='region' {...register('region')} className={`form-select ${errors.region ? 'is-invalid' : ''}`}>
                       <option value="">Choose...</option>
                       { loadRegion && loadRegion.map((r)=>{
                             return(
                              <option key={r.id} value={r.location}>{r.location}</option>
                             )
                               
                       })}
                     
                    
                    </select>
                    <div className="invalid-feedback">{errors.region?.message}</div>
              </div>

                    </div>

                    <div className='col-md-6'>

                    <div className="mb-3">
                <label htmlFor="formrow-firstname-input" className="form-label">Identification Type</label>
                <select name='id_type' id='id_type' {...register('idtype')} className={`form-select ${errors.idtype ? 'is-invalid' : '' }`}  onChange={(e)=> getidentity(e)}>
                       <option value="">Choose...</option>
                      <option value={'Student ID Number'}>ID</option>
                      <option value={'ID Passport'}>Passport</option>
                    
                    </select>
                    <div className="invalid-feedback">{errors.idtype?.message}</div>
              </div>
                      
                      </div>
                  </div>
                


                { identity !== false ?
              <div className="mb-3">
                    <label htmlFor="formrow-inputZip" className="form-label">{idType}</label>
                    <input type="text" name='idNO' id='idNO' {...register('idNO')} className={`form-control ${errors.idNO ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.idNO?.message}</div>
                 </div>
                 : null }

             

            
              <div className="d-grid gap-2">
              
              <button type="submit" className="btn btn-success btn-sm waves-effect waves-light">Continue</button>
              </div>

              </div>
          }

            </form>
          </div>
          {/* end card body */}
        </div>
        {/* end card */}
      </div>
                    
                 </div> }
               </div>
           
           )
  }



const fetchStudent = async () => {
  let hs = getParameterByName('add');

    await axios.get('/get-parent-student',{ 
          headers: {'Content-Type': 'application/json'}
     }).then((response)=>{

                setLoadRegion(response.data.region);

                setGrade(response.data.grade)

                if(hs === 'learner'){
                  setAvailableStud(false)  
                  setAddBtn(true)
                }
    
            if(response.data.status === 'allow'){
                 
               setAvailableStud(true)
               setLoadStudent(response.data.studData);

            }else
            {
              setLoadStudent([])
              setAvailableStud(false)
            }
     }).catch(err=>{
        console.log(err)
     })
}

 useEffect(()=>{
     fetchStudent();
        
 },[availableStud]);


       return (
           <div>
            <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
          

          { loadData !== false ? 
                      <div id="preloader-spinner" className="preloader spinner">

                      <div className="wrapper">
                          <span className="loader"></span>
                          <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>
      
                          <p style={{marginLeft: -30, marginTop: 120}}>Processing your request. Please wait...</p>
                      </div>
      
                      </div> : null
                  }
           
          
            {displayStudent()}

            </div>
            <FootNav />
          </div>
           </div>
           </div>
       )

   
}

export default Learners