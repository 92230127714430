import axios from 'axios';
import React, { useState, useEffect } from 'react'
import ErrorMessage from '../userAuth/ErrorMessage';
import { useForm } from 'react-hook-form';
import '../../App.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import FootNav from '../Nav/FootNav';
import Pagination from '../General/Pagination';

function Centres() {
    let PageSize = 6;
      const [checkCent, setCheckCent] = useState(false);
      const [centData, setCentData] = useState([]);
      const [region, setRegion] = useState();
      const [loading, setLoading] = useState(false);
      const [displayForm, setDisplayForm] = useState(false);
      const [loadDatas, setLoadDatas] = useState(false)
      const [loadtxt, setLoadTxt] = useState('Loading page. Please wait...');
      const [notify, setNotify] = useState(null)
      const [rems, setRems] = useState(false)
      const [checkLen, setCheckLen] = useState();
      const history = useHistory();
      const [lat, setLat] = useState();
      const [log, setLog] = useState();

      const [currentPage, setCurrentPage] = useState(1);

      const handlePage = () => {
         const firstPageIndex = (currentPage - 1) * PageSize;
         const lastPageIndex = firstPageIndex + PageSize;
         return centData.slice(firstPageIndex, lastPageIndex);
       };

       const currentTable  = handlePage();

      const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


    const getUserLocation = () =>{

        if(navigator.geolocation){
          navigator.geolocation.watchPosition( (position) => {
    
              setLat(position.coords.latitude);
              setLog(position.coords.longitude);
              
          },(err)=>{
                console.log(err)
          })
      }else
      {
          console.log('Browser does not support location');
      }
    
      }
    
    

      const schema = Yup.object().shape({
                    names:Yup.string().required('centre name is required'),
                    email:Yup.string().required('Email is required')
                    .email('Please provide a valid email address'),
                    phone:Yup.string().required('Phone number is required'),
                    address:Yup.string().required('Address is required')
      });

      const formOption = { resolver: yupResolver(schema)};
      const {register, handleSubmit, formState } = useForm(formOption);
        const {errors} = formState;

        const submitCentre = (data) =>{
                setLoadDatas(true);
                setNotify(null)
                 setLoadTxt('Processing your request. Please wait...')
              axios.post(`/api-create-centres?lat=${lat}&&log=${log}`,data,{
                 headers:{'Content-Type':'application/json'}
              }).then((res)=>{
                    setTimeout(()=>{
                        setLoadDatas(false)
                        if(res.data.status === 'ok'){
                            setNotify(null)
                           window.location ="";
                       }else
                       {
                           setNotify(handleErrs('danger',res.data.status,'message'))
                       }
                    },1000)
                   
              }).catch((err)=>{
                   console.log(err)
              })
        }

        const removeCentre = (cid) => {
                axios.post('/api-removecentre',{cid:cid},{
                     headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                        if(res.data.status==='ok'){
                             
                               setRems(true)

                        }else{
                            setRems(false)
                        }
                }).catch(err=>{
                      console.log(err)
                })
        }

      const handleErrs = ($type,$msg,$uitype) =>{
        let errorChild = {types:$type, msg:$msg, uiType:$uitype}
      return (
        <div>
        <ErrorMessage errors={errorChild} />
        </div>
      
      )
    }

    const handleRegion = (e) =>{
            setLoading(true)
            setTimeout(()=>{
                setLoading(false)
                setDisplayForm(true)
            },1000)
          

    }


 const allCentres = () =>{

      if(centData.length > 0){

         return (
            <div className='row'>

                                            <div className="col-sm-6">
                                                <div className="text-sm-start">
                                                    <h4>Centres</h4>
                                             </div>
                                             </div>
                    

                                      <div className="col-sm-6">
                                                <div className="text-sm-end">
                                                    <button onClick={() => setCheckCent(false)} type="button" className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"><i className="mdi mdi-plus me-1"></i> Add New Centre</button>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                            <div className="table-responsive">
                                               
             <table className="table project-list-table table-nowrap align-middle table-borderless">
                  <thead>
                  <tr>
                         <th scope='col'></th>
                    <th scope="col" style={{width: '100px'}}>#</th>
                   
                    <th scope="col" className='text-center'>Centre Names </th>
                    <th scope="col" className='text-center'>Phone</th>
                    <th scope="col" className='text-center'>Email</th>
                    <th scope="col" className='text-center'>Centre Status</th>
                  
                    <th scope="col" className='text-center'>Register Date</th>
                     <th scope="col" className='text-center'>Action</th>
                  </tr>
                </thead>
                <tbody>
                     { currentTable && currentTable.map((c, index)=>{
                            return(
                                <tr key={index+1}>
                                      <td>  <i onClick={()=>removeCentre(c.centre_id)} className='bx bx-trash text-danger' style={{ cursor:'pointer' }}></i></td>
                                      <td>{ 1 + index} </td>
                                    
                                    <td>{ c.centre_name} </td>
                                    <td>{ c.phone} </td>
                                    <td>{ c.email} </td>
                                    <td className='text-center'>
                           { c.status === 'Active' ? <div className='d-flex' style={{ marginLeft:70 }}> { handleErrs('Active',null,'profile-status') } Active </div>: <div className='d-flex' style={{ marginLeft:'10%' }}>{ handleErrs('Not Active',null,'profile-status')}  Not Active</div> }
                        </td>
                                    <td>{ c.date_reg} </td>
                                    <td>
                                        <div className='d-flex'>
                                        <button className="btn btn-dark btn-sm" onClick={() => history.push('/centres/'+c.centre_id) }>View Centre <i className="mdi mdi-arrow-right ms-1"></i></button>
                                      
                                        </div>
                                      
                                    
                                    </td>

                                </tr>
                            )
                     })}
                    
                </tbody>
          </table>
                                                </div>

                                                <div className="row">
                            <div className="col-12">
                                               <Pagination
                                                        className="justify-content-center mt-4"
                                                        currentPage={currentPage}
                                                        totalCount={centData.length}
                                                        pageSize={PageSize}
                                                        onPageChange={page => setCurrentPage(page)}
                                                    />
                            </div>
                        </div>
                                               
                                                </div>


            </div>
         )

      }else{

         return(
            <div className='row'>
                <div className='col-lg-12'>
            { handleErrs(null,'You have no centres at this moment','not')}
            <div className="text-center">
         <button onClick={() => setCheckCent(false)} type="button" className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"><i className="mdi mdi-plus me-1"></i> Add New Centre</button>
         </div>
            </div>
            </div>
         )
      }

 }

 const addCentres = () =>{

         return (
            <div className='row'>
                <div className='col-lg-3'></div>
                <div className='col-lg-6'>
                    <div className='row'>
                                        { checkLen === 0 ? null :
                                         <div className="col-sm-12">
                                                <div className="text-sm-end">
                                                    <button onClick={() => setCheckCent(true)} type="button" className="btn btn-success btn-rounded btn-sm waves-effect waves-light mb-2 me-2"><i className="mdi mdi-arrow-left me-1"></i> Back </button>
                                                </div>
                                            </div> }
                                          </div> 
              
                    <div className='card'>
                          
                        <div className='card-body'>
                     
                            <h3 className='card-title'>Add centre</h3>
                            <hr style={{ marginTop:-5, color:'red' }} />

                            <form onSubmit={handleSubmit(submitCentre)}>
                                 { notify !== null ? notify : null }
                            <label htmlFor="formrow-email-input" className="form-label">Select centre region</label>
                            <select name='location' className={`form-select ${errors.location ? 'is-invalid' : ''}`}  {...register('location')} onChange={(e)=> handleRegion(e)}>
                                <option value={''}>Choose...</option>
                                 { region && region.map((r)=>{
                                      return (
                                         <option value={r.location}>{ r.location }</option>
                                      )
                                 })}
                            </select>
                            { displayForm !== false ?
                                 <div>
                                 <h4 className="card-title mt-4">Complete the centre form below</h4>
                                 
                                 <div className="mb-3 mt-4">
                                    <label htmlFor="formrow-firstname-input" className="form-label">Centre Names</label>
                                    <input type="text" name="names" {...register('names')} className={`form-control ${errors.names ? 'is-invalid' : ''}`}  {...register('names')}  />
                                    <div className="invalid-feedback">{errors.names?.message}</div>
                                </div>

                                <div className='row'>
                                    <div className='col-lg-6'>

                                    <div className="mb-3">
                                    <label htmlFor="formrow-firstname-input" className="form-label">Centre Email</label>
                                    <input type="email" name="email" id="email"  {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`}  />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                 </div>

                                    </div>

                                    <div className='col-lg-6'>

                                    <div className="mb-3">
                                    <label htmlFor="formrow-firstname-input" className="form-label">Centre Phone</label>
                                    <input type="tel" name="phone" className={`form-control ${errors.phone ? 'is-invalid' : ''}`}  {...register('phone')}  />
                                    <div className="invalid-feedback">{errors.phone?.message}</div>
                                      </div>
                                        
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="formrow-firstname-input" className="form-label">Centre Address</label>
                                    <textarea type="text" name="address" {...register('address')} style={{ resize:'none' }}  className={`form-control ${errors.address ? 'is-invalid' : ''}`}  />
                                    <div className="invalid-feedback">{errors.address?.message}</div>
                                </div>

                                <div className="d-grid gap-2">
              
                                    <button type="submit" className="btn btn-success btn-sm waves-effect waves-light">Create Centre</button>
                                    </div>

                               

                            </div> : null }


                              { loading !== false ? 
                              <div className='text-center mt-4'>{handleErrs('success',null,'load')}</div>
                            
                            : <div></div>}

                            </form>
                        


                        </div>
                    </div>


                </div>

               
            </div>
         )

 }

 const controllerCent = () =>{
          if(checkCent !== false){
                return( <>
                    { allCentres() }
                </>)
          }else
          {
            return (
                <>
                { addCentres() }
                </>
            )
          }
 }

  useEffect(()=>{
      setLoadDatas(true)
       let hs = getParameterByName('add');
       getUserLocation();
       
     axios.get('/api-fetch-centre',{
         headers:{'Content-Type':'application/json'}
     }).then((res)=>{
                setRegion(res.data.region)
                setLoadDatas(false)
                  let lng;
            if(res.data.centre !== 'empty'){
                        setCentData(res.data.centre)
                         lng = res.data.centre;
                         if(hs === 'centre'){
                            setCheckCent(false)
                         }else{
                            setCheckCent(true) 
                         }
                      
            }else{
                            lng = 0;
                        setCheckCent(false)
                        setCentData([])
            }
              setCheckLen(lng)
     }).catch((err)=>{
          console.log(err)
     })

  },[rems])
      
  return (
    <div className="main-content">
    <div className="page-content">
         
      <div className="container-fluid">
      { loadDatas === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

                <div className="wrapper">
                    <span className="loader"></span>
                    <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

                    <p style={{marginLeft: -30, marginTop: 120}}>{loadtxt}</p>
                </div>

                </div>
            }
                { controllerCent() }

                <FootNav />
        </div>
     </div>
   </div>
  )
}

export default Centres