import React,{useEffect, useState} from 'react'
import axios from 'axios'
import ErrorMessage from '../userAuth/ErrorMessage';
import '../../App.css';
import Result from '../General/Result';
import FootNav from '../Nav/FootNav';


function TermFinal() {

     const [grade, setGrade] = useState(null);
     const [pid, setPid] = useState();
     const [accYear, setAccYear] = useState();
     const [sData, setSData] = useState(null);
     const [loadBut, setLoadBut] = useState(false)
     const [viewStud, setViewStud] = useState(null);
     const [loadInfo, setLoadInfo] = useState(false);
     const [viewReport, setViewReport] = useState(false)
     const [terms, setTerms] = useState('');
     const [comment, setComment] = useState('');
     const [loader, setLoader] = useState(false);
     const [notify, setNotify] = useState(null);
     const [year, setYear] = useState('');
     const [studGrade, setStudGrade] = useState();
     const [getReport, setGetReport] = useState(null);
     const [loading, setLoading] = useState(false)
     const [reportNote, setReportNote] = useState(null);
     const [centDetails, setCentDetails] = useState(null);
     const [loadCent, setLoadCent] = useState(false);

     const utype = 'Parent';
     const cent = null;
    
     

      let tm_Split = terms.split(',');
      let lenTerm = tm_Split.length;

      const warnTerms = () =>{
                let ans;
            switch(terms){
               case '3,4':
                 ans = handleErrs(null,null,'warn');
                break;
                case 'all':
                  ans = handleErrs(null,null,'warn');
                break;
                case '2,3,4':
                  ans = handleErrs(null,null,'warn');
                break;
                default:

                  ans ='';
            }

              return ans;
      }

     

      const getScoreRating = (type,score) =>{
                
                 let rate;
               if(type === 'percent'){
                      
                    switch(score){
                        case '7':
                              rate = '80% - 100%'
                          break;
                        case '6':
                          rate = '70% - 79%'
                          break;
                        case '5':
                          rate = '60% - 69%'
                          break;
                         case '4':
                          rate = '50% - 59%'
                         break;
                         case '3':
                          rate = '40% - 49%'
                          break;
                          case '2':
                            rate = '30% - 39%'
                            break;
                         case '1':
                          rate = '0% - 29%'
                           break;
                           default:
                            rate = 'N/A'
                    }

                   
               }else{
                switch(score){
                  case '7':
                        rate = 'A+'
                    break;
                  case '6':
                    rate = 'A'
                    break;
                  case '5':
                    rate = 'B'
                    break;
                   case '4':
                    rate = 'C'
                   break;
                   case '3':
                    rate = 'D'
                    break;
                    case '2':
                      rate = 'E'
                      break;
                   case '1':
                    rate = 'F'
                     break;
                     default:
                      rate = 'N/A'
              }
                

               }

               return rate;

      }


     const handleErrs = ($type,$msg,$uitype) =>{
      let errorChild = {types:$type, msg:$msg, uiType:$uitype}
    return (
      <div>
      <ErrorMessage errors={errorChild} />
      </div>
    
    )
  }



  const letGetTerms = (tms,r) =>{
              let mscore;
            switch(tms){
                case '1':
               
                  
                     mscore = r.term1;
                   
                  break;
                 case '2':
                  mscore = r.term2;
                   break;
                  case '3':
                    mscore = r.term3;
                    
                break;
                case '4':
                  mscore = r.term4;
                  
              break;
                default:
                  mscore ="N/A";
            }

            let mst 
            if(mscore === null || mscore ===''){
                  mst = 'N/A';
              }else{
                   mst = mscore;
              }
          return mst;
  }

  

    const checkDisplayTable = (r) => {
            let len = tm_Split.length;

            switch(len){
              case 1:
                   let tms = tm_Split[0];
             
                return(
                 <tr key={r.id}>
                 <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.subject } 2020</td>
                 <td className='text-center' style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.cate }</td>
                <td className='text-center' style={{ fontWeight:'bolder' }} >{ letGetTerms(tms,r) }</td>
                <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tms,r) }</td>
               
             
               
                   </tr>
                )
               break;
               case 2:
                 return(
                   <tr key={r.id}>
                    <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.subject }</td>
                    <td className='text-center' style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.cate }</td>
                   <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[1],r) }</td>
                   <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[1],r) }</td>
                
                 
                  
                 
                     </tr>
                  )
               break;
               case 3:
                 return(
                   <tr key={r.id}>
                
                <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.subject }</td>
                <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.cate }</td>
                   <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[0],r) }</td>
                   <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[1],r) }</td>
                   <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[2],r) }</td>
                 
                 
                 
                     </tr>
                  )
               break;
               default:
                 return(
                   <tr key={r.id}>
                  <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.subject }</td>
                  <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.cate }</td>
                   <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[0],r) }</td>
                   <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[1],r) }</td>
                   <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[2],r) }</td>
                   <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[3],r) }</td>
                 
                     </tr>
                  )
                 }
             
    }

    const viewMyReport = () =>{

      let reports = {checkDisplayTable,terms,lenTerm,terms,getReport,tm_Split,comment,centDetails,viewStud}
            return(
                <div>
                <Result report={reports} />
                </div>
            ) 
    }

    const displayStudentInfo = () => {

        return (
          <>
            { viewStud !== null ?
           <div className='card'>
          <div className='card-body'>
        
                { loadInfo === false ? 

                          <div>
                             <h4 className='card-title '>Learner Details</h4>
                             <hr className='text-danger' style={{ marginTop:-5 }} />

                                      <div className="media mb-4">
                                            <div className="me-3">
                                             </div>
                                            <div className="media-body">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <div className="text-muted">
                                                            <h5 className="mb-2" style={{ textTransform:'capitalize' }}>{viewStud.names}</h5>
                                                            <p className="mb-0 text-dark">Learner ID: {viewStud.stud_userid}</p>
                                                            <div className='d-flex text-dark mt-1'>Profile Status: { viewStud.profile_status === false ? <div className='d-flex'> { handleErrs('Not Active',null,'profile-status') } Not Active</div> : <div className='d-flex'> { handleErrs('Active',null,'profile-status') } Active</div> } </div>
                                                           
                                                        </div>
                                                        
                                                    </div>

                                                    
                                                </div>
                                             </div>
                                         </div>
                                                

                
                            <div className="table-responsive">
                                    

                                            <table className="table table-nowrap mb-0">
                                                <tbody>
                                                <tr>
                                                        <th scope="row">Gender :</th>
                                                        <td>{ viewStud.gender }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Date of Birth :</th>
                                                        <td>{ viewStud.dob }</td>
                                                    </tr>
                                                  

                                                    <tr>
                                                        <th scope="row">Home Language :</th>
                                                        <td>{ viewStud.home_lang} </td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row">Other Language :</th>
                                                        <td>{ viewStud.other_lang } </td>
                                                    </tr>

                                                
                                                    <tr>
                                                        <th scope="row">{ viewStud.id_type } :</th>
                                                        <td>{viewStud.stud_id_no}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Address</th>
                                                        <td>{ viewStud.address }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Region</th>
                                                        <td>{ viewStud.region }</td>
                                                    </tr>
                                                  
                                                </tbody>
                                            </table>
                               </div>

                               </div>
                
                :
                <div className='text-center'>
             <div className="spinner-border text-success m-1" style={{ height:100, width:100 }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                
                </div>
                 }

           
        
          </div>
        </div>
         : null }

          { centDetails === null ? null :
        <div className='card mt-4'>
          <div className='card-body'>
              
                { loadCent !== false ? <>
                  <div className='text-center'>
             <div className="spinner-border text-success m-1" style={{ height:100, width:100 }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                
                </div>
                </> : <>
                <h4 className='card-title '>Centre Details</h4>
                <hr className='text-danger' style={{ marginTop:-5 }} />
                <div className="media mb-4">
                                            <div className="me-3">
                                                <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-sm rounded-circle img-thumbnail" />
                                            </div>
                                            <div className="media-body">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <div className="text-muted">
                                                            <h5 className="mb-2" style={{ textTransform:'capitalize' }}>{centDetails[0].centre_name}</h5>
                                                            <p className="mb-0 text-dark">Centre ID: {centDetails[0].centre_id}</p>
                                                            <div className='d-flex text-dark mt-1'>Centre Status: { centDetails[0].status === false ? <div className='d-flex'> { handleErrs('Not Active',null,'profile-status') } Not Active</div> : <div className='d-flex'> { handleErrs('Active',null,'profile-status') } Active</div> } </div>
                                                           
                                                        </div>
                                                        
                                                    </div>

                                                    
                                                </div>
                                             </div>
                                         </div>

                                         <div className="table-responsive">
                                    

                                    <table className="table table-nowrap mb-0">
                                        <tbody>

                                        <tr>
                                                <th scope="row">Email :</th>
                                                <td>{ centDetails[0].email} </td>
                                            </tr>

                                        <tr>
                                                <th scope="row">Phone :</th>
                                                <td>{ centDetails[0].phone} </td>
                                            </tr>
                                       
                                            <tr>
                                                <th scope="row">Address</th>
                                                <td>{ centDetails[0].address }</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Region</th>
                                                <td>{ centDetails[0].region }</td>
                                            </tr>
                                          
                                        </tbody>
                                    </table>
                       </div>
                                                
                
                </>}
              
            </div>
            </div>
    }

          </>
         
        )

    }


     const getStudentDatas = (e) =>{
           const gd = e.target.value;

            if(gd !== ''){
                  setLoadBut(true)
                    setStudGrade(gd)
              axios.get('/api-list-student/'+gd+'/'+pid,{
                   headers:{'Content-Type':'application/json'}

              }).then((res)=>{
                      setTimeout(()=>{
                        setLoadBut(false)
                        if(res.data.msData !== 'empty'){
                            setLoadInfo(true)
                          setSData(res.data.msData);
                           setTimeout(()=>{
                                setLoadInfo(false)
                           },1000)
                    }else{
                          setSData(null)
                    }
                      },1000)
                    
              }).catch(err =>{
                  console.log(err)
              })

            } 
     }


const getGrades = (parentID,userType) => {
  axios.get('/api-list-currentgrade/'+parentID+'/'+userType,{
     headers:{'Content-Type':'application/json'}
  }).then((res)=>{
              setAccYear(res.data.acYear);
              

          if(res.data.gradeData === 'empty'){
                   setGrade(null);
              
          }else{
               setGrade(res.data.gradeData);
          }
  })
}

    const handleFinalResult = () =>{
      const sid = viewStud.stud_userid;

      setReportNote(null);
      if(terms === ''){
         setReportNote(handleErrs('danger','Please select the term of result you want to create','message'))
      }else
      {
           setLoading(true)
          
                const datas = {sid:sid,year:year,studGrade:studGrade,terms:terms}
              axios.post('/create-report',datas,{
                  headers:{'Content-Type':'application/json'}
              }).then((res)=>{
                      setTimeout(()=>{
                        setLoading(false)
                        if(res.data.report !== 'empty'){
                          setGetReport(res.data.report);
                          setReportNote(null)
                   }else{
                            setGetReport(null)
                            setReportNote(handleErrs('danger','Oops! We are unable to create a report for this learner, because no result found','message'))
                   }
                      },1500)
                  

              }).catch(err =>{
                  console.log(err)
              })
      }
    }

  const displayStudentData = (e) =>{

            const sid = e.target.value;
             setLoadInfo(true)
            
             setViewStud('empty')
            axios.get('/view-finalstudent/'+sid,{
               headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                    setTimeout(()=>{
                       setLoadInfo(false)
                       setLoadCent(true)
                      setViewStud(res.data.studData[0]);
                      if(res.data.mcent !== 'empty'){
                              setCentDetails(res.data.mcent)
                      }else{
                              setCentDetails(null)
                      }
                        setTimeout(()=>{
                          setLoadCent(false)
                        },1000)
                       
                    },1000)
                    
            }).catch(err =>{
                 console.log(err)
            })
  }


  const displayReport = (yr) =>{
           setViewReport(true);
           setYear(yr)
     
  }


  useEffect(()=>{
    setLoadBut(true)
   axios.get('/checklogin/status',{
      headers:{'Content-Type':'application/json'}
   }).then((res)=>{
       setTimeout(()=>{
     
        setLoadBut(false)
          setPid(res.data.userData[0].userid)
         getGrades(res.data.userData[0].userid,res.data.userData[0].user_type)
       },1000)
       
   })
},[])

  return (
    <div>
            <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          { loading === false ? null : 
          <div id="preloader-spinner" className="preloader spinner">

                <div className="wrapper">
                    <span className="loader"></span>
                    <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

                    <p style={{marginLeft: -30, marginTop: 120}}>Creating your report. Please wait...</p>
                </div>

                </div>
            }


            { getReport === null ? 
          <div className="row">


       
       <div className="col-lg-8">
       <div className="row">

       <div className="col-lg-12">

          <div className="card">
            <div className="card-body">

            <h5 className="card-title mb-4">Create Terms / Final Report</h5>
            <hr className='text-danger' style={{ marginTop:-15 }} />
              <form className="row gy-3 gx-5 align-items-center">

              <div className="col-sm-auto col-md-4">
                  <label htmlFor="autoSizingSelect">Grade</label>

                  
                  <select className="form-select" onChange={(e)=> getStudentDatas(e)}>
                  <option value=''> Choose... </option>
                      {
                         grade === null ? <option disabled>Not available</option> :
                      
                      grade && grade.map((g)=>{
                          return (
                              <option key={g.id} value={g.grade}>Grade {g.grade}</option>
                          )
                      })}
               
                  </select>

                 
                </div>



                <div className="col-sm-auto col-md-4">
                  <label htmlFor="autoSizingSelect">Learners</label>

                  
                  <select className="form-select form-control" onChange={(e)=> displayStudentData(e)}>
                    <option value=''>Choose...</option>
                     { sData !== null ? 
                       sData && sData.map((s)=>{
                          return (
                            <option key={s.id} value={s.stud_userid}> {s.names}</option>
                          )
                       })
                       :   <option disabled>Not Available</option>
                    }
                  
               
                  </select>

                 
                </div>

                <div className="col-sm-auto col-md-4">
                  <label htmlFor="autoSizingSelect">Academic Year</label>

                  
                  <select className="form-select" id="year" onChange={(e)=> displayReport(e.target.value)}>
                    <option value=''>Choose...</option>
                  { accYear !== null ?
                      accYear && accYear.map((y)=>{
                              return(
                                <option key={y.id} value={y.year}>{y.year } </option>
                              )
                      })
                      :
                      <option disabled>Not available</option>
                }
               
                  </select>

                 
                </div>

                 { loadBut !== false ? <div className='text-center'>{ handleErrs('danger',null,'load') }</div> : null }




                </form>

              </div>
            </div>


            { viewReport === false ? null :
            <div className='card'>
              <div className='card-body'>

              
                <div>
           
                    { reportNote !== null ? reportNote : null}

                  <div className='mb-3 mt-3'>
                    <label>Select Term</label>
                  <select className='form-select' onChange={(e)=> setTerms(e.target.value)}>
                    <option value={''}>Choose...</option>
                    <option value={1}>Term 1</option>
                    <option value={2}>Term 2</option>
                    <option value={3}>Term 3</option>
                    <option value={4}>Term 4</option>
                    <option value={'1,2'}>Term 1, 2</option>
                    <option value={'1,2,3'}>Term 1, 2, 3</option>
                    <option value={'2,3,4'}>Term 2, 3, 4</option>
                    <option value={'3,4'}>Term 3, 4</option>
                    <option value={'1,2,3,4'}>All Term</option>
                  
                  </select>
                  </div>

                  { warnTerms() }

                  <div className='mt-4 mb-3'>
                  <label>Comment (Option)</label>
                  <textarea onChange={(e)=> setComment(e.target.value)} style={{resize:'none'}} rows={4} className="form-control" placeholder="Addition report comment"></textarea>

                  </div>

                  <div className='mt-4 text-end'>
                     { loader === false ?
                  <button onClick={()=> handleFinalResult()} type="button" className="btn btn-primary w-xl waves-effect waves-light" id="create-report">
                    <i className="mdi mdi-download font-size-16"></i> Create Report
                                            </button>  
                                            :

                                            <div className='text-center'>{ handleErrs('success',null,'load') }</div> 

                     }
                  </div>
                  </div>
                

              </div>
            </div>
}


          </div>

        </div>

        </div>


        <div className='col-lg-4'>
             { displayStudentInfo()}
        </div>

       



        </div>
        : viewMyReport()

}
          
        

            </div>
            <FootNav />
          </div>
           </div>
           </div>
  )
}

export default TermFinal