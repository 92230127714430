import React, { useState, useEffect } from 'react'
import ErrorMessage from '../userAuth/ErrorMessage'
import { useParams } from 'react-router-dom'
import '../../App.css'
import axios from 'axios';
import FootNav from '../Nav/FootNav'


function ViewCentre() {

      const [loadDatas, setLoadDatas] = useState(false);
      const { cid } = useParams();
      const  [cDatas, setCDatas] = useState()
      const [checkDatas, setCheckDatas] = useState(false)
      const [studList, setStudList] = useState();
      const [countStud, setCountStud] = useState(0);
      const [studDatas, setStudDatas] = useState(false)
      const [subStud, setSubStud] = useState([]);
      const [loadS, setLoadS] = useState(false);
      const [subjectTotal, setSubjectTotal] = useState();
      const [currGrade, setCurrGrade] = useState('filter');
      const [grade, setGrade] = useState();
      const [region, setRegion] = useState([]);
      const [centreName, setCentreName] = useState('');
      const [centreEmail, setCentreEmail] = useState('');
      const [centrePhone, setCentrePhone] = useState('');
      const [centreRegion, setCentreRegion] = useState('');
      const [centreAddress, setCentreAddress] = useState('');
      const [notify, setNotify] = useState(null);
      const [lat, setLat] = useState();
      const [log, setLog] = useState();
      const [loadUp, setLoadUp] = useState(false);
      const [centreUpdate, setCentreUpdate] = useState(false);


      const handleErrs = ($type,$msg,$uitype) =>{
        let errorChild = {types:$type, msg:$msg, uiType:$uitype}
      return (
        <div>
        <ErrorMessage errors={errorChild} />
        </div>
      
      )
    }

    const getUserLocation = () =>{

        if(navigator.geolocation){
          navigator.geolocation.watchPosition( (position) => {
          
               setLat(position.coords.latitude)
               setLog(position.coords.longitude)

          },(err)=>{
                console.log(err)
          })
      }else
      {
          console.log('Browser does not support location');
      }
    
      }
  

    const calculateAge = (date) =>{
                let m = date.split('-');
                 let y = new Date().getFullYear();

                 let age = y - m[0];
                   
                  return age
    }

    const getStudProfile = (sid,grd,numSub) => {
                    setLoadS(false)
              axios.get('/api-fetchstudgradecent/'+sid+'/'+grd,{
                   headers:{'Content-Type':'application/json'}
              }).then((res)=>{
                   setTimeout(()=>{
                    setLoadS(true)
                        setStudDatas(res.data.stud[0]);
                        setSubStud(res.data.sub);
                        setSubjectTotal(numSub)
                         

                   },1000)
                    
              }).catch((err)=>{
                 console.log(err)
              })
    }

    const learnerProfile = () =>{
         return (

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Learner Profile</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        { loadS === false ? <div className='text-center'>  <div style={{ width:200, height:200 }} className="spinner-border text-success m-1" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div></div>: 
                      <div className='row'>
                         <div className='col-lg-6'>
                            <div className='card'>
                            <div className='card-body'>
                            <h4 className="card-title mb-4">Personal Information</h4>
                         <div className="media">
                                            <div className="me-4">
                                                <i className="mdi mdi-account-circle text-primary h1"></i>
                                            </div>

                                            <div className="media-body">
                                                <div className="text-muted">
                                                    <h5 style={{ textTransform:'uppercase' }}>{ studDatas.names }</h5>
                                                    <p className="mb-1">ID NO: { studDatas.stud_userid }</p>
                                                    <p className="mb-0">Grade: { studDatas.grade }</p>
                                                </div>
                                                
                                            </div>

                                          
                                        </div>
                                    </div>
                                </div>




                                        <div className="card">
                                    <div className="card-body">
                                        
                                        <div className="table-responsive">
                                            <table className="table table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Date of birth :</th>
                                                        <td>Age: { calculateAge(studDatas.dob) }, { studDatas.dob }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Gender :</th>
                                                        <td>{ studDatas.gender }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Race :</th>
                                                        <td>{ studDatas.race }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Academic Year :</th>
                                                        <td>{ studDatas.academic_year }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Location :</th>
                                                        <td>{ studDatas.address+', '+studDatas.region }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Home Language :</th>
                                                        <td>{ studDatas.home_lang }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                         </div>

                         <div className='col-lg-6'>

                         <div className="table-responsive">
                            { subStud.length === 0 ? handleErrs(null,'This learner has no subject','not') : 
                         <table className="table align-middle table-nowrap table-check">
                         <thead className="table-light">
                                                    <tr>
                                                        <th className="align-middle">Subject Name</th>
                                                        <th className="align-middle">Subject ID</th>
                                                        <th className="align-middle">Status</th>
                                                 
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                     { subStud && subStud.map((x)=>{
                                                          return (
                                                            <tr>
                                                                <td>{x.subject_name}</td>
                                                                <td>{x.subject_id}</td>
                                                                <td>{x.status}</td>
                                                            </tr>
                                                          )
                                                     })}
                           

                                                </tbody>
                            </table> } 
                         </div>
                            
                         </div>
                      </div>
    }

                    </div>
                   
                </div>
            </div>
        </div>
         )
    }

    const handleCrentreUpdate = () =>{
            if(!centreAddress.trim() || !centreEmail.trim() || !centreName.trim() || !centrePhone.trim()){
                  setNotify(handleErrs('danger','Please make sure all input are not empty','message'))
            }else
            {           
                   setNotify(null)
                   setLoadUp(true)
                   
                   const datas = {centreAddress,centreEmail,centreName,centrePhone,centreRegion,lat,log,cid}
                   axios.post('/api-updatecentre-data',datas,{
                        headers:{'Content-Type':'application/json'}
                   }).then((res)=>{
                         setTimeout(()=>{
                             setLoadUp(false)
                            setNotify(handleErrs(res.data.color,res.data.message,'message'))
                            setCentreUpdate(true)
                         },1000)
                        
                   }).catch((err)=>{
                        console.log(err)
                   })
            }
    }
    
    const splitDatas = (data,symbol) =>{
                 let s = data.split(symbol);
                  return s[0];
    }

    const viewCentreProfile = () =>{
                return(
                   
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="media">
                                            <div className="me-3">
                                            <div className="me-4">
                                                <i className="mdi mdi-account-circle text-primary h1"></i>
                                            </div>
                                            </div>
                                            <div className="media-body align-self-center">
                                                <div className="text-muted">
                                                    <h5 className="mb-2">Welcome to <b>{ cDatas[0].centre_name }</b></h5>
                                                    <p className="mb-1">{ cDatas[0].email }</p>
                                                    <p className="mb-1">{ cDatas[0].phone }</p>
                                                    <p className="mb-0">{ cDatas[0].address+', '+cDatas[0].region }</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div className="col-lg-4 align-self-center">
                                        <div className="text-lg-center mt-4 mt-lg-0">
                                            <div className="row">
                                                <div className="col-4">
                                                    <div>
                                                        <p className="text-muted text-truncate mb-2">Total Learners</p>
                                                        <h5 className="mb-0">{ countStud }</h5>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div>
                                                        <p className="text-muted text-truncate mb-2">Date Registered</p>
                                                        <h5 className="mb-0 font-size-12">{ cDatas[0].date_reg}</h5>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div>
                                                        <p className="text-muted text-truncate mb-2">Centre Status</p>
                                                        <h5 className="mb-0 font-size-14">
                                                        { cDatas[0].status === 'Active' ? <div className='d-flex'> { handleErrs('Active',null,'profile-status') } Active </div>: <div className='d-flex'>{ handleErrs('Not Active',null,'profile-status')}  Not Active</div> }
                                                        </h5>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div className="col-lg-3 d-none d-lg-block">
                                        <div className="clearfix mt-4 mt-lg-0">
                                            <div className="dropdown float-end">
                                                <button className="btn btn-primary mt-3" type="button" data-bs-toggle="modal" data-bs-target="#staticBack">
                                                    <i className="bx bxs-cog align-middle me-1"></i> Setting
                                                </button>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal fade" id="staticBack" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="staticBackdropLabel">Centre Settings</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">

                                                            { notify === null ? null : notify }

                                                        <div className="mb-3">
                                                            <label htmlFor="formrow-firstname-input" className="form-label">Centre Names</label>
                                                            <input type="text" name="names" onChange={(e)=> setCentreName(e.target.value)} className="form-control" defaultValue={cDatas[0].centre_name} />
                                                           
                                                        </div>


                                                            <div className='row'>
                                                                <div className='col-lg-6'>

                                                                <div className="mb-3">
                                                            <label htmlFor="formrow-firstname-input" className="form-label">Centre Email</label>
                                                            <input type="text" name="names" onChange={(e)=> setCentreEmail(e.target.value)} className="form-control" defaultValue={cDatas[0].email} />
                                                           
                                                        </div>

                                                                </div>

                                                                <div className='col-lg-6'>

                                                                <div className="mb-3">
                                                            <label htmlFor="formrow-firstname-input" className="form-label">Centre Phone</label>
                                                            <input type="text" name="names" onChange={(e)=> setCentrePhone(e.target.value)} className="form-control" defaultValue={cDatas[0].phone} />
                                                           
                                                                    </div>
                                                                    
                                                                    </div>
                                                            </div>

                                                            <div className="mb-3">
                                                            <label htmlFor="formrow-firstname-input" className="form-label">Centre Region</label>
                                                            <select  className="form-select" onChange={(e)=> setCentreRegion(e.target.value)}>
                                                                <option defaultValue={cDatas[0].region}>{cDatas[0].region}</option>
                                                                 { region.length > 0 ? region && region.map((g,i)=>{
                                                                     return (
                                                                         <option key={i} defaultValue={g.value}>{g.location}</option>
                                                                     )
                                                                 }) : <option disabled></option>
                                                                
                                                                }
                                                            </select>
                                                           
                                                        </div>

                                                        <div className="mb-3">
                                                            <label htmlFor="formrow-firstname-input" className="form-label">Centre Address</label>
                                                            <textarea style={{ resize:'none' }} onChange={(e)=> setCentreAddress(e.target.value)} className="form-control">{cDatas[0].address}</textarea>
                                                           
                                                        </div>


                                                       

 

                                                        </div>
                                                        <div className="modal-footer">
                                                           { loadUp === false ?
                                                            <button type="button" onClick={()=> handleCrentreUpdate()} className="btn btn-dark">Update Centre</button>
                                                            :   <button type="button" className="btn btn-dark btn-sm waves-effect">
                                                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Updating...
                                                        </button>
                                                             }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                            
                            </div>
                        </div>
                    </div>
                </div>
             
                )
    }

     const viewAllLearners = () =>{
                return (
                    <div className='row'>

                                        <div className="col-sm-4">
                                                <div className=" me-2 mb-2 d-inline-block">
                                                   
                                                        
                                                        <select style={{ width:200 }} type="text" onChange={(e)=>setCurrGrade(e.target.value)} className="form-select form-control" >
                                                          
                                                            <option value={'filter'}>Filter Learner by Grade</option>
                                                                { grade === null ? <option value={''} disabled>Not Available</option> :
                                                                grade && grade.map((g)=>{
                                                                    return (
                                                                        <option key={g.id} value={g.grade}>{'Grade '+g.grade}</option>
                                                                    )
                                                                })
                                                                }
                                                        </select>
                                                       
                                                  
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="text-sm-end">
                                                 <h4>{ cDatas[0].centre_name} Learners</h4>
                                                 </div>
                                            </div>

                        <div className='col-lg-12'>  
                            <hr />
                        </div>
                        
                        { countStud > 0 ?

                         studList && studList.map((s)=>{
                              return(
                                <div className="col-xl-4 col-sm-6">
                                <div className="card">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="text-center p-4 border-end">
                                                <div className="avatar-sm mx-auto mb-3 mt-1">
                                                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16" style={{ textTransform:'uppercase' }}>
                                                        {splitDatas(s.names,'')}
                                                    </span>
                                                </div>
                                                <h5 className="text-truncate pb-1">{s.names}</h5>
                                            </div>
                                        </div>

                                        <div className="col-xl-6">
                                            <div className="p-4 text-center text-xl-start">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <p className="text-muted mb-2 text-truncate">Subject</p>
                                                            <h5>{ s.subject}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <p className="text-muted mb-2 text-truncate">Grade</p>
                                                            <h5>{ s.grade}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <a style={{ cursor:'pointer' }} onClick={()=>getStudProfile(s.stud_id,s.grade,s.subject)} className="text-decoration-underline text-reset" data-bs-toggle="modal" data-bs-target="#staticBackdrop">See Profile <i className="mdi mdi-arrow-right"></i></a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                              )
                         })
                       
                            :
                            handleErrs(null,'You have no learners in '+cDatas[0].centre_name,'not') }


                    </div>
                )
     }

     const handleGrade = () => {

        axios.get('/api-list-currentgrade/'+cid+'/Tutor',{
             headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                        if(res.data.gradeData !== 'empty'){
                            setGrade(res.data.gradeData)
                       }else{
                            setGrade(null)
                       }
                
             
        }).catch((err)=>{
              console.log(err)
        })
  
}

     useEffect(()=>{
            setLoadDatas(true)
            getUserLocation();
            setCentreUpdate(false)
            
           axios.get('/api-centredatas/'+cid+'/'+currGrade,{
               headers:{'Content-Type':'application/json'}
           }).then((res)=>{
                setTimeout(()=>{
                       setLoadDatas(false);
                       setCheckDatas(true);
                        handleGrade();
                        setCDatas(res.data.centreData);
                        setStudList(res.data.studDatas);
                        setCountStud(res.data.countStud);
                        setRegion(res.data.region)

                        setCentreAddress(res.data.centreData[0].address)
                        setCentreName(res.data.centreData[0].centre_name)
                        setCentrePhone(res.data.centreData[0].phone)
                        setCentreEmail(res.data.centreData[0].email)
                        setCentreRegion(res.data.centreData[0].region)
                        
                      
                },800)

           }).catch(err=>{
               console.log(err)
           })
     },[currGrade,centreUpdate])
     
  return (
    <div className="main-content">
    <div className="page-content">  

      <div className="container-fluid">
        { learnerProfile() }

      { loadDatas === false ? null : 
             <div id="preloader">
             <div id="status">
                 <div className="spinner-chase">
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                 </div>
             </div>
         </div> 
            }  

            { checkDatas !== false ? viewCentreProfile() : null }

            { checkDatas !== false ? viewAllLearners() : null }

            <FootNav />

        </div>
     </div>
 </div>
  )
}

export default ViewCentre