import React, { useState, useEffect } from 'react'
import ErrorMessage from '../userAuth/ErrorMessage';
import axios from 'axios';
import FootNav from '../Nav/FootNav';
import Pagination from './Pagination';
import $ from  'jquery'


let PageSize = 6;

function Wallet() {
  const [wloader, setWloader] = useState(false);
  const [wShowForm, setWShowForm] = useState();
  const [mform, setMForm] = useState(false);
  const [pname, setPname] = useState();
  const [pemail, setPemail] = useState();
  const [walletID, setWalletID] = useState();
  const [balance, setBalance] = useState();
  const [loadTrans, setLoadTrans] = useState([])
  const [userType, setUserType] = useState();
  const [amt, setAmt] = useState('');
  const [wRequest, setWRequest] = useState(null);
  const [loadMe, setLoadMe] = useState(false);
  const [notify, setNotify] = useState(null);
  const [tdetails, setTdetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [account, setAccount] = useState(null);
  const [userID, setUserID] = useState();
  const [lodBut, setLodBut] = useState(false);

  const handlePage = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return loadTrans.slice(firstPageIndex, lastPageIndex);
  };

   const currentTableData = handlePage();

  const handleErrs = ($type,$msg,$uitype) =>{
    let errorChild = {types:$type, msg:$msg, uiType:$uitype}
  return (
    <div>
    <ErrorMessage errors={errorChild} />
    </div>
  
  )
}

const addFunds = () =>{
    axios.post('/api-update-user-wallet',{amt},{
       headers:{'Content-Type':'application/json'}
    }).then((res)=>{

    }).catch(err=>{

    })
}




const Currency = (num) =>{
      let bala = parseInt(num)
  return bala.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const handlePayment = () =>{

          if(!amt.trim()){
                setNotify(<div className='alert alert-danger text-center'>Please provide the amount you wish to deposit</div>)
          }else
          {
          let datas = {amt:amt,email:pemail}
          
            setLodBut(true)
          axios.post('/payment',datas,{
             headers:{'Content-Type':'application/json'}
          }).then((res)=>{
          
            window.$("#staticBackdrop").modal("hide");
            setLodBut(false)
             window.payfast_do_onsite_payment({"uuid":res.data.payID.uuid},  (result) => {
              if (result === true) {
                    addFunds();
              }
              else {
                // Payment Window Closed
                   alert('Closee')
              }
            }); 
           
        

          }).catch((err)=>{
              console.log(err)
          })

        }
        

}

const handleWith = () => {
           if(!amt.trim()){
               setNotify(handleErrs('danger','Please enter the amount you want to withdraw','message'))
           }else
           {
               if(parseInt(amt) > parseInt(balance)){
                setNotify(handleErrs('danger','Request can not be made due to insufficient funds','message'))
               }else
               {
                    setWloader(true)
                   axios.post('/api-withdrawal-request',{amt,pname},{
                      headers:{'Content-Type':'application/json'}
                   }).then((res)=>{
                      setTimeout(()=>{
                        setWloader(false)
                        setNotify(handleErrs(res.data.color,res.data.message,'message'));
                         
                      },1000)
                   
                   }).catch((err)=>{
                      console.log(err)
                   })
               }
           }
}


const handleView = (details,amt,tdate,tid) =>{
         let datas = {details,amt,tdate,tid}
          setTdetails(datas)
         
}

const loadUserTransaction = () =>{
       return(
         
        <div>

<div className="modal fade orderdetailsModal" tabindex="-1" role="dialog" aria-labelledby="orderdetailsModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="orderdetailsModalLabel">Order Details</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p className="mb-2">Transaction ID: <span className="text-primary">{ tdetails.tid }</span></p>
                                <p className="mb-4">Transaction Date: <span className="text-primary">{ tdetails.tdate}</span></p>

                                <div className="table-responsive">
                                    <table className="table align-middle table-nowrap" >
                                        <thead>
                                            <tr>
                                            <th scope="col">Details</th>
                                      
                                            <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td >
                                                <div  style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>

                                                    <p>
                                                        { tdetails.details}
                                                    </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <h5 className="text-truncate font-size-14">R { Currency(tdetails.amt) }</h5>
                                                      
                                                    </div>
                                                </td>
                                             
                                            </tr>
                                            
                                            <tr>
                                                <td>
                                                    <h6 className="m-0 text-right">Tax:</h6>
                                                </td>
                                                <td className='text-dark'>
                                                    R 0.00
                                                </td>
                                            </tr>
                                            <tr>
                                                <td >
                                                    <h6 className="m-0 text-right">Total:   </h6>
                                                </td>
                                                <td>
                                                R { Currency(tdetails.amt) }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="table-responsive">
<table id="datatable" className="table table-hover dt-responsive nowrap" 
style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>

<thead>
  <tr>
      <th>S/N</th>
       <th>Amount</th>
        <th>Deposit type</th>  
        <th>Date</th>
        <th>Action</th>
      
       </tr>
         </thead>

         <tbody>
         
          { currentTableData && currentTableData.map((x, i)=>{
              
              return(
                <tr key={x.id}>
              <td>{ i + 1} </td>
            <td>R {Currency(x.amount)}</td>
            <td>{x.depo_type}</td>
            <td>{x.trans_date}</td>
            <td>
           

              <button type="button" onClick={()=> handleView(x.details,x.amount,x.trans_date,x.tras_id)} className="btn btn-dark btn-sm btn-rounded" data-bs-toggle="modal" data-bs-target=".orderdetailsModal">
                                                                View Details
                                                            </button>
            
            </td>
          
               </tr>
              )
          })}
           
         </tbody>

        </table>
        </div>
                                       <div className="row">
                                                    <div className="col-lg-12">

                                                    <Pagination
                                                        className="justify-content-center mt-4"
                                                        currentPage={currentPage}
                                                        totalCount={loadTrans.length}
                                                        pageSize={PageSize}
                                                        onPageChange={page => setCurrentPage(page)}
                                                    />
                                                    </div>
                                                </div>

        </div>
       )
}
const handleChange = (e) => {
  const value = e.target.value.replace(/\D/g, "");
  setAmt(value);
};

 const cardView = () =>{
  return (
     
    <div>

       <div className='mb-4'>
       <button className="btn btn-primary btn-sm w-md" onClick={()=> setMForm(false)}> <b>Back to Deposit Options</b></button>
                               
       </div>
     <form>
     
     <div className="input-group mb-3">
            <label className="input-group-text">Amount</label>
         <input type="text" onChange={handleChange} className="form-control text-center" value={amt} placeholder='0.00' />
        
        <label className="input-group-text">R</label>
         </div>
         <div className="text-center">
          
           { lodBut === false ? 
           <button type="button" onClick={()=> handlePayment()}  className="btn btn-success w-md">Deposit</button>
           :
           <button type="button" className="btn btn-success w-md" disabled>Please wait...</button>
           }
            
           </div>
     </form>
    </div>
)
 }

  const eft = () =>{
    return (
      <div>
                 <div className='mb-4'>
       <button className="btn btn-primary btn-sm w-md" onClick={()=> setMForm(false)}> <b>Back to Deposit Options</b></button>
                               
       </div>
                     <div className="card border border-primary">
                                    <div className="card-header bg-transparent border-primary">
                                        <h5 className="my-0 text-primary"><i className="mdi mdi-bullseye-arrow me-3"></i>Notices</h5>
                                    </div>
                                    <div className="card-body">
                                       
                                    <strong>Kindly user your user id <span className='text-danger'>{userID}</span> as a reference when using EFT Method</strong>
       
                                    </div>
                                </div>

        <div className='card border border-success'>
          <div className='card-body'>

                          

          <table className='table'>
           <tbody>
            <tr>
              <th>Bank Name</th>
              <td>{account.bank_name}</td>
            </tr>

            <tr>
              <th>Account Name</th>
              <td>{account.account_name}</td>
            </tr>

            <tr>
              <th>Bank Name</th>
              <td>{account.account_number}</td>
            </tr>

            <tr>
              <th>Branch Code</th>
              <td>{account.branch_code}</td>
            </tr>
           </tbody>
        </table>

          </div>
        </div>
       
        
      </div>
  )
  }


  const displayFormData = () =>{
           switch(wShowForm){
              case 'card':
                return(
                  <div>
                 { cardView() }
                  </div>
                 )
                  
                break;
              case 'eft':
                return(
                  <div>
                  {eft()}
                  </div>
                 )
                break;
              
           }
  }

  const handleFunds = (e) => {
          let val = e.target.value;
              setWloader(true)
          setTimeout(function(){
            setWloader(false)
             setWShowForm(val)
             setMForm(true)

          },1000)
          
  }

  const fetchParentData = () =>{
          setLoadMe(true)
        axios.get('/checklogin/status',{
           headers:{'Content-Type':'application/json'}
        }).then((res)=>{
              setTimeout(()=>{
                setLoadMe(false)
                setPname(res.data.userData[0].names);
                setPemail(res.data.userData[0].email);
                setUserID(res.data.userData[0].userid)
                 setWalletID(res.data.wallet[0].wallet_id);
                 setAccount(res.data.account[0]);
                  setUserType(res.data.userType);
                   if(res.data.withdraw !== 'empty'){
                    setWRequest(res.data.withdraw)
                   }else{
                       setWRequest(null)
                   }
                  if(res.data.wallet[0].balance === null){
                      setBalance('0.00');  
                  }else{
                       setBalance(res.data.wallet[0].balance);
                  }
  
                  if(res.data.transData === 'empty'){
                        setLoadTrans([])
                  }else{
                        setLoadTrans(res.data.transData);
                  }
              },1000)
           


        })
  }

  useEffect(()=>{
        fetchParentData();
  },[wloader])
  return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">

      { wloader !== false ? null : loadMe === false ? null :
             <div id="preloader">
             <div id="status">
                 <div className="spinner-chase">
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                     <div className="chase-dot"></div>
                 </div>
             </div>
         </div> }

        <div className='row'>
          <div className='col-xl-12'>

            
            

                       <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="staticBackdropLabel"> { userType === 'Parent' ? 'Fund Deposit' : 'Withdrawal Request' }</h5>
                                                            <button type="button" className="btn-close" onClick={()=> setMForm(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">

                                                              { notify === null ? null : notify }

                                                            { userType === 'Tutor' ? <>
                                                              <label>Amount to withdraw</label>
                                                              <div className="input-group mb-3">
                                                                    <label className="input-group-text">Amount</label>
                                                                <input type="text" onChange={handleChange} className="form-control text-center" style={{ textAlign:'center' }} value={amt} placeholder='0.00' />
                                                                <label className="input-group-text">R</label>
                                                                </div>
                                                                  
                                                              <div className='text-center'>
                                                              <button type='button' className="btn btn-success btn-sm w-md" onClick={()=> handleWith()}> <b>Withdraw</b> <i className="mdi mdi-arrow-right ms-1"></i></button>
                          
                                                              </div>

                                                                 
                                                            </> : mform === false ?
                                                            <form>
                                                              <label>How do you want to fund your wallet</label>
                                                              <select className='form-select' onChange={(e)=>handleFunds(e)}>
                                                                <option value="">Choose...</option>
                                                                <option value="eft">Electronic Fund Transfer (EFT)</option>
                                                               { /* <option value="card">Card Payment</option> */ }
                                                              </select>
                                                               
                                                                
                                                            </form>
                                                            : displayFormData() }

                                                             { wloader === false ? null : 
                                                                      <div className='text-center mt-2'>
                                                                         {  handleErrs('dark',null,'load') }
                                                                      </div>
                                                                     
                                                                }
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-light" onClick={()=> setMForm(false)} data-bs-dismiss="modal">Close</button>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

          </div>
        </div>
       
      <div className="row">
        <div className="col-xl-4">
          <div className="card">
            <div className="card-body">
              <div className="media">
                <div className="me-4">
                  <i className="mdi mdi-account-circle text-primary h1" />
                </div>
                <div className="media-body">
                  <div className="text-muted">
                    <h5>{ pname }</h5>
                    <p className="mb-1">{pemail}</p>
                    <p className="mb-0">User ID: #{userID}</p>
                  
                  </div>
                </div>
               
              </div>
            </div>
            <div className="card-body border-top">
              <div className="row">
                <div className="col-sm-6">
                  <div>
                    <h5 className=" mb-2">Available Balance</h5>
                    <h5>R {Currency(balance)}</h5>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-end mt-4 mt-sm-0">
                    <p className="text-muted mb-4"></p>
                    <div className="mt-3">
                            { userType === 'Parent' ? 
                            <button className="btn btn-primary btn-sm w-md" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <b>Deposit Funds</b> <i className="mdi mdi-arrow-right ms-1"></i></button>
                                              :
                                              <button className="btn btn-success btn-sm w-md" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <b>Withdraw Funds</b> <i className="mdi mdi-arrow-right ms-1"></i></button>
                          
                                                              }
                            </div>
                  </div>
                </div>
              </div>
            </div>

          
          </div>
        </div>

        <div className="col-xl-8">
          { wRequest === null ? null :
          <div className='card'>
            <div className='card-body'>
               <h5 className='card-title'>Withdraw Request</h5>
               <hr className='text-danger' style={{ marginTop:-5 }} />
            <div>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Amount</th>
                          <th>Date Request</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>R { wRequest[0].amount }</td>
                          <td>{ wRequest[0].request_date}</td>
                          <td>{ wRequest[0].status }</td>
                          <td><button className='btn btn-sm btn-primary w-md'>Delete Request</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
            </div>
          </div> }
       
       
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-3">Wallet Activity</h4>
              <hr className='text-danger' style={{ marginTop:-8 }} />
              <div> 

                      
                 { loadTrans.length === 0 ?
                    <div>
                             { handleErrs(null,'No transaction on this wallet','not') }

                           </div> : loadUserTransaction() }

              </div>
            </div>
          </div>
        </div>

        
      </div>

      <FootNav />
    </div>
      </div>
        </div>
  )
}

export default Wallet