import React, { useState, useEffect} from 'react'
import axios from 'axios';
import ErrorMessage from '../userAuth/ErrorMessage';
import { useParams } from 'react-router-dom';


function AddStudentToCentre() {

    const [loadcentre, setLoadCentre] = useState([])
    const [loading, setLoading] = useState(false);
    const [cDatas, setCDatas] = useState([])
    const {sid}= useParams();
    const [studName,setStudName] = useState();
    const [loadBut, setLoadBut] = useState(false)
    const [notify, setNotify] = useState(null);
    const [region, setRegion] = useState([])
    const [studdatas, setStudDatas] = useState();
    const [studInfo, setStudInfo] = useState(false);
    const [getRegion, setGetRegion] = useState(0);
    const [squery, setSQuery] = useState(0);
    const [loadSearch, setLoadSearch] = useState(false);
    const [searchResult, setSearchResult] = useState([]);



    const searchCenterResult = () => {
                    return (
                        <>
                        { searchResult.length > 0 ? 
                        
                        searchResult && searchResult.map((d,index)=>{
                            return(
                              <div className="col-xl-4 col-sm-6" key={index}>
                              <div className="card">
                                  <div className="row">
                                      <div className="col-xl-5">
                                          <div className="text-center p-4 border-end">
                                              <div className="avatar-sm mx-auto mb-3 mt-1">
                                                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16">
                                                      { getFirstLetter(d.names)}
                                                  </span>
                                              </div>
                                              <h5 className="text-truncate pb-1">{d.names}</h5>
                                          </div>
                                      </div>

                                      <div className="col-xl-7">
                                          <div className="p-4 text-center text-xl-start">
                                              <div className="row">
                                                  <div className="col-sm-6">
                                                      <div>
                                                          <p className="text-muted mb-2 text-truncate">Learners</p>
                                                          <div className='text-center'>
                                                          <h5>{d.lenNum}</h5>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-sm-6">
                                                      <div>
                                                          <p className="text-muted mb-2 text-truncate">Region</p>
                                                          <h6>{d.region}</h6>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="mt-4">
                                                {/*
                                                
                                                names:q[i].centre_name,
                    region:q[i].region,
                    phone:q[i].phone,
                    cid:q[i].centre_id,
                    address:q[i].address,
                    lenNum:studNum,
                    email:q[i].email
                                                 */}
                                                  <div style={{ cursor:'pointer' }} onClick={()=>viewCentresDetails(d.cid,d.region,d.phone,d.address,d.lenNum,d.email,d.names,d.tid)} data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="text-decoration-underline text-reset">See Profile <i className="mdi mdi-arrow-right"></i></div>
                                              </div>
                                          </div>

                                      </div>
                                  </div>
                              </div>
                          </div> 
                            )
                        })
                        
                        : null }
                        </>
                    )
    }

    const handleSearch = () => {
                if(getRegion === 0){
                         setNotify(handleErrs('danger','Please select region and centre name','message'))
                }else
                {
                      setLoadSearch(true);
                      setLoading(false);
                   axios.post('/searchcentres',{getRegion,squery},{
                      headers:{'Content-Type':'application/json'}
                   }).then((res)=>{
                        setTimeout(()=>{
                            setLoading(true);
                            setSQuery(0)
                            setGetRegion(0)
                            if(res.data.mDatas !== 'empty'){
                                setSearchResult(res.data.mDatas);
                             
                                setNotify(null)
                         }else
                         {
                             setNotify(handleErrs('danger','No centre match your search cretarial','message'));
                             setSearchResult([]);
                         }
                        },1000)
                     
                   }).catch(err => {
                    console.log(err)
                   })

                }
    }


    const getStudentInfo = () =>{
        axios.get('/get-student-data/'+sid,{
            headers: {'Content-Type':'application/json'}
       }).then((res)=>{
                 setStudDatas(res.data.studentData);
       }).catch((err)=>{
             console.log(err)
       })
    }
  
    const addLearnerToCentre = (cid,tid,names) => {
                 setLoadBut(true);
                  setNotify(null)
                 axios.post('/add-learner-to-center',{cid,tid,names,sid},{
                      headers:{'Content-Type':'application/json'}
                 }).then((res)=>{
                         setTimeout(()=>{
                                setLoadBut(false);
                                 setNotify(handleErrs(res.data.color,res.data.status,'message'));
                                 
                         },1000)
                 }).catch((err)=>{
                        console.log(err)
                 })
    }

    const getFirstLetter = (str) => {
            let sd = str.split('');
              return sd[0];
    }

    const viewCentresDetails = (cid,region,phone,address,lenNum,email,names,tid) =>{
                let datas = {cid,region,phone,address,lenNum,email,names,tid}
               
                 setNotify(null)
                 setCDatas(datas)


    }


    const handleErrs = ($type,$msg,$uitype) =>{
      let errorChild = {types:$type, msg:$msg, uiType:$uitype}
    return (
      <div>
      <ErrorMessage errors={errorChild} />
      </div>
    
    )
 }


    const getUserLocation = () =>{

      if(navigator.geolocation){
        navigator.geolocation.watchPosition( (position) => {
  
       
             getCentresByLocation(position.coords.latitude,position.coords.longitude)
        },(err)=>{
              console.log(err)
        })
    }else
    {
        console.log('Browser does not support location');
    }
  
    }

    const getCentresByLocation = (lat,longi) => {
              let local = {lat,longi,sid}
             
             axios.post('/get-nearby-centre',local,{
                headers:{'Content-Type':'application/json'}

             }).then((res)=>{
                setTimeout(() => {
                    setLoading(true);
                   
                      setStudName(res.data.studData[0].names)
                      setRegion(res.data.region)
                    
                      setStudInfo(true)
                     if(res.data.cDatas !=='empty'){
                          setLoadCentre(res.data.cDatas)
                     }else
                     {
                          setLoadCentre([])
                     }
                }, 500);

             }).catch((err)=>{
                console.log(err)
             })
    }

  useEffect(()=>{
    getStudentInfo();
     getUserLocation();

  },[])

  return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
            { studInfo === false ? null :
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="staticBackdropLabel">{cDatas.names} Information</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className='row'>
                                                              <div className='col-lg-12'>



                                                              <div className="card">
                                    <div className="card-body border-bottom">
                                        
                                    <div className='row'>
                                    <div className='col-lg-12'>
                                         { notify === null ? null : notify }
                                        </div>

                                     </div>

                                        <div className='row'>
                                            <div className='col-lg-6'>
                                            <div className="mb-4 me-3">
                                                <i className="mdi mdi-account-circle text-primary h1"></i>
                                            </div>

                                            <div>
                                                <h5 className="">{cDatas.names}</h5>
                                                <p className="text-muted mb-1">{cDatas.email}</p>
                                                <p className="text-muted mb-0">Id no: { cDatas.cid }</p>
                                            </div>
                                            </div>

                                            <div className='col-lg-6'>
                         
                                            <p>To add <strong>"{studName}"</strong> to <strong>{cDatas.names} centre</strong>. Kindly click on add learner</p>

                                            {  studdatas[0].centre_id===cDatas.cid ?  <button type="button" className="btn btn-outline-success waves-effect waves-light" disabled>Added already</button> : loadBut ===false ?
                                            <button onClick={()=>addLearnerToCentre(cDatas.cid,cDatas.tid,cDatas.names)} type="button" className="btn btn-success btn-sm waves-effect">  <i className="bx bx-plus-medical font-size-16 align-middle me-2"></i> Add learner</button>
                                                :

                                            <button type="button" className="btn btn-success btn-sm waves-effect">
                                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Adding..
                                            </button>
                                                }
                                              
                                            </div>
                                          

                                        </div>
                                    </div>
                                    <div className="card-body border-bottom">
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div>
                                                        
                                                     
                                                        <p className="text-muted mb-2">Number of Learners</p>
                                                        <h5>{  cDatas.lenNum } </h5>
                                                     
                                                       
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="text-sm-end mt-4 mt-sm-0">
                                                        <p className="text-muted mb-2">Region</p>
                                                        <h5>{ cDatas.region }</h5>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                                                </div>

                                                                <div className='mt-2 card'>
                                                                <div className='card-body'>
                                                                <h4 className="card-title mb-4">Other Information</h4>

                                                                <div className="table-responsive">
                                                              <table className="table table-nowrap mb-0">
                                                                  <tbody>
                                                                  <tr>
                                                                  <th scope="row">Phone :</th>
                                                                  <td>{ cDatas.phone }</td>
                                                                  </tr>

                                                                  <tr>
                                                                  <th scope="row">Address :</th>
                                                                  <td>{ cDatas.address }</td>
                                                                  </tr>
                                                                  </tbody>
                                                                  </table>
                                                                 </div>
                                                               
                                                                </div>
                                                                </div>

                                                              
                                                              </div>


                                                            </div>
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                            </div> }

      { loading === false ?
          <div id="preloader-spinner" className="preloader spinner">

                <div className="wrapper">
                    <span className="loader"></span>
                    <p style={{ textAlign: 'center', marginRight: 190, marginTop: 37 }}></p>

                    <p style={{marginLeft: -30, marginTop: 120}}>Loading nearby centre. Please wait...</p>
                </div>

                </div> : 
            

                    <div>
                    <div className="row mb-4">
                    <div className='col-xl-12'>
                                { notify === null ? null : notify }
                            </div>
                    <div className="col-xl-4 col-sm-6">
                        <select  className='form-select' placeholder='' onChange={(e)=>setGetRegion(e.target.value)}>
                          <option value={0}>Select Region</option>
                           { region.length > 0 ? 
                                region && region.map((r,i)=>{
                                        return(

                                     <option key={i} value={r.location}>{r.location}</option>
                                        )
                                   
                                })
                                : <option disabled>No Available</option>
                        }
                          </select>
                      </div>
                           
                      <div className="col-xl-4 col-sm-6">
                        <input type='text' className='form-control' onChange={(e)=> setSQuery(e.target.value)} placeholder='Enter centre name' />
                      </div>

                      <div className="col-xl-4 col-sm-6">
                      <button className='btn btn-danger waves-effect waves-light mb-2 me-2' onClick={()=>handleSearch()}>Search</button>
                      </div>

                      <div className='col-xl-12'>
                        <hr className='text-danger' />
                      </div>
                      </div>

                       <div className="row">
             

                            { loadSearch !== false ? searchCenterResult() : loadcentre.length > 0 ?

                                loadcentre && loadcentre.map((d,index)=>{
                                    return(
                                      <div className="col-xl-4 col-sm-6" key={index}>
                                      <div className="card">
                                          <div className="row">
                                              <div className="col-xl-5">
                                                  <div className="text-center p-4 border-end">
                                                      <div className="avatar-sm mx-auto mb-3 mt-1">
                                                          <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16">
                                                              { getFirstLetter(d.names)}
                                                          </span>
                                                      </div>
                                                      <h5 className="text-truncate pb-1">{d.names}</h5>
                                                  </div>
                                              </div>
      
                                              <div className="col-xl-7">
                                                  <div className="p-4 text-center text-xl-start">
                                                      <div className="row">
                                                          <div className="col-sm-6">
                                                              <div>
                                                                  <p className="text-muted mb-2 text-truncate">Learners</p>
                                                                  <div className='text-center'>
                                                                  <h5>{d.lenNum}</h5>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <div className="col-sm-6">
                                                              <div>
                                                                  <p className="text-muted mb-2 text-truncate">Region</p>
                                                                  <h6>{d.region}</h6>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="mt-4">
                                                        {/*
                                                        
                                                        names:q[i].centre_name,
                            region:q[i].region,
                            phone:q[i].phone,
                            cid:q[i].centre_id,
                            address:q[i].address,
                            lenNum:studNum,
                            email:q[i].email
                                                         */}
                                                          <div style={{ cursor:'pointer' }} onClick={()=>viewCentresDetails(d.cid,d.region,d.phone,d.address,d.lenNum,d.email,d.names,d.tid)} data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="text-decoration-underline text-reset">See Profile <i className="mdi mdi-arrow-right"></i></div>
                                                      </div>
                                                  </div>
      
                                              </div>
                                          </div>
                                      </div>
                                  </div> 
                                    )
                                })
                          : handleErrs(null,'No centre was found in your location','not')
                                }
                           

                        </div>


                        </div>
}
                    

        </div>
     </div>
   </div>
  )
}

export default AddStudentToCentre